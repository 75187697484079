/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, {useState} from 'react';
import {get} from 'lodash';
import * as Yup from 'yup';
import {Formik, ErrorMessage} from 'formik';
import {FormHelperText, Switch} from '@mui/material';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import IsLoadingHOC from '../../layout/components/HOC/IsLoadingHOC';
const instance = axios.create();
const BaseUrl = process.env.REACT_APP_API_URL;
const ClientReg = ({setLoading}) => {
  const initialValues = {
    username: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    companyName: '',
    zip: '',
    password: '',
    address: '',
    role: '',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
      .min(2, 'First Name should be greater than 2 letters')
      .max(15, 'First Name should be less than 15 letters')
      .required('Required'),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
      .min(2, 'First Name should be greater than 2 letters')
      .max(15, 'First Name should be less than 15 letters')
      .required('Required'),
    phone: Yup.string()
      .matches(/^[0-9 ]*$/, 'Please enter valid number')
      .min(10, 'Should be 10 digit')
      .max(10, 'Should be 10 digit')
      .required('Required'),
    username: Yup.string()
      .matches(
        /^[a-zA-Z0-9_]*$/,
        'Must Contain Alphabets, Numbers and Underscore only',
      )
      .min(4, 'Should be greater than 3 digit')
      .max(15, 'Should be less than 15 digit')
      .required('Required'),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
        'Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character ',
      )
      .min(6, 'Should be greater than 6 digit')
      .max(15, 'Should be less than 15 digit')
      .required('Required'),
    email: Yup.string()
      .min(4, 'Should be min 3 digit')
      .max(50, 'Should be max 15 digit')
      .email(' This is not a valid format')
      .required('Required'),
    companyName: Yup.string()
      .min(4, 'Should be min 3 digit')
      .max(15, 'Should be max 15 digit')
      .required('Required'),
    address: Yup.string()
      // .matches(/^[a-zA-Z0-9_.]*$/, 'Must Contain Alphabets, Numbers, dot and Underscore only')
      .min(4, 'Should be min 3 digit')
      .max(40, 'Should be max 40 digit')
      .required('Required'),
    zip: Yup.string()
      .matches(/^\d+$/, 'The field should have digits only')
      .min(6, 'Should be  6 digit')
      .max(6, 'Should be 6 digit')
      .required('Required'),
    role: Yup.string().required('Required'),
  });
  const {accessToken} = useSelector((state) => state.auth);
  // console.log("accessToken", accessToken);

  let [message, setMessage] = useState('');
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, {resetForm}) => {
          const role = [];
          if (values.role) {
            role.push('CLIENT');
          }
          const finalData = {...values, role: role};
          delete finalData.client;
          delete finalData.editor;
          setLoading(true);
          instance
            .post(`${BaseUrl}/s2/identities`, finalData, {
              headers: {Authorization: accessToken},
            })
            .then((res) => {
              setLoading(false);
              //  setMessage(res.data.message)
              resetForm(initialValues);
              toast.success('Admin User Added. Please check your email');
            })
            .catch((err) => {
              setLoading(false);
              // console.log(err.response.data);
              let errorMsg = [];
              errorMsg = err.response?.data && err.response.data?.errors;
              errorMsg.forEach((item) => {
                toast.error(item.message);
              });

              //setMessage("error in submitting")
            });
        }}
        validationSchema={validationSchema}
        //  onReset={() => {}}
      >
        {({values, handleReset, handleSubmit, handleChange, handleBlur}) => (
          <>
            {message == '' ? (
              <div className="card card-custom">
                {/*Header*/}
                <div className="card-header card-header-tabs-line">
                  <ul
                    className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                    data-remember-tab="tab_id"
                    role="tablist">
                    <li className="nav-item">
                      <span className={`nav-link active}`} data-toggle="tab">
                        Create Admin User
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="form">
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        First Name:
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="firstName"
                          checked={!!get(values, 'subheader.display')}
                        />

                        <FormHelperText>
                          <ErrorMessage
                            name="firstName"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        Last Name:
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="lastName"
                          checked={!!get(values, 'subheader.display')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="lastName"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        Phone Number:
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="phone"
                          checked={!!get(values, 'subheader.display')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="phone"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        Email Address:
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="email"
                          checked={!!get(values, 'subheader.fixed')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="email"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        Company Name:
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="companyName"
                          checked={!!get(values, 'subheader.fixed')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="companyName"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        Address:
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="address"
                          checked={!!get(values, 'subheader.fixed')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="address"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        Zip Code
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="zip"
                          checked={!!get(values, 'subheader.fixed')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="zip"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        Username:
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="username"
                          checked={!!get(values, 'subheader.display')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="username"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        Password:
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="password"
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="password"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>

                    <h3>Roles</h3>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        Client
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <select
                          className="form-control form-control-lg form-control-solid"
                          name="role"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.actionType}>
                          <option value="">Select Role</option>
                          <option value="CLIENT">CLIENT</option>
                          <option value="EDITOR">EDITOR</option>
                        </select>
                        <FormHelperText>
                          <ErrorMessage
                            name="role"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div className="row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-9">
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className={`btn btn-primary font-weight-bold me-2`}>
                          <i className="fa fa-eye" /> Submit
                        </button>{' '}
                        <button
                          type="button"
                          onClick={handleReset}
                          className={`btn btn-clean font-weight-bold me-2`}>
                          <i className="fa fa-recycle" /> Reset
                        </button>{' '}
                        {/* <span
                                                className={`ms-3 ${clsx({
                                                    spinner: isLoading
                                                })}`}
                                            /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p>{message}</p>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default IsLoadingHOC(ClientReg);
