import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useSubheader} from '../../../_metronic/layout';
import List from './pages/List';
import AddEdit from './component/AddEdit';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {RBACUIProvider} from './RBACUIContext';
import Permission from './component/Permission';

const RBAC = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle('');
  const requestedReportsUIEvents = {};

  return (
    <RBACUIProvider requestedReportsUIEvents={requestedReportsUIEvents}>
      <Switch>
        <Redirect from="/rbac/roles" exact={true} to="/rbac/roles/list" />
        <Route exect path="/rbac/roles/list" component={List} />
        <Route exect path="/rbac/roles/add" component={AddEdit} />
        <Route exect path="/rbac/roles/edit/:id" component={AddEdit} />
        <Route exect path="/rbac/role-permissions" component={Permission} />
      </Switch>
    </RBACUIProvider>
  );
};

export default IsLoadingHOC(RBAC);
