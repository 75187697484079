// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
//import * as actions from '../../../_redux/customers/customersActions';
import {getRuleList} from '../_redux/action';
import {connect} from 'react-redux';
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../_metronic/_helpers';
import * as uiHelpers from '../RuleUIHelpers';
import * as columnFormatters from './ActionsColumnFormatter';
import {Pagination} from '../../../../_metronic/_partials/controls';
// import {entities} from '../_mock';
import {useRuleUIContext} from '../RuleUIContext';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import filterFactory from 'react-bootstrap-table2-filter';
import filterFun from '../../../../_metronic/_helpers/FilterHelper';

const RuleTable = ({getRuleList, setLoading}) => {
  // Rule UI Context
  const ruleUIContext = useRuleUIContext();
  const ruleUIProps = useMemo(() => {
    return {
      ids: ruleUIContext.ids,
      setIds: ruleUIContext.setIds,
      queryParams: ruleUIContext.queryParams,
      setQueryParams: ruleUIContext.setQueryParams,
      openEditRuleDialog: ruleUIContext.openEditRuleDialog,
      openDeleteRuleDialog: ruleUIContext.openDeleteRuleDialog,
    };
  }, [ruleUIContext]);

  // Getting curret state of Rule list from store (Redux)
  const {currentState} = useSelector(
    (state) => ({currentState: state.rule}),
    shallowEqual,
  );
  const {totalCount, entities, listLoading} = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    ruleUIProps.setIds([]);
    // server call by queryParams
    //  dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    // dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    // console.log('working', ruleUIProps.queryParams);
    //dispatch(getRuleList(RuleUIProps.queryParams)));

    async function callList() {
      try {
        setLoading(true);
        await getRuleList(ruleUIProps.queryParams);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    callList();
    // }, [customersUIProps.queryParams, dispatch]);
  }, [dispatch, ruleUIProps.queryParams]);
  // Table columns
  const columns = [
    {
      dataField: 'ruleName',
      text: 'Rule Name',
      headerSortingClasses,
      filter: filterFun({placeholder: 'Search'}),
    },
    {
      dataField: 'tag',
      text: 'Tag',
      headerSortingClasses,
      filter: filterFun({placeholder: 'Search'}),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerSortingClasses,
      filter: filterFun({placeholder: 'Search'}),
    },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditRuleDialog: ruleUIProps.openEditRuleDialog,
        openDeleteRuleDialog: ruleUIProps.openDeleteRuleDialog,
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pe-3',
      style: {
        minWidth: '100px',
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: ruleUIProps.queryParams.limit,
    page: ruleUIProps.queryParams.page,
    // onPageChange: function (page, sizePerPage) {
    //   console.log(page);
    //   console.log(sizePerPage);
    // },
    // onSizePerPageChange: function (page, sizePerPage) {
    //   console.log(page);
    //   console.log(sizePerPage);
    // },
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                filter={filterFactory()}
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  ruleUIProps.setQueryParams,
                )}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default connect(null, {getRuleList})(IsLoadingHOC(RuleTable));
