import { toast } from 'react-toastify';
import api from '../../../../api';
import { SetUser, Logout, UPDATE_CAMPAIGN, SAVE_LOOK_UP } from './types';
export const BASE_URL = process.env.REACT_APP_API_URL;

export const login = (email, password) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/auth/signin', {
      username: email,
      password: password,
    });
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({ type: SetUser, payload: data.data });
      // utils.setStorage('accessToken', "sdjfsdfsdjf")
      // auth.accessType.setUserConfig()
    } else {
      reject(data.errors);
    }
  });
};

export const regenerateCampaignToken =
  (campaignId, clientId) => async (dispatch) => {
    //console.log('campifd', campaignId, clientId);
    return new Promise(async (resolve, reject) => {
      let data = await api.post('/s2/auth/regen', {
        clientId: clientId,
        campaignId: campaignId,
      });
      //  console.log('response campaign select', data);

      if (data.httpCode === 200) {
        dispatch({ type: SetUser, payload: data.data });
        let dataNew = await api.get('/s1/campaign/campaigndetails');
        if (dataNew.httpCode === 200) {
          dispatch({ type: 'SAVE_TENANT_CONFIG', payload: dataNew?.data });
          dispatch({ type: 'CLEAR_ALL' });
          resolve(data.message);
        } else {
          resolve(data.message);
        }

        // utils.setStorage('accessToken', "sdjfsdfsdjf")
        // auth.accessType.setUserConfig()
      } else {
        reject(data.errors);
      }
    });
  };

export const logout = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get('/s1/auth/signin');
    if (data.httpCode === 200) {
      dispatch({ type: Logout });
      dispatch({ type: 'CLEAR_ALL' });
      resolve('Log out successfully');
    } else {
      reject(data?.errors);
    }
  });
};

export function register(email, fullname, username, password) {
  return api.post('/s2/auth/register', { email, fullname, username, password });
}

export function requestPassword(email) {
  //return api.post(`/s2/identities/forgot-password?email=${email}`);
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/identities/forget-password', { email: email });
    if (data.httpCode === 200) {
      resolve(data.message);
      // dispatch({type: SetUser, payload: data.data});
      // utils.setStorage('accessToken', "sdjfsdfsdjf")
      // auth.accessType.setUserConfig()
    } else {
      reject(data.errors);
    }
  });
}

export const resetPassword = (token, password) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post(`/s2/identities/reset-password?token=${token}`, {
      password: password,
    });
    if (data.httpCode === 202) {
      resolve(data.message);
      // dispatch({type: SetUser, payload: data.data});
      // utils.setStorage('accessToken', "sdjfsdfsdjf")
      // auth.accessType.setUserConfig()
    } else {
      reject(data.errors);
    }
  });
};

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return api.get('/s2/auth/me');
}

export function getSidebarPanelConfig() {
  return api.get('https://mocki.io/s1/fe193784-bf9e-4af4-b946-4cc2c2bb02bb');
}

export const getCampaignList = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get('/s1/campaign');
    //console.log('response campaign list', data);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({ type: 'SAVE_CAMPAIGN', payload: data.data });
      //  if(data.data.length > 0) {
      //      dispatch({type: "SAVE_CAMPAIGN_ID", payload: data.data[0]._id})
      //  }
    } else {
      reject(data.message);
    }
  });
};

export const getLookUpData = (params) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/lookup', params);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({ type: 'SAVE_LOOK_UP', payload: data.data });
    } else {
      reject(data.message);
    }
  });
};
export const getLookUpDataChart = (params) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get('/s1/report/get-dashboard-graph');
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({ type: 'SAVE_LOOK_UP_CHART', payload: data.data });
    } else {
      reject(data.message);
    }
  });
};

export const addCompaign = (tenantConfig) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/campaign', tenantConfig);
    // console.log('add compaign', data);
    if (data.httpCode == 201) {
      resolve(data.message);
      // dispatch({type: 'ADD_CAMPAIGN', payload: tenantConfig});
      //  if(data.data.length > 0) {
      //      dispatch({type: "SAVE_CAMPAIGN_ID", payload: data.data[0]._id})
      //  }
    } else {
      reject(data.errors);
    }
  });
};

export const updateCompaign = (tenantConfig) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.put('/s1/campaign', tenantConfig);
    //console.log('add compaign', data);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({ type: UPDATE_CAMPAIGN, payload: tenantConfig });
      //  if(data.data.length > 0) {
      //      dispatch({type: "SAVE_CAMPAIGN_ID", payload: data.data[0]._id})
      //  }
    } else {
      reject(data.errors);
    }
  });
};

export const getCampaignDetailByToken = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get('/s1/campaign/campaigndetails?refereshCampaign=1');
    if (data.httpCode === 200) {
      resolve(data.data);
      // dispatch({type: 'ADD_CAMPAIGN', payload: tenantConfig});
      //  if(data.data.length > 0) {
      //      dispatch({type: "SAVE_CAMPAIGN_ID", payload: data.data[0]._id})
      //  }
    } else {
      reject(data.errors);
    }
  });
};

export const fileContentExtract = (config) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/automation/file-content-extract');
    // console.log('add compaign', data);
    if (data.httpCode === 200) {
      resolve(data.message);
      // dispatch({type: 'ADD_CAMPAIGN', payload: tenantConfig});
      //  if(data.data.length > 0) {
      //      dispatch({type: "SAVE_CAMPAIGN_ID", payload: data.data[0]._id})
      //  }
    } else {
      reject(data.errors);
    }
  });
};

export const getMenu = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get('/s1/menulist');
    //console.log('add compaign', data);
    if (data.httpCode === 200) {
      resolve(data.message);
      //console.log('menu data', data);
      dispatch({ type: 'SAVE_MENU', payload: data?.data });
      //  if(data.data.length > 0) {
      //      dispatch({type: "SAVE_CAMPAIGN_ID", payload: data.data[0]._id})
      //  }
    } else {
      reject(data.errors);
    }
  });
};
export const createStackPost = (payload) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/cloudFormation/createstack', payload);
    // console.log('add compaign', data);
    if (data.httpCode === 201) {
      resolve(data.data);
      // dispatch({type: 'ADD_CAMPAIGN', payload: tenantConfig});
      //  if(data.data.length > 0) {
      //      dispatch({type: "SAVE_CAMPAIGN_ID", payload: data.data[0]._id})
      //  }
      toast.success(
        'Create Stack request created. It will take sometime. Please click on refresh button for check latest Status',
      );
    } else {
      reject(data.errors);
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};

export const checkStatus = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/cloudFormation/checkstatus');
    if (data.httpCode === 200) {
      resolve(data.data);
    } else {
      if (data.httpCode === 409) {
        reject({ status: 'new', message: data.message });
      } else {
        reject(data.errors);
      }
    }
  });
};

export const getModuleList = (campaignId, clientId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/campaign/clone?cloneModuleList=true', {
      clientId,
      campaignId,
    });
    if (data.httpCode === 200) {
      resolve(data.data);
    } else {
      reject(data.errors);
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};

export const cloneCampaign = (values) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/campaign/clone', values);
    if (data.httpCode === 201) {
      resolve(data);
    } else {
      reject(data.errors);
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};
export const getClientList = () => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get('/s1/identities/users?clientList=1');
    if (data.httpCode === 200) {
      resolve(data.data);
    } else {
      data?.errors && data.errors.forEach((item) => toast.error(item.message));
    }
  });
};

export const getCampaignTagsAction = () => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/campaign?tag=1`);
    if (data.httpCode === 200) {
      resolve(data.data);
    } else {
      reject(data.errors);
    }
  });
};
