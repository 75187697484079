import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import IsLoadingHOC from '../../../layout/components/HOC/IsLoadingHOC';
import {Row, Col} from 'react-bootstrap';
import {getTrafficGoogleAnalyticsData} from '../_redux/action';
import DatePicker from 'react-datepicker';

const TrafficGoogleAnalytics = ({
  getTrafficGoogleAnalyticsData,
  setLoading,
}) => {
  const [data, setData] = useState([]);

  const {activationEnd, activationStart} = useSelector(
    (state) => state.auth.tenantConfig,
  );
  const {campaignId} = useSelector((state) => ({
    campaignId: state.auth.campaignId,
  }));

  const [startDate, setStartDate] = useState(new Date(activationStart));
  const [endDate, setEndDate] = useState(new Date(activationEnd));

  useEffect(() => {
    setLoading(true);
    getTrafficGoogleAnalyticsData(startDate, endDate)
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [campaignId, startDate, endDate]);

  const renderHtml = (data) => {
    return data.map((item, index) => {
      return (
        <Col xs={3} key={index}>
          <div className="metrics-container">
            <div className="metrics-name">{item.name}</div>
            <div className="metrics-value">{item.value}</div>
          </div>
        </Col>
      );
    });
  };

  return (
    <>
      <Row>
        <Col>
          <div className={`card card-custom gutter-b`}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Please Select the Date Range
                </span>
              </h3>
              <div className="d-flex  ps-5 flex-row">
                <div className="me-3">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    className="form-control form-control-md form-control-solid "
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    showYearDropdown
                    dropdownMode="select"
                    enableTabLoop
                  />
                </div>
                <div className="me-3">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    className="form-control form-control-md form-control-solid"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    showYearDropdown
                    enableTabLoop
                    dropdownMode="select"
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className={`card card-custom gutter-b`}>
        <Row>{data?.length > 3 && renderHtml(data)}</Row>
      </div>
    </>
  );
};

export default connect(null, {getTrafficGoogleAnalyticsData})(
  IsLoadingHOC(TrafficGoogleAnalytics),
);
