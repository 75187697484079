import React from 'react';

function createDate(value) {
  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dateResult = new Date(value);
  const date = dateResult.getDate();
  const month = monthList[dateResult.getMonth()];
  const year = dateResult.getFullYear();

  let customDate = `${date} ${month} ${year}`;
  return customDate;
}

const ProductsList = ({
  products,
  productDetails,
  handleClose,
  handleShow,
  handleFormSubmit,
  show,
}) => {
  return (
    <div class="table-responsive" style={{background: '#fff'}}>
      <table class="table table-borderless table-vertical-center">
        <thead>
          <tr>
            <th class="p-0" style={{width: '50px'}}></th>
            <th class="p-0" style={{minWidth: '200px'}}>
              Product Name
            </th>
            <th class="p-0" style={{minWidth: '200px'}}>
              UPC
            </th>
            <th class="p-0" style={{minWidth: '125px'}}>
              Video
            </th>
            <th class="p-0 text-right" style={{minWidth: '110px'}}>
              Manage Product
            </th>
          </tr>
        </thead>
        <tbody>
          {products &&
            products.map((product) => (
              <tr>
                <td class="pl-0 py-4">
                  <div class="symbol symbol-50 symbol-light me-1">
                    <span class="symbol-label">
                      {product.image1 && (
                        <img
                          width="40"
                          src={`https://dev-content-management.s3.us-west-2.amazonaws.com/${product.image1}`}
                        />
                      )}
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  {product.productName && (
                    <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                      {product.productName}
                    </span>
                  )}
                </td>
                <td class="pl-0">
                  {product.UPC && (
                    <span class="text-muted font-weight-500">
                      {product.UPC}
                    </span>
                  )}
                </td>
                <td class="pl-0">
                  {product.shortDesc && (
                    <span class="text-muted font-weight-500">
                      {product.shortDesc}
                    </span>
                  )}
                </td>
                <td class="pl-0">
                  {product.videoUrl && (
                    <span class="text-muted font-weight-500">
                      {product.videoUrl}
                    </span>
                  )}
                </td>
                <td class="text-right">
                  {product._id && (
                    <span
                      onClick={() => handleShow(product._id)}
                      style={{cursor: 'pointer'}}
                      class="label label-lg label-light-primary label-inline">
                      Manage
                    </span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsList;
