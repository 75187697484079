import React, {useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {MapContainer, TileLayer} from 'react-leaflet';
import 'leaflet.heat/dist/leaflet-heat';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Loader from 'react-loader-spinner';
const BaseUrl = process.env.REACT_APP_API_URL;
const instance = axios.create();
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Heatmap = () => {
  const {campaignId, accessToken} = useSelector((state) => state.auth);
  const cId = campaignId.split('-')[0];
  const position = [37.0902, 95.7129];
  const mapRef = useRef();
  const [geoDat, setGeoDat] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    let data = {campaignId: cId, name: 'ssssss'};
    instance
      .post(`${BaseUrl}/s1/report/heatmap-geolocation`, data, {
        headers: {Authorization: accessToken},
      })
      .then((res) => {
        setGeoDat(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [campaignId]);

  useEffect(() => {
    var heatmapPoints = [];
    const {current = {}} = mapRef;
    let map = null;
    if (current) {
      const {leafletElement} = current;
      map = leafletElement;
    }
    if (!map) return;

    if (geoDat && geoDat.features.length == 0) {
      map.eachLayer(function (layer) {
        if (layer._container) {
        } else {
          map.removeLayer(layer);
        }
      });
    } else if (geoDat && geoDat.features.length > 0) {
      map.eachLayer(function (layer) {
        if (layer._container) {
        } else {
          map.removeLayer(layer);
        }
      });

      geoDat.features.forEach(function (feature) {
        heatmapPoints.push([
          feature.geometry.coordinates[1],
          feature.geometry.coordinates[0],
          feature.geometry.intensity,
        ]);
      });
      var heat = L.heatLayer(heatmapPoints, {
        radius: 30,
        minOpacity: 0.4,
        gradient: {0.1: 'blue', 0.3: 'lime', 0.6: 'red'},
      }).addTo(map);
    }

    // var maxBounds = [
    //     [9, -94.227],
    //     [45, -74.125]
    // ];
    // map.fitBounds(maxBounds);
  }, [geoDat]);

  useEffect(() => {
    const {current = {}} = mapRef;
    let map = null;
    if (current) {
      const {leafletElement} = current;
      map = leafletElement;
    }
    if (!map) return;

    if (geoDat && geoDat.features.length > 0) {
      const {
        geometry: {coordinates},
      } = geoDat.features[0];

      map.setView([coordinates[1], coordinates[0]]);
    }
  }, [geoDat]);

  return (
    <div>
      {isLoading && (
        <div className="loaderHolder">
          <div className="loaderMain">
            <Loader type="Circles" color="#ccc" height={100} width={100} />
          </div>
        </div>
      )}

      <MapContainer
        ref={mapRef}
        center={[0, 0]}
        zoom={13}
        scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
};

export default Heatmap;
