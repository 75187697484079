import React, {useMemo} from 'react';
import {Formik} from 'formik';
import {isEqual} from 'lodash';
import {useRewardSummaryUIContext} from '../RewardSummaryUIContext';
import {connect, useSelector} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {getRewardSummaryList} from '../_redux/action';
import SQSReportPopup from '../../../common/SQSReportPopup';
const prepareFilter = (queryParams, values) => {
  const {status, type, searchText, campaignId} = values;

  const newQueryParams = {...queryParams};
  const filter = {};
  // Filter by status
  filter.status = status !== '' ? +status : undefined;
  // Filter by type
  filter.type = type !== '' ? +type : undefined;
  // Filter by all fields
  filter.lastName = searchText;
  filter.campaignId = campaignId;
  if (searchText) {
    filter.firstName = searchText;
    filter.email = searchText;
    filter.ipAddress = searchText;
  }
  //  newQueryParams.filter = filter;
  // newQueryParams.searchText = searchText;
  newQueryParams.campaignId = campaignId;
  return newQueryParams;
};

const RewardSummaryFilter = ({
  listLoading,
  setLoading,
  getRewardSummaryList,
  setCampaignId,
  campaignId,
}) => {
  // Customers UI Context
  const rewardsummaryUIContext = useRewardSummaryUIContext();

  const {campaign} = useSelector((state) => state.auth);
  const rewardsummaryUIProps = useMemo(() => {
    return {
      queryParams: rewardsummaryUIContext.queryParams,
      setQueryParams: rewardsummaryUIContext.setQueryParams,
    };
  }, [rewardsummaryUIContext]);

  // queryParams, setQueryParams,
  const [exportAllResp, setExportAllResp] = React.useState(null);
  const dialogClose = () => {
    setExportAllResp(null);
  };

  const renderCampaignList = () => {
    let content = campaign && campaign?.content ? campaign?.content : [];
    content = content.sort((a, b) => a.name.localeCompare(b.name));
    return content.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });
  };

  const downloadFile = ({data, fileName, fileType}) => {
    const blob = new Blob([data], {type: fileType});
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = async () => {
    // Headers for each column
    let headers = [
      'Email, RewardCode, Redeemed, SentAt, RedemptionDate, Amount, Currency, RewardId, Quantity, RewardType',
    ];
    try {
      setExportAllResp(null);
      setLoading(true);
      const data = await getRewardSummaryList({
        ...rewardsummaryUIProps.queryParams,
        download: true,
      });
      setLoading(false);
      setExportAllResp(data);
      // let content = [];
      // if (Array.isArray(data)) {
      //   content = data;
      // } else {
      //   content = data?.content || [];
      // }
      // if (content?.length) {
      //   let dataCsv = content.reduce((acc, item) => {
      //     const email = item.email,
      //       rewardCode = item.token,
      //       redeemed =
      //         item.isClaimed ||
      //         (item.claimedTime && !item.hasOwnProperty('isClaimed'))
      //           ? 'TRUE'
      //           : 'FALSE',
      //       sentAt = item.createdAt
      //         ? new Date(item.createdAt).toLocaleDateString()
      //         : 'NA',
      //       redemptionDate = item.claimedTime
      //         ? new Date(item.claimedTime).toLocaleDateString()
      //         : 'NA',
      //       amount = item.rewardMasterInfo.redeemPrice || '',
      //       currency = item.rewardMasterInfo.currency || '',
      //       rewardId = item.rewardId; //rewardMasterInfo.brandId || '';

      //     let arrValues = [
      //       email,
      //       rewardCode,
      //       redeemed,
      //       sentAt,
      //       redemptionDate,
      //       amount,
      //       currency,
      //       rewardId,
      //       item.quantity,
      //       item.rewardType,
      //     ];
      //     acc.push(arrValues);
      //     return acc;
      //   }, []);

      //   downloadFile({
      //     data: [...headers, ...dataCsv].join('\n'),
      //     fileName: 'rewardsummary.csv',
      //     fileType: 'text/csv',
      //   });
      // }
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          // status: '', // values => All=""/Susspended=0/Active=1/Pending=2
          // type: '', // values => All=""/Business=0/Individual=1
          searchText: '',
          campaignId: '', //campaignId ? campaignId.split('-')[0] : '',
        }}
        onSubmit={(values) => {
          // applyFilter(values);
          setCampaignId(values.campaignId);
        }}>
        {({values, handleSubmit, handleBlur, handleChange, setFieldValue}) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-3">
                <select
                  className="form-control"
                  name="campaignId"
                  placeholder="Filter by Status"
                  // TODO: Change this code
                  onChange={(e) => {
                    setFieldValue('campaignId', e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.campaignId}>
                  <option value="">Select Campaign</option>
                  {renderCampaignList()}
                </select>
              </div>
              <div className="col-lg-3">
                <button
                  className="btn btn-primary ms-5"
                  type="button"
                  onClick={exportToCsv}
                  disabled={campaignId === '' ? true : false}>
                  Export to CSV
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <SQSReportPopup
        dialogClose={dialogClose}
        modelVisible={exportAllResp ? true : false}
        fileName={exportAllResp}
      />
    </>
  );
};

export default connect(null, {getRewardSummaryList})(
  IsLoadingHOC(RewardSummaryFilter),
);
