import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {getFirstChart} from '../dashboards/_redux/action';
import IsLoadingHOC from '../../layout/components/HOC/IsLoadingHOC';
import CommanChart from './CommanChart';
import {Button, Modal} from 'react-bootstrap';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const FirstChart = ({
  className,
  data,
  getFirstChart,
  setLoading,
  startDate,
  endDate,
}) => {
  const {campaignId} = useSelector((state) => state.auth);
  const [result, setResult] = useState();
  const [graphtype, setGraphType] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    getFirstChart(data.reportId, startDate, endDate)
      .then((res) => {
        setLoading(false);
        setResult(res);
        setGraphType(res.type);
        //console.log('graph data', res);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [campaignId, startDate, endDate]);
  return (
    <div
      className={`card card-custom ${className}`}
      style={{minHeight: '400px'}}>
      {/* Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title font-weight-bolder ">{data.name}</h3>
        <div onClick={() => setShowModal(true)}>
          {' '}
          <FullscreenIcon style={{fontSize: '40px'}} />
        </div>
      </div>
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <div
            className="card-body d-flex flex-column"
            style={{minHeight: '600px'}}>
            <div className="flex-grow-1">
              {graphtype && (
                <CommanChart
                  type={graphtype}
                  data={result.data}
                  options={result?.options}
                />
              )}
            </div>
          </div>
          <p align="center">
            <Button
              className="btn btn-dark"
              onClick={() => setShowModal(false)}>
              Close
            </Button>
          </p>
        </Modal.Body>
      </Modal>
      {/* Body */}
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          {graphtype && (
            <CommanChart
              type={graphtype}
              data={result.data}
              options={result?.options}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(null, {getFirstChart})(IsLoadingHOC(FirstChart));
