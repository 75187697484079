// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
//import * as actions from '../../../_redux/customers/customersActions';
import {getRoleList} from '../_redux/action';
import {connect} from 'react-redux';
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../_metronic/_helpers';
import * as uiHelpers from '../RoleUIHelpers';
import * as columnFormatters from './ActionsColumnFormatter';
import {Pagination} from '../../../../_metronic/_partials/controls';
// import {entities} from '../_mock';
import {useRoleUIContext} from '../RoleUIContext';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';

const RoleTable = ({getRoleList, setLoading}) => {
  // Role UI Context
  const roleUIContext = useRoleUIContext();
  const roleUIProps = useMemo(() => {
    return {
      ids: roleUIContext.ids,
      setIds: roleUIContext.setIds,
      queryParams: roleUIContext.queryParams,
      setQueryParams: roleUIContext.setQueryParams,
      openEditRoleDialog: roleUIContext.openEditRoleDialog,
      openDeleteRoleDialog: roleUIContext.openDeleteRoleDialog,
    };
  }, [roleUIContext]);

  // Getting curret state of Role list from store (Redux)
  const {currentState} = useSelector(
    (state) => ({currentState: state.role}),
    shallowEqual,
  );
  const {totalCount, entities, listLoading} = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    roleUIProps.setIds([]);
    // server call by queryParams
    //  dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    // dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    // console.log('working', roleUIProps.queryParams);
    //dispatch(getroleList(roleUIProps.queryParams)));

    async function callList() {
      try {
        setLoading(true);
        await getRoleList(roleUIProps.queryParams);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    callList();
    // }, [customersUIProps.queryParams, dispatch]);
  }, [dispatch, roleUIProps.queryParams]);
  // Table columns
  const columns = [
    {
      dataField: 'RoleId',
      text: 'Role Id',
      // sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'RoleName',
      text: 'Role Name',
      // sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'MaxSessionDuration',
      text: 'Max Session Duration',
      // sort: true,
      //  sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditRoleDialog: roleUIProps.openEditRoleDialog,
        openDeleteRoleDialog: roleUIProps.openDeleteRoleDialog,
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pe-3',
      style: {
        minWidth: '100px',
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: roleUIProps.queryParams.limit,
    page: roleUIProps.queryParams.page,
    // onPageChange: function (page, sizePerPage) {
    //   console.log(page);
    //   console.log(sizePerPage);
    // },
    // onSizePerPageChange: function (page, sizePerPage) {
    //   console.log(page);
    //   console.log(sizePerPage);
    // },
  };
  // const onTableChange = (type, newState) => {
  //   // handle any data change here
  //   console.log(type);
  //   console.log(newState);
  // };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  roleUIProps.setQueryParams,
                )}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default connect(null, {getRoleList})(IsLoadingHOC(RoleTable));
