import React from 'react';
import {Card} from '../../../../_metronic/_partials/controls';
import {Tabs, Tab, Box} from '@mui/material';
import GTMReport from './GTMReport';
function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
const GTMTrackingReport = () => {
  const [tab, setTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <Card>
      <Box sx={{width: '100%', typography: 'body1'}}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="simple tabs example">
          <Tab label="Custom Report" />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <GTMReport />
        </TabPanel>
      </Box>
    </Card>
  );
};

export default GTMTrackingReport;
