// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
//import * as actions from '../../../_redux/customers/customersActions';
import {getTemplateList} from '../_redux/action';
import {connect} from 'react-redux';
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../_metronic/_helpers';
import * as uiHelpers from '../TemplateUIHelpers';
import * as columnFormatters from './ActionsColumnFormatter';
import {Pagination} from '../../../../_metronic/_partials/controls';
// import {entities} from '../_mock';
import {useTemplateUIContext} from '../TemplateUIContext';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import filterFun from '../../../../_metronic/_helpers/FilterHelper';

const TemplateTable = ({getTemplateList, setLoading}) => {
  // Template UI Context
  const templatesUIContext = useTemplateUIContext();
  const templateUIProps = useMemo(() => {
    return {
      ids: templatesUIContext.ids,
      setIds: templatesUIContext.setIds,
      queryParams: templatesUIContext.queryParams,
      setQueryParams: templatesUIContext.setQueryParams,
      openEditTemplateDialog: templatesUIContext.openEditTemplateDialog,
      openDeleteTemplateDialog: templatesUIContext.openDeleteTemplateDialog,
    };
  }, [templatesUIContext]);

  // Getting curret state of Template list from store (Redux)
  const {currentState, campaignId} = useSelector(
    (state) => ({
      currentState: state.template,
      campaignId: state.auth.campaignId,
    }),
    shallowEqual,
  );
  const {totalCount, entities, listLoading} = currentState;

  // Customers Redux state
  useEffect(() => {
    // clear selections list
    templateUIProps.setIds([]);
    getTemplateList(templateUIProps.queryParams);
  }, [campaignId, templateUIProps.queryParams]);
  useEffect(() => {
    setLoading(listLoading);
  }, [listLoading]);
  // Table columns
  const columns = [
    {
      dataField: 'templateName',
      text: 'Template Name',
      filter: filterFun(),
      headerSortingClasses,
    },
    {
      dataField: 'templateTag',
      text: 'Template Tag',
      filter: filterFun(),
      headerSortingClasses,
    },
    {
      dataField: 'masterTemplate',
      text: 'Master Template',
      filter: filterFun(),
      formatter: (cellContent) => {
        return cellContent ? 'true' : 'false';
      },
      headerSortingClasses,
    },
    {
      dataField: 'subject',
      text: 'Email Subject',
      filter: filterFun(),
      headerSortingClasses,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerSortingClasses,
    },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditTemplateDialog: templateUIProps.openEditTemplateDialog,
        openDeleteTemplateDialog: templateUIProps.openDeleteTemplateDialog,
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pe-3',
      style: {
        minWidth: '100px',
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: templateUIProps.queryParams.limit,
    page: templateUIProps.queryParams.page,
  };

  // console.log('template ui props', templateUIProps);
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                filter={filterFactory()}
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  templateUIProps.setQueryParams,
                )}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default connect(null, {getTemplateList})(IsLoadingHOC(TemplateTable));
