import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import StackCreateSchema from './StackCreate.Schema';
import {Formik, ErrorMessage} from 'formik';
import {FormHelperText} from '@mui/material';
import {createStackPost} from '../../../app/modules/Auth/_redux/authCrud';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import IsLoadingHOC from '../../layout/components/HOC/IsLoadingHOC';

const StackCreate = ({
  show,
  handleClose,
  createStackPost,
  setLoading,
  s3FolderPath,
  status,
}) => {
  const initialValues = {
    stackName: '',
    S3KeyName: s3FolderPath,
    appName: '',
    repoName: '',
    templateURL:
      process.env.NODE_ENV === 'production'
        ? 'https://clienthomes-1.s3.us-west-2.amazonaws.com/template/dev-userportal-template.yaml '
        : 'https://clienthomes-1.s3.us-west-2.amazonaws.com/template/userportal-template.yaml',
  };

  const createStack = (values, resetForm) => {
    if (status === 'CREATE_COMPLETE') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Once you create new stack,  old repo will be deleted and you will not be able to recover this imaginary file!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes Sure',
      }).then((result) => {
        if (result.isConfirmed) {
          createStackNew(values);
        }
      });
    } else {
      createStackNew(values);
    }
  };

  const createStackNew = (values) => {
    setLoading(true);
    createStackPost(values)
      .then((res) => {
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };

  console.log('show stacik', show);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create Stack</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, {resetForm}) => {
              const newValues = {
                ...values,
                // status:
                //   values.status === true || values.status === 'active'
                //     ? 'active'
                //     : 'inactive',
              };
              createStack(newValues, resetForm);
            }}
            validationSchema={StackCreateSchema}
            onReset={() => {}}>
            {({
              values,
              handleReset,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <div className="form">
                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Stack Name:
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="stackName"
                        value={values.stackName}
                      />

                      <FormHelperText>
                        <ErrorMessage
                          name="stackName"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      S3 Key Name :
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="S3KeyName"
                        value={values.S3KeyName}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="S3KeyName"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      App Name :
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="appName"
                        value={values.appName}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="firstName"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Repo Name:
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="repoName"
                        value={values.repoName}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="repoName"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-9">
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className={`btn btn-primary font-weight-bold me-2`}>
                          <i className="fa fa-eye" /> Submit
                        </button>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(null, {createStackPost})(IsLoadingHOC(StackCreate));
