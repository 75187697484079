import React, {useState, useEffect} from 'react';
import FileManager from './FileManager/FileManager';
import './FileManager/Assets/fonts/Cronicon/Cronicon.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import store from '../../../redux/store';
import {connect} from 'react-redux';
import {unsetSelectedFiles} from './_redux/actions/index';

function FileManagerModel(props) {
  const [showModal, setShowModal] = useState(true);
  const getSelectedFilesOnParent = props.getSelectedFilesOnParent;
  const close = () => {
    setShowModal(false);
    props.unsetSelectedFiles();
    props.parentCallback();
  };
  const folderOnly = props.folderOnly;
  useEffect(() => {
    props.unsetSelectedFiles();
  }, []);
  const selectFile = (e) => {
    let {fileManager} = store.getState();
    if (fileManager) {
      if (fileManager.selectedFiles.length === 0) {
        let msg = 'Please select a file';
        if (folderOnly === 1) {
          msg = 'Please select a folder';
        }
        alert(msg);
        return false;
      } else if (
        fileManager.selectedFiles[0]['type'] !== 'folder' &&
        folderOnly === 1
      ) {
        alert('Selected object is not a valid Folder');
        return false;
      } else if (
        fileManager.selectedFiles[0]['type'] !== 'file' &&
        folderOnly !== 1
      ) {
        alert('Selected object is not a valid file');
        return false;
      } else {
        getSelectedFilesOnParent(fileManager.selectedFiles[0]);
        setShowModal(false);
      }
    }
  };
  const handleCallBack = (filePath) => {
    //console.log('Image Path Returend', filePath);
  };
  return (
    <div>
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="md"
        open={showModal}
        onClose={close}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">File Manager</DialogTitle>
        <DialogContent>
          <FileManager height="580" callback={handleCallBack} />
        </DialogContent>
        <DialogActions>
          {getSelectedFilesOnParent ? (
            <Button onClick={selectFile} variant="contained" color="secondary">
              Select {folderOnly === 1 ? 'Folder' : 'File'}
            </Button>
          ) : (
            ''
          )}
          <Button onClick={close} color="primary">
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (store) => {
  return {
    store,
    selectedFiles: store.fileManager.selectedFiles,
    selectedFolder: store.fileManager.selectedFolder,
    bufferedItems: store.fileManager.bufferedItems,
    foldersList: store.fileManager.foldersList,
    filesList: store.fileManager.filesList,
    itemsView: store.fileManager.itemsView,
    history: store.fileManager.history,
    loading: store.fileManager.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  unsetSelectedFiles: () => dispatch(unsetSelectedFiles()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(FileManagerModel, (prevProps, nextProps) => true));
