import React, {useEffect, useState} from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import {connect, useSelector} from 'react-redux';
import {Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';

import {toast} from 'react-toastify';
import moment from 'moment';
import ErrorBoundary from '../../_helpers/ErrorBoundary';
import IsLoadingHOC from '../../layout/components/HOC/IsLoadingHOC';
import {useHistory} from 'react-router-dom';
import {
  getModuleList,
  cloneCampaign,
  getClientList,
} from '../../../app/modules/Auth/_redux/authCrud';
import {Checkbox} from '../controls';
import styled from 'styled-components';
import {FormHelperText} from '@mui/material';
import CloneSchema from './Clone.Schema';

const CheckBoxDiv = styled.div`
  display: flex;
  margin: 10px 0px;
  justify-content: space-between;
`;

const ModelForm = ({
  setLoading,
  modelVisible,
  dialogClose,
  getModuleList,
  cloneCampaign,
}) => {
  //const [open, dialogOpen, dialogClose] = useDialogHook();
  const [initialvalues, setInitialvalues] = useState({
    name: '',
    domain: '',
    selectCampaign: '',
    selectClient: '',
  });
  const [campaignType, setCampaignType] = useState(false);

  const {campaign, accessToken} = useSelector((state) => state.auth);
  const [moduleState, setModuleState] = useState({});
  const [checkbox, selectCheckbox] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [tokenData, setTokenData] = useState({});

  const history = useHistory();
  useEffect(() => {
    getClientList().then((res) => {
      if (res?.content?.length) {
        setClientList(res?.content || []);
      }
    });
  }, []);
  useEffect(() => {
    if (accessToken) {
      try {
        let jsData = JSON.parse(window.atob(accessToken.split('.')[1]));
        jsData.isSuperAdmin = jsData?.roles?.indexOf('ADMIN') >= 0 || false;
        if (!jsData.isSuperAdmin) {
          setInitialvalues({
            ...initialvalues,
            selectClient: jsData?.clId || '',
          });
        }
        setTokenData(jsData);
      } catch (e) {
        setTokenData({});
      }
    }
  }, [accessToken]);
  const selectCampaignType = (e) => {
    const {value} = e.target;

    if (value === 'second') {
      setCampaignType(value);
    } else {
      setCampaignType(value);
      history.push('/campaign/add');
    }
  };

  const renderCampaignList = () => {
    let content = campaign && campaign?.content ? campaign?.content : [];
    content = content.sort((a, b) => a.name.localeCompare(b.name));
    return content.map((item) => {
      return (
        <option value={`${item.id}-${item.clientId}`} key={item.id}>
          {item.name}
        </option>
      );
    });
  };

  const handleCheckbox = (e, name) => {
    const {checked} = e.target;
    if (checked) {
      selectCheckbox([...checkbox, name]);
    } else {
      const checkboxRemaning = checkbox.filter((item) => item !== name);
      selectCheckbox(checkboxRemaning);
    }
  };
  const selectCampaign = (e, setFieldValue) => {
    const {name, value} = e.target;
    //  setFieldValue(value);

    const arr = value.split('-');
    setLoading(true);
    getModuleList(arr[0], arr[1])
      .then((res) => {
        setLoading(false);
        //console.log('data', res);
        selectCheckbox([]);
        setModuleState(res);
        setFieldValue(name, value);
      })
      .catch((error) => setLoading(false));
  };

  const saveForm = (values) => {
    // setLoading(true);
    const arr = values.selectCampaign.split('-');
    const data = {
      ...values,
      campaignId: arr[0],
      // clientId: arr[1],
      clientId: values.selectClient,
      selectedModules: checkbox,
    };
    //console.log('data values', data);
    setLoading(true);
    //console.log('data', campaignvalue);
    cloneCampaign(data)
      .then((res) => {
        setLoading(false);
        // console.log('testdfds');
        //console.log('data', res);
        dialogClose();
        res?.message && toast.success(res?.message);
        history.push('/campaign');
        // setModuleState(res);
      })
      .catch((error) => setLoading(false));
  };

  const renderCheckbox = () => {
    const list = Object.entries(moduleState);
    //console.log(list);
    return list.map((item) => {
      return (
        <div key={item[0]}>
          <CheckBoxDiv>
            <Checkbox
              isSelected={checkbox.includes(item[0])}
              onChange={(e) => handleCheckbox(e, item[0])}>
              {`${item[0]} : ${item[1]}`}
            </Checkbox>
          </CheckBoxDiv>
        </div>
      );
    });
  };

  // console.log('checkox', checkbox);
  return (
    <Modal
      // className="container-fluid"
      size="xl"
      show={modelVisible}
      onHide={() => {
        dialogClose();
        //setToggetValue(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Create Campaign
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorBoundary>
          <Formik
            initialValues={
              !tokenData?.isSuperAdmin && tokenData?.clId
                ? {...initialvalues, selectClient: tokenData?.clId}
                : initialvalues
            }
            enableReinitialize
            onSubmit={(values) => {
              // console.log('submit', values);
              saveForm(values);
            }}
            validationSchema={CloneSchema}
            onReset={() => {}}>
            {({
              values,
              handleReset,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
            }) => (
              <>
                <div className="card card-custom">
                  <div className="form">
                    <div className="card-body">
                      <div className="form-group row" key={values.inputName}>
                        <label className="col-lg-3 col-form-label text-lg-left">
                          Select Campaign Type
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control form-control-lg form-control-solid"
                            name="status"
                            onChange={selectCampaignType}
                            value={campaignType}>
                            <option value="">Select Campaign Type</option>
                            <option value="one">New Campaign</option>
                            <option value="second">Clone Old Campaign</option>
                          </select>

                          {/* <FormHelperText>
                            <ErrorMessage
                              name={values.inputName}
                              render={(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            />
                          </FormHelperText> */}
                        </div>
                      </div>
                      {campaignType === 'second' && (
                        <>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label text-lg-left">
                              Site Name
                            </label>
                            <div className="col-lg-9 col-xl-4">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="name"
                                value={values?.name}
                              />

                              <FormHelperText>
                                <ErrorMessage
                                  name="name"
                                  render={(msg) => (
                                    <span className="text-danger">{msg}</span>
                                  )}
                                />
                              </FormHelperText>
                            </div>
                          </div>
                          {tokenData?.isSuperAdmin && (
                            <div className="form-group row">
                              <label className="col-lg-3 col-form-label text-lg-left">
                                Select client for new campaign
                              </label>
                              <div className="col-lg-9 col-xl-4">
                                <select
                                  style={{marginRight: '5px'}}
                                  onChange={handleChange}
                                  value={values?.selectClient}
                                  name="selectClient"
                                  className="form-control"
                                  id="sel1">
                                  <option value="">Select Client</option>
                                  {clientList.map((v) => (
                                    <option
                                      value={
                                        v.id
                                      }>{`${v.firstName} ${v.lastName}`}</option>
                                  ))}
                                </select>

                                <FormHelperText>
                                  <ErrorMessage
                                    name={'selectClient'}
                                    render={(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  />
                                </FormHelperText>
                              </div>
                            </div>
                          )}
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label text-lg-left">
                              Domain
                            </label>
                            <div className="col-lg-9 col-xl-4">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="domain"
                                value={values?.domain}
                              />

                              <FormHelperText>
                                <ErrorMessage
                                  name="domain"
                                  render={(msg) => (
                                    <span className="text-danger">{msg}</span>
                                  )}
                                />
                              </FormHelperText>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label text-lg-left">
                              Select Campaign
                            </label>
                            <div className="col-lg-9 col-xl-4">
                              <select
                                style={{marginRight: '5px'}}
                                onChange={(e) => {
                                  selectCampaign(e, setFieldValue);
                                }}
                                value={values?.selectCampaign}
                                name="selectCampaign"
                                className="form-control"
                                id="sel1">
                                <option value="">Select Site</option>
                                {renderCampaignList()}
                              </select>

                              <FormHelperText>
                                <ErrorMessage
                                  name={'selectCampaign'}
                                  render={(msg) => (
                                    <span className="text-danger">{msg}</span>
                                  )}
                                />
                              </FormHelperText>
                            </div>
                          </div>

                          <div
                            className="form-group row"
                            key={values.inputName}>
                            <label className="col-lg-3 col-form-label text-lg-left">
                              Select Modules For Copy
                            </label>
                            <div className="col-lg-9 col-xl-4">
                              {renderCheckbox()}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="card-footer">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-9">
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className={`btn btn-primary font-weight-bold me-2`}>
                            <i className="fa fa-eye" /> Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Formik>
        </ErrorBoundary>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dialogClose();
            // setToggetValue(false);
          }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(null, {getModuleList, cloneCampaign})(
  IsLoadingHOC(ModelForm),
);
