import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import * as utils from '../../../../_metronic/_helpers/LocalStorageHelpers';
import * as auth from '../_redux/authRedux';
import {login, getSidebarPanelConfig} from '../_redux/authCrud';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {toast} from 'react-toastify';
import {Formik, ErrorMessage, FieldArray, Field} from 'formik';
const initialValues = {
  email: '',
  password: '',
};

function Login(props) {
  const {intl} = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      }),
    ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  // const getInputClasses = (fieldname) => {
  //   if (formik.touched[fieldname] && formik.errors[fieldname]) {
  //     return 'is-invalid';
  //   }

  //   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
  //     return 'is-valid';
  //   }

  //   return '';
  // };

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: LoginSchema,
  //   onSubmit: async (values, {setStatus, setSubmitting}) => {
  //     enableLoading();
  //     //setTimeout(async () => {
  //     try {
  //       props.setLoading(true);
  //       props
  //         .login(values.email, values.password)
  //         .then((res) => {
  //           //  console.log("res");
  //           toast.success(res);
  //           props.setLoading(false);
  //         })
  //         .catch((error) => {
  //           props.setLoading(false);
  //           error.map((item) => {
  //             toast.error(item.message);
  //           });
  //         });

  //       // const { accessToken } = data.data
  //       // utils.setStorage('accessToken', accessToken)
  //       // //delete (data.data['accessToken'])
  //       // delete (data.data['refreshToken'])
  //       // delete (data.data['tokenType'])
  //       //let sideMenu = await getSidebarPanelConfig();
  //       //const { uiPanels } = sideMenu.data
  //       // data.data["config"] = uiPanels;
  //       // props.login(data.data);
  //       // props.setUserConfig(uiPanels)
  //       disableLoading();
  //     } catch (err) {
  //       setStatus(
  //         intl.formatMessage({
  //           id: 'AUTH.VALIDATION.INVALID_LOGIN',
  //         }),
  //       );
  //     } finally {
  //       disableLoading();
  //       setSubmitting(false);
  //     }
  //     //  }, 1000);
  //   },
  // });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <Formik
        className="form fv-plugins-bootstrap fv-plugins-framework"
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, {setStatus, setSubmitting}) => {
          enableLoading();
          //setTimeout(async () => {
          try {
            props.setLoading(true);
            props
              .login(values.email, values.password)
              .then((res) => {
                //  console.log("res");
                toast.success(res);
                props.setLoading(false);
              })
              .catch((error) => {
                props.setLoading(false);
                error.map((item) => {
                  toast.error(item.message);
                });
              });

            disableLoading();
          } catch (err) {
            setStatus(
              intl.formatMessage({
                id: 'AUTH.VALIDATION.INVALID_LOGIN',
              }),
            );
          } finally {
            disableLoading();
            setSubmitting(false);
          }
        }}
        validationSchema={LoginSchema}
        onReset={() => {}}>
        {({
          values,
          handleReset,
          handleSubmit,
          handleChange,
          touched,
          errors,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework">
            <>
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Email"
                  type="text"
                  data-test="sign-in-username-input"
                  className={`form-control form-control-solid h-auto py-5 px-6 `}
                  name="email"
                  onChange={handleChange}
                  value={values?.email}
                  // {...formik.getFieldProps('email')}
                />
                {touched.email && errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors.email}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Password"
                  type="password"
                  data-test="sign-in-password-input"
                  className={`form-control form-control-solid h-auto py-5 px-6 `}
                  name="password"
                  onChange={handleChange}
                  value={values?.password}
                />
                {touched.password && errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors.password}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-end">
                <Link
                  to="/auth/forgot-password"
                  className="text-dark-50 text-hover-primary my-3 me-2"
                  id="kt_login_forgot">
                  <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                </Link>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  data-test="sign-in-sign-in-button"
                  //onClick={handleSubmit}
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
                  <span>Sign In</span>
                  {loading && (
                    <span className="ms-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </>
          </form>
        )}
      </Formik>
      {/*end::Form*/}
    </div>
  );
}

//export default injectIntl(connect(null, auth.actions)(IsLoadingHOC(Login, "wating")));
export default injectIntl(connect(null, {login})(IsLoadingHOC(Login)));
