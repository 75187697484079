// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
//import * as actions from '../../../_redux/customers/customersActions';
import {getCampaignMappingList} from '../_redux/action';
import {connect} from 'react-redux';
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../_metronic/_helpers';
import * as uiHelpers from '../CampaignMappingUIHelpers';
import * as columnFormatters from './ActionsColumnFormatter';
import {Pagination} from '../../../../_metronic/_partials/controls';
// import {entities} from '../_mock';
import {useCampaignMappingUIContext} from '../CampaignMappingUIContext';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import filterFun from '../../../../_metronic/_helpers/FilterHelper';
import filterFactory from 'react-bootstrap-table2-filter';

const CampaignMappingTable = ({getCampaignMappingList, setLoading}) => {
  // CampaignMapping UI Context
  const campaignMappingUIContext = useCampaignMappingUIContext();
  const campaignMappingUIProps = useMemo(() => {
    return {
      ids: campaignMappingUIContext.ids,
      setIds: campaignMappingUIContext.setIds,
      queryParams: campaignMappingUIContext.queryParams,
      setQueryParams: campaignMappingUIContext.setQueryParams,
      openEditCampaignMappingDialog:
        campaignMappingUIContext.openEditCampaignMappingDialog,
      openDeleteCampaignMappingDialog:
        campaignMappingUIContext.openDeleteCampaignMappingDialog,
    };
  }, [campaignMappingUIContext]);

  // Getting curret state of campaignMapping list from store (Redux)
  const {currentState} = useSelector(
    (state) => ({currentState: state.campaignMapping}),
    shallowEqual,
  );
  const {totalCount, entities, listLoading} = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    campaignMappingUIProps.setIds([]);
    // server call by queryParams
    //  dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    // dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    // console.log('working', campaignMappingUIProps.queryParams);
    //dispatch(getCampaignMappingList(campaignMappingUIProps.queryParams)));

    async function callList() {
      try {
        setLoading(true);
        await getCampaignMappingList(campaignMappingUIProps.queryParams);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    callList();
    // }, [customersUIProps.queryParams, dispatch]);
  }, [dispatch, campaignMappingUIProps.queryParams]);
  // Table columns
  const columns = [
    {
      dataField: 'firstName',
      text: 'First Name',
      filter: filterFun(),
      headerSortingClasses,
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      filter: filterFun(),
      headerSortingClasses,
    },
    {
      dataField: 'username',
      text: 'User Name',
      filter: filterFun(),
    },
    {
      dataField: 'email',
      text: 'Email',
      filter: filterFun(),
    },
    // {
    //   dataField: 'createdAt',
    //   text: 'C',
    //   // sort: true,
    //   //  sortCaret: sortCaret,
    //   // formatter: columnFormatters.StatusColumnFormatter,
    //   headerSortingClasses,
    // },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCampaignMappingDialog:
          campaignMappingUIProps.openEditCampaignMappingDialog,
        openDeleteCampaignMappingDialog:
          campaignMappingUIProps.openDeleteCampaignMappingDialog,
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pe-3',
      style: {
        minWidth: '100px',
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: campaignMappingUIProps.queryParams.limit,
    page: campaignMappingUIProps.queryParams.page,
    // onPageChange: function (page, sizePerPage) {
    //   console.log(page);
    //   console.log(sizePerPage);
    // },
    // onSizePerPageChange: function (page, sizePerPage) {
    //   console.log(page);
    //   console.log(sizePerPage);
    // },
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                filter={filterFactory()}
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  campaignMappingUIProps.setQueryParams,
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: campaignMappingUIProps.ids,
                //   setIds: campaignMappingUIProps.setIds,
                // })}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default connect(null, {getCampaignMappingList})(
  IsLoadingHOC(CampaignMappingTable),
);
