import React, {useEffect, useState, useRef} from 'react';
import {Formik, ErrorMessage, FieldArray, Field} from 'formik';
import {FormHelperText, Switch, Typography, Tooltip} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {IconButton} from '@mui/material';
import {Button, Form} from 'react-bootstrap';
import {get} from 'lodash';
import {toast} from 'react-toastify';
import {connect, useSelector, useDispatch, shallowEqual} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {addRuleItem, updateRule, fetchRule} from '../_redux/action';
import AddRulevalidationSchema from '../Schema/AddRule.Schema';
import {useHistory, useParams} from 'react-router';
import FileManagerModel from '../../FileManager/index';
import ErrorBoundary from '../../../../_metronic/_helpers/ErrorBoundary';
import {ReactCodeJar, useCodeJar} from 'react-codejar';
import '../styles.css';

const highlight = (editor) => {
  let code = editor.textContent;
  code = code.replace(/\((\w+?)(\b)/g, '(<font color="#8a2be2">$1</font>$2');
  editor.innerHTML = code;
};

const AddRule = ({setLoading, addRuleItem, updateRule}) => {
  const initialValues = {
    ruleName: '',
    ruleCode: '',
    description: '',
    issueType: '',
    tag: '',
    status: 'inactive',
    isStoreRequired: false,
    isProductRequired: false,
    isUserDataRequired: false,
    isUserPointsRequired: false,
    isUserRewardsRequired: false,
    isUserActivityAttemptsRequired: false,
    isUserPurchaseHistoryRequired: false,
    isUserRewardClaimTokensRequired: false,
    isUserPackageCodeClaimRequired: false,
  };
  //const [code, setCode] = useState();
  const inputRef = useRef(null);
  const {id} = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRule(id));
  }, [id, dispatch]);
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const {ruleForEdit, listLoading} = useSelector(
    (state) => ({
      ruleForEdit: state.rule.ruleForEdit,
      listLoading: state.rule.listLoading,
    }),
    shallowEqual,
  );

  // server request for save
  const saveRule = (rule, resetForm) => {
    if (!id) {
      setLoading(true);
      addRuleItem(rule)
        .then((res) => {
          setLoading(false);
          resetForm(initialValues);
          toast.success('Rule Added.');
          history.push('/rule/list');
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      updateRule(id, rule)
        .then((res) => {
          setLoading(false);
          resetForm(initialValues);
          toast.success('Updated Rule');
          history.push('/rule/list');
        })
        .catch((err) => {
          setLoading(false);
          // err.forEach((item) => {
          //   toast.error(item.message);
          // });
        });
    }
  };

  let history = useHistory();
  const ToolTipsCustom = (title) => {
    return (
      <Tooltip
        title={
          <Typography variant="subtitle1" component="span">
            {title}
          </Typography>
        }>
        <IconButton>
          <HelpIcon />
        </IconButton>
      </Tooltip>
    );
  };
  if (listLoading) {
    return (
      <Typography variant="h3" component="div">
        Loading....
      </Typography>
    );
  }
  return (
    <ErrorBoundary>
      <Formik
        initialValues={
          id ? (ruleForEdit ? ruleForEdit : initialValues) : initialValues
        }
        enableReinitialize
        onSubmit={(values, {resetForm}) => {
          const newValues = {
            ...values,
            //ruleCode: code,
            status:
              values.status === true || values.status === 'active'
                ? 'active'
                : 'inactive',
          };
          saveRule(newValues, resetForm);
        }}
        validationSchema={AddRulevalidationSchema}
        onReset={() => {}}>
        {({
          values,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
        }) => (
          <>
            <div className="card card-custom">
              <div className="card-header card-header-tabs-line">
                <ul
                  className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                  data-remember-tab="tab_id"
                  role="tablist">
                  <li className="nav-item">
                    <span className={`nav-link active}`} data-toggle="tab">
                      {id ? 'Update' : 'Add'} Rule
                    </span>
                  </li>
                </ul>
                <button
                  type="text"
                  style={{margin: '16px'}}
                  onClick={() => history.push('/rule/list')}
                  className={`btn btn-info font-weight-bold me-2`}>
                  Back
                </button>
              </div>
              <div className="form">
                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Rule Name:
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        ref={inputRef}
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="ruleName"
                        value={values?.ruleName}
                      />

                      <FormHelperText>
                        <ErrorMessage
                          name="ruleName"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Rule Code:
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <textarea
                        cols="40"
                        rows="10"
                        style={{whiteSpace: 'nowrap'}}
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="ruleCode"
                        value={values?.ruleCode}
                      />

                      <FormHelperText>
                        <ErrorMessage
                          name="ruleCode"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Description:
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <textarea
                        cols="40"
                        rows="5"
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="description"
                        value={values.description}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="description"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Tag:
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="tag"
                        value={values?.tag}
                      />

                      <FormHelperText>
                        <ErrorMessage
                          name="tag"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Is Store Required
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="isStoreRequired"
                        checked={!!get(values, 'isStoreRequired')}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="isStoreRequired"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Is Product Required
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="isProductRequired"
                        checked={!!get(values, 'isProductRequired')}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="isProductRequired"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Is User Data Required
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="isUserDataRequired"
                        checked={!!get(values, 'isUserDataRequired')}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="isUserDataRequired"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Is User Activity Attempts Required
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="isUserActivityAttemptsRequired"
                        checked={
                          !!get(values, 'isUserActivityAttemptsRequired')
                        }
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="isUserActivityAttemptsRequired"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Is User Points Required
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="isUserPointsRequired"
                        checked={!!get(values, 'isUserPointsRequired')}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="isUserPointsRequired"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Is User Rewards Required
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="isUserRewardsRequired"
                        checked={!!get(values, 'isUserRewardsRequired')}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="isUserRewardsRequired"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Is User Purchase History Required
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="isUserPurchaseHistoryRequired"
                        checked={!!get(values, 'isUserPurchaseHistoryRequired')}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="isUserPurchaseHistoryRequired"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Is User Reward Claim Tokens Required
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="isUserRewardClaimTokensRequired"
                        checked={
                          !!get(values, 'isUserRewardClaimTokensRequired')
                        }
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="isUserRewardClaimTokensRequired"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Is User Package Code Claim Required
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="isUserPackageCodeClaimRequired"
                        checked={
                          !!get(values, 'isUserPackageCodeClaimRequired')
                        }
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="isUserPackageCodeClaimRequired"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Status
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="status"
                        checked={
                          values.status === 'active' || values.status === true
                            ? true
                            : false
                        }
                        // checked={!!get(values, 'status')}
                      />
                      {values.status === true || values.status === 'active'
                        ? 'active'
                        : 'inactive'}
                      <FormHelperText>
                        <ErrorMessage
                          name="status"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-9">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className={`btn btn-primary font-weight-bold me-2`}>
                        <i className="fa fa-eye" /> {id ? 'Update' : 'Submit'}
                      </button>{' '}
                      {!id && (
                        <button
                          type="button"
                          onClick={handleReset}
                          className={`btn btn-clean font-weight-bold me-2`}>
                          <i className="fa fa-recycle" /> Reset
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </ErrorBoundary>
  );
};

export default connect(null, {addRuleItem, updateRule})(IsLoadingHOC(AddRule));
