import React, {useEffect, useState, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {shallowEqual, useSelector} from 'react-redux';
//import * as actions from '../../../_redux/customers/customersActions';
import {getList, downloadFileAction} from '../_redux/action';
import {connect} from 'react-redux';
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  headerSortingClasses,
} from '../../../../_metronic/_helpers';
import * as uiHelpers from '../RBACUIHelpers';
import {Pagination} from '../../../../_metronic/_partials/controls';
import {RBACUIContext} from '../RBACUIContext';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import filterFactory from 'react-bootstrap-table2-filter';
import {useHistory} from 'react-router-dom';
const ListTable = ({getList, setLoading}) => {
  const history = useHistory();
  // RequestedReports UI Context
  const requestedReportsUIContext = RBACUIContext();
  const requestedReportsUIProps = useMemo(() => {
    return {
      queryParams: requestedReportsUIContext.queryParams,
      setQueryParams: requestedReportsUIContext.setQueryParams,
    };
  }, [requestedReportsUIContext]);

  // Getting curret state of RequestedReports list from store (Redux)
  const {campaignId, currentState} = useSelector(
    (state) => ({
      currentState: state.RBACReducer,
      campaignId: state.auth.campaignId,
    }),
    shallowEqual,
  );
  const {totalCount, entities, listLoading} = currentState;

  //const {entities} = currentState;
  // console.log('entities, data', entities);

  useEffect(() => {
    setLoading(true);
    getList({...requestedReportsUIContext.queryParams});
  }, [campaignId, requestedReportsUIProps.queryParams]);
  useEffect(() => {
    setLoading(listLoading);
  }, [listLoading]);

  // Table columns
  const columns = [
    {
      dataField: 'role',
      text: 'Role',
      headerSortingClasses,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerSortingClasses,
      formatter: (cellContent) => {
        return (
          <span
            className={
              cellContent === 'active' ? 'text-success' : 'text-primary'
            }>
            {cellContent ? cellContent.toUpperCase() : '-'}
          </span>
        );
      },
    },
    {
      dataField: 'description',
      text: 'Description',
      headerSortingClasses,
    },
    {
      dataField: 'updatedAt',
      text: 'Last Updated',
      headerSortingClasses,
      formatter: (cellContent) => {
        return (
          <span>
            {cellContent ? new Date(cellContent).toLocaleString() : ''}
          </span>
        );
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      headerSortingClasses,
      formatter: (cellContent, row) => {
        return (
          <a
            title="Edit"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => history.push(`/rbac/roles/edit/${row._id}`)}>
            <i className="fa fa-edit"></i>
          </a>
        );
      },
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: requestedReportsUIProps.queryParams.limit,
    page: requestedReportsUIProps.queryParams.page,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive text-nowrap filter-label-custom"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden w-auto"
                bootstrap4
                remote
                keyField="_id"
                filter={filterFactory()}
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  requestedReportsUIProps.setQueryParams,
                )}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default connect(null, {
  getList,
})(IsLoadingHOC(ListTable));
