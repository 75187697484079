import * as Yup from 'yup';
const AddRulevalidationSchema = Yup.object({
  ruleName: Yup.string()
    .min(2, 'Rule name should be greater than 2 letters')
    .max(100, 'Rule should be less than 100 letters')
    .required('Required'),
  description: Yup.string()
    .min(5, 'Short Description should be greater than 5 letters')
    .max(500, 'Short Description should be less than 200 letters')
    .required('Required'),
  ruleCode: Yup.string().required('Required'),
  tag: Yup.string().required('Required'),
  isStoreRequired: Yup.boolean().required('Required'),
  isProductRequired: Yup.boolean().required('Required'),
  isUserDataRequired: Yup.boolean().required('Required'),
  status: Yup.string().required('Required'),
  isUserPointsRequired: Yup.boolean().required('Required'),
  isUserRewardsRequired: Yup.boolean().required('Required'),
  // isUserPurchaseHistoryRequired: Yup.boolean().required('Required'),
  //isUserRewardClaimTokensRequired: Yup.boolean().required('Required'),
  isUserActivityAttemptsRequired: Yup.string().required('Required'),
});

export default AddRulevalidationSchema;
