import React, {useEffect, useState} from 'react';
import {Formik, ErrorMessage} from 'formik';
import {FormHelperText, Switch, Typography, Tooltip} from '@mui/material';
import {toast} from 'react-toastify';
import {connect, useSelector, useDispatch, shallowEqual} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {
  addCampaignMappingItem,
  updateCampaignMapping,
  fetchCampaignMapping,
  getUserList,
} from '../_redux/action';
import AddCampaignMappingvalidationSchema from '../Schema/AddCampaignMapping.Schema';
import {useHistory, useParams} from 'react-router';
import {Button} from 'react-bootstrap';
import FileManagerModel from '../../FileManager/index';
import ErrorBoundary from '../../../../_metronic/_helpers/ErrorBoundary';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   useParams
// } from "react-router-dom";

const AddCampaignMapping = ({
  setLoading,
  addCampaignMappingItem,
  updateCampaignMapping,
  getUserList,
}) => {
  const {campaignMappingForEdit, listLoading, lookup, campaign} = useSelector(
    (state) => ({
      campaignMappingForEdit: state.campaignMapping.campaignMappingForEdit,
      listLoading: state.campaignMapping.listLoading,
      lookup: state.auth.lookup,
      campaign: state.auth.campaign,
    }),
    shallowEqual,
  );
  const [showTextFM, setShowTextFM] = useState(false);
  const initialValues = {
    userId: '',
    campaignId: [],
  };
  const {id} = useParams();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    dispatch(fetchCampaignMapping(id));
  }, [id, dispatch]);

  useEffect(() => {
    setLoading(true);
    getUserList()
      .then((res) => {
        setLoading(false);
        res?.content && setUsers(res.content);
      })
      .catch((error) => {
        setLoading(false);
        console.log('eror', error);
      });
  }, []);

  // server request for save
  const saveCampaignMapping = (campaignMapping, resetForm) => {
    if (!id) {
      setLoading(true);
      addCampaignMappingItem(campaignMapping)
        .then((res) => {
          setLoading(false);
          resetForm(initialValues);
          toast.success('CampaignMapping Added.');
          history.push('/campaignMapping/list');
        })
        .catch((err) => {
          setLoading(false);
          err.forEach((item) => {
            toast.error(item.message);
          });
        });
    } else {
      setLoading(true);
      updateCampaignMapping(id, campaignMapping)
        .then((res) => {
          setLoading(false);
          resetForm(initialValues);
          toast.success('Updated CampaignMapping');
          history.push('/campaignMapping/list');
        })
        .catch((err) => {
          setLoading(false);
          err.forEach((item) => {
            toast.error(item.message);
          });
        });
    }
  };

  let history = useHistory();

  const renderCampaignList = () => {
    let content = campaign && campaign?.content ? campaign?.content : [];
    content = content.sort((a, b) => a.name.localeCompare(b.name));
    return content.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });
  };

  const renderUser = () => {
    return users.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.username}
        </option>
      );
    });
  };
  if (listLoading) {
    return (
      <Typography variant="h3" component="div">
        Loading....
      </Typography>
    );
  }
  return (
    <ErrorBoundary>
      <Formik
        initialValues={campaignMappingForEdit || initialValues}
        enableReinitialize
        onSubmit={(values, {resetForm}) => {
          const newValues = {
            ...values,
          };
          saveCampaignMapping(newValues, resetForm);
        }}
        validationSchema={AddCampaignMappingvalidationSchema}
        onReset={() => {}}>
        {({
          values,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <>
            <div className="card card-custom">
              {/*Header*/}
              <div className="card-header card-header-tabs-line">
                <ul
                  className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                  data-remember-tab="tab_id"
                  role="tablist">
                  <li className="nav-item">
                    <span className={`nav-link active}`} data-toggle="tab">
                      {id ? 'Update' : 'Add'} CampaignMapping
                    </span>
                  </li>
                </ul>
                <button
                  type="text"
                  style={{margin: '16px'}}
                  onClick={() => history.push('/campaignMapping/list')}
                  className={`btn btn-info font-weight-bold me-2`}>
                  Back
                </button>
              </div>

              <div className="form">
                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      User :
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <select
                        //disabled={id ? true : false}
                        className="form-control form-control-lg form-control-solid"
                        name="userId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.actionType}>
                        <option value="">Select User</option>
                        {renderUser()}
                      </select>
                      <FormHelperText>
                        <ErrorMessage
                          name="userId"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Campaign :
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <select
                        multiple
                        //   disabled={id ? true : false}
                        className="form-control form-control-lg form-control-solid"
                        name="campaignId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.campaignId}>
                        <option value="">Select Campaign</option>
                        {renderCampaignList()}
                      </select>
                      <FormHelperText>
                        <ErrorMessage
                          name="campaignId"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-9">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className={`btn btn-primary font-weight-bold me-2`}>
                        <i className="fa fa-eye" /> {id ? 'Update' : 'Submit'}
                      </button>{' '}
                      {!id && (
                        <button
                          type="button"
                          onClick={handleReset}
                          className={`btn btn-clean font-weight-bold me-2`}>
                          <i className="fa fa-recycle" /> Reset
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </ErrorBoundary>
  );
};

export default connect(null, {
  addCampaignMappingItem,
  updateCampaignMapping,
  getUserList,
})(IsLoadingHOC(AddCampaignMapping));
