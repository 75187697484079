/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
import {useLocation, useHistory} from 'react-router';
import {NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {connect} from 'react-redux';
import {TextField} from '@mui/material';
import {withStyles} from '@mui/styles';
import {Autocomplete} from '@mui/material';
import {checkIsActive} from '../../../../_helpers';
import {
  getMenu,
  getCampaignList,
  regenerateCampaignToken,
} from '../../../../../app/modules/Auth/_redux/authCrud';
import IsLoadingHOC from '../../HOC/IsLoadingHOC';
import './styles.css';

const styles = {
  root: {},
  inputRoot: {
    padding: 4,
    backgroundColor: '#fff',

    '&[class*="MuiOutlinedInput-root"]': {
      // Default left padding is 6px
      padding: 4,
    },
  },
};

const icons = {
  'Receipt Summary': 'fas fa-file-invoice',
  Campaigns: 'far fa-flag',
  'Panel Users': 'fas fa-users',
  'Master Rewards': 'fas fa-gift',
  'Reward Summary': 'fas fa-gifts',
  'Pincode Report': 'fas fa-map-marker-alt',
  'IAM Policies': 'fas fa-user-tag',
  Products: 'fas fa-list',
  Dashboard: 'fas fa-tachometer-alt',
  'End Users': 'fas fa-users',
  Activities: 'far fa-star',
  'Campaign Rewards': 'fas fa-gift',
  'PIN Codes': 'fas fa-map-pin',
  Contests: 'fas fa-puzzle-piece',
  Template: 'fas fa-paste',
  'Rule Engine': 'fas fa-check-double',
  'Dynamic messaging': 'far fa-envelope',
  'Process Automation': 'fas fa-cogs',
  Receipts: 'fas fa-file-invoice',
  Retailers: 'fas fa-store',
  Heatmap: 'fas fa-map-marked-alt',
  'Campaign Mapping': 'far fa-thumbs-up',
  'Support Tickets': 'fas fa-headset',
};

const AsideMenuList = ({
  layoutProps,
  setLoading,
  getMenu,
  getCampaignList,
  regenerateCampaignToken,
  classes,
}) => {
  const location = useLocation();

  const {campaign, campaignId, accessToken} = useSelector(
    (state) => state.auth,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getMenu()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    async function fetchData() {
      getCampaignList()
        .then((response) => {
          //  console.log(response);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }

    fetchData();
  }, [accessToken]);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && 'menu-item-active'
        } menu-item-open menu-item-not-hightlighted`
      : '';
  };

  const {menu, tenantConfig} = useSelector((state) => state.auth);

  const selectCampaign = (e) => {
    //console.log(e.target.value);
    const {value} = e.target;
    if (value) {
      setLoading(true);
      const arr = value.split('-');

      //console.log(arr);
      regenerateCampaignToken(arr[0], arr[1])
        .then((response) => {
          setLoading(false);
          dispatch({type: 'SAVE_CAMPAIGN_ID', payload: value});
          // window.location.reload(false);
          // console.log('response check kdkfkd', response);
          const url = window.location.pathname.split('/');
          // console.log('urltest', url);
          history.push(
            '/' + url.length > 1 ? url[1] : window.location.pathname,
          );
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const renderCampaignOption = () => {
    let content = campaign && campaign?.content ? campaign?.content : [];
    content = content.sort((a, b) => a.name.localeCompare(b.name));
    return content.map((item) => ({
      value: `${item.id}-${item.clientId}`,
      label: item.name,
    }));
  };

  return (
    <div className="">
      {/* admin menu*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {menu?.adminMenu
          ?.sort((a, b) => a.order - b.order)
          .map((item) => {
            return (
              <li
                key={item.id}
                className={`menu-item ${
                  item?.subMenus && 'menu-item-submenu'
                } ${getMenuItemActive(item?.route, true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover">
                <NavLink
                  className={`menu-link ${item?.subMenus && 'menu-toggle'}`}
                  to={item?.route ? item?.route : '/'}>
                  <i
                    className={icons[item.name] || 'fas fa-list'}
                    style={{lineHeight: 'unset'}}
                  />
                  <span className="menu-text" style={{marginLeft: '5px'}}>
                    {item.name}
                  </span>
                  {item?.subMenus && <i className="menu-arrow" />}
                </NavLink>
                {item?.subMenus && (
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      {item?.subMenus.map((item) => {
                        return (
                          <li
                            key={item.id}
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                              item.route,
                              true,
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover">
                            <NavLink
                              className="menu-link menu-toggle"
                              to={item?.route ? item.route : '/'}>
                              <i className="menu-bullet icon-layers ng-star-inserted">
                                <span />
                              </i>
                              <span className="menu-text m-2">{item.name}</span>
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </li>
            );
          })}

        <li className="menu-item">
          <div className="menu-link">
            <i
              className="fas fa-ellipsis-v"
              style={{lineHeight: 2, padding: '0 4px', fontSize: '20px'}}
            />

            <Autocomplete
              id="combo-box-demo"
              options={renderCampaignOption()}
              getOptionLabel={(option) => option.label}
              style={{width: 300}}
              disableClearable={true}
              classes={classes}
              value={
                renderCampaignOption().find((val) => val.value == campaignId) ||
                null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{borderRadius: 4}}
                  placeholder="Select Site"
                  variant="outlined"
                />
              )}
              onChange={(el, op) => {
                selectCampaign({
                  target: {
                    value: op.value,
                  },
                });
              }}
            />
          </div>
        </li>
      </ul>
      <hr style={{borderTop: '1px solid #fff', margin: '0 20px'}} />

      {/* campaign menu*/}
      {campaignId && menu?.campaignMenu?.length > 0 && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {menu?.campaignMenu
            ?.sort((a, b) => a.order - b.order)
            // ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => {
              if (
                !tenantConfig?.helpDeskServices?.enable &&
                item.name == 'Support Tickets'
              ) {
                return null;
              }

              return (
                <li
                  key={item.id}
                  className={`menu-item ${
                    item?.subMenus && 'menu-item-submenu'
                  } ${getMenuItemActive(item?.route, true)}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover">
                  <NavLink
                    className={`menu-link ${item?.subMenus && 'menu-toggle'}`}
                    to={item?.route ? item?.route : '/'}>
                    <i
                      className={icons[item.name] || 'fas fa-list'}
                      style={{lineHeight: 'unset'}}
                    />
                    <span className="menu-text" style={{marginLeft: '5px'}}>
                      {item.name}
                    </span>
                    {item?.subMenus && <i className="menu-arrow" />}
                  </NavLink>
                  {item?.subMenus && (
                    <div className="menu-submenu">
                      <ul className="menu-subnav">
                        {item?.subMenus.map((item) => {
                          return (
                            <li
                              key={item.id}
                              className={`menu-item menu-item-submenu ${getMenuItemActive(
                                item.route,
                                true,
                              )}`}
                              aria-haspopup="true"
                              data-menu-toggle="hover">
                              <NavLink
                                className="menu-link menu-toggle"
                                to={item?.route ? item.route : '/'}>
                                <i className="menu-bullet icon-layers ng-star-inserted">
                                  <span />
                                </i>
                                <span className="menu-text m-2">
                                  {item.name}
                                </span>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </li>
              );
            })}
          {/* <li
            className="menu-item menu-item-submenu"
            aria-haspopup="true"
            data-menu-toggle="hover">
            <NavLink className={`menu-link`} to="/heatmap">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/Shopping/Bag2.svg')}
                />
              </span>
              <span className="menu-text">Heatmap</span>
            </NavLink>
          </li> */}
        </ul>
      )}
    </div>
  );
};

export default connect(null, {
  getMenu,
  getCampaignList,
  regenerateCampaignToken,
})(IsLoadingHOC(withStyles(styles)(AsideMenuList)));
