import React, {useEffect, useState} from 'react';
import FirstChart from '../charts/FirstChart';
import {Row, Col} from 'react-bootstrap';
import {getLookUpDataChart} from '../../../app/modules/Auth/_redux/authCrud';
import {connect, useSelector} from 'react-redux';
import IsLoadingHOC from '../../layout/components/HOC/IsLoadingHOC';

import DatePicker from 'react-datepicker';

//import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';

const ChartsWidget = ({getLookUpDataChart}) => {
  const state = useSelector((state) => state);
  const [startDate, setStartDate] = useState(
    Date.now() - 10 * 24 * 60 * 60 * 1000,
  );
  const [endDate, setEndDate] = useState(new Date());
  useEffect(() => {
    async function lookUp() {
      getLookUpDataChart();
    }

    lookUp();
  }, [state.auth.accessToken]);

  const exportToPdf = () => {
    exportNewPdf();
    // const input = document.getElementById('divToPrint');
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF('p', 'pt', 'a4', false);
    //   pdf.addImage(imgData, 'JPEG', 0, 0, 600, 0, undefined, false);
    //   // pdf.output('dataurlnewwindow');
    //   pdf.save(`${startDate}-${endDate}.pdf`);
    // });
  };

  const exportNewPdf = () => {
    htmlToImage
      .toPng(document.getElementById('divToPrint'), {quality: 0.95})
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = 280;
        pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${startDate}-${endDate}.pdf`);
      });
  };

  // console.log('state.auth?.lookupChart?', state.auth?.lookupChart);
  return (
    <>
      <Row>
        <Col>
          {state.auth?.lookupChart?.data &&
            state.auth?.lookupChart?.data.length > 0 && (
              <div className={`card card-custom gutter-b`}>
                {/* begin::Header */}
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">
                      Please Select the Date Range
                    </span>
                  </h3>
                  <div className="d-flex  ps-5 flex-row">
                    <div className="me-3">
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        className="form-control form-control-md form-control-solid "
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        showYearDropdown
                        enableTabLoop
                        dropdownMode="select"
                      />
                    </div>
                    <div className="me-3">
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        className="form-control form-control-md form-control-solid"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        showYearDropdown
                        enableTabLoop
                        dropdownMode="select"
                      />
                    </div>
                    <div className="me-3">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={exportToPdf}>
                        Export to PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </Col>
      </Row>
      <Row id="divToPrint" className="mt4">
        {state.auth?.lookupChart?.data &&
          state.auth?.lookupChart?.data.length > 0 &&
          state.auth?.lookupChart?.data.map((item) => (
            <Col
              key={item.reportId}
              className={
                item.cols
                  ? `col-xs-12 col-md-${12 / parseInt(item.cols)} col-lg-${
                      12 / parseInt(item.cols)
                    } col-xl-${12 / parseInt(item.cols)}`
                  : 'col-xs-12 col-md-6 col-lg-6 col-xl-4'
              }>
              <FirstChart
                className="card-stretch gutter-b"
                data={item}
                startDate={startDate}
                endDate={endDate}
              />
            </Col>
          ))}
      </Row>
    </>
  );
};

export default connect(null, {
  getLookUpDataChart,
})(IsLoadingHOC(ChartsWidget));
