import {forEach} from 'lodash-es';
import React, {useState, useEffect} from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Switch} from '@mui/material';
import ErrorBoundary from '../../_helpers/ErrorBoundary';
import {parse} from 'date-fns';

const requiredFields = ['firstName', 'lastName', 'email', 'username'];
const UiConfig = ({data: tenantConfig, onChange, setExpanded}) => {
  let {id} = useParams();
  const [customFieldlist, setCustomFieldlist] = useState([]);
  const [extraFields, setExtraFields] = useState([]);
  const [extraFieldValue, setExtraFieldValue] = useState();
  const [wizardStep, setWizardStep] = useState(1);
  const {customFields} = useSelector((state) => state.auth);
  const {uiConfig} = tenantConfig;
  const selectedCustomFieldValues =
    uiConfig?.userFields &&
    uiConfig.userFields.filter((item) => !requiredFields.includes(item.name));

  useEffect(() => {
    let selectedCustomFieldValues =
      uiConfig &&
      uiConfig?.userFields?.filter(
        (item) => !requiredFields.includes(item.name),
      );
    if (!selectedCustomFieldValues) {
      selectedCustomFieldValues = [];
    }
    //console.log("selectedCustomFieldValues", selectedCustomFieldValues);
    setExtraFields(selectedCustomFieldValues);
    const selectedCustomFieldsArray =
      selectedCustomFieldValues &&
      selectedCustomFieldValues.map((item) => {
        return item.name;
      });
    const getCustomFieldList =
      selectedCustomFieldsArray &&
      customFields.filter(
        (item) => !selectedCustomFieldsArray.includes(item.name),
      );
    setCustomFieldlist(getCustomFieldList);
  }, [uiConfig]);

  const wizardPrevious = () => {
    if (wizardStep === 1) {
      setExpanded('panel2');
    }
    if (wizardStep > 1) {
      setWizardStep(wizardStep - 1);
    }
  };

  const wizardNext = () => {
    if (wizardStep >= 3) {
      setExpanded('panel4');
    } else {
      setWizardStep(wizardStep + 1);
    }
  };

  const handleCustomField = (e) => {
    //console.log("handle changes");
    const {name, value} = e.target;
    setExtraFieldValue(value);
  };

  const addExtraField = () => {
    if (extraFieldValue) {
      const customFieldsValues = customFields.find(
        (item) => item.name === extraFieldValue,
      );
      //  console.log("set Extra Fields", customFieldsValues);

      const configNewUpdated = {
        ...tenantConfig,
        uiConfig: {
          ...tenantConfig?.uiConfig,
          userFields: [
            ...tenantConfig?.uiConfig?.userFields,
            customFieldsValues,
          ],
        },
      };
      const remaningList = customFieldlist.filter(
        (item) => item.name !== extraFieldValue,
      );
      setCustomFieldlist(remaningList);
      setExtraFields([...extraFields, customFieldsValues]);
      //setExtraFieldValue to Null
      setExtraFieldValue('');

      //console.log("configNewUpdated", configNewUpdated);

      onChange(configNewUpdated);
    }
  };

  const removeExtraField = (name) => {
    //   console.log(name);
    const findFieldFromCustomField = customFields.find(
      (item) => item.name === name,
    );
    if (findFieldFromCustomField) {
      setCustomFieldlist([...customFieldlist, findFieldFromCustomField]);
    }

    const extraFieldNew = extraFields.filter((item) => item.name !== name);
    setExtraFields(extraFieldNew);
    //console.log("configNewUpdated", configNewUpdated);
    const customFieldsValues = tenantConfig.uiConfig.userFields.filter(
      (item) => item.name !== name,
    );
    const configNewUpdated = {
      ...tenantConfig,
      uiConfig: {
        ...tenantConfig?.uiConfig,
        userFields: [...customFieldsValues],
      },
    };
    onChange(configNewUpdated);
  };

  const handleChanges = (e) => {
    const {name, value} = e.target;
    const arrayValue = name.split('.');
    //  console.log(arrayValue);
    let configNewUpdated = {};
    if (arrayValue[0] === 'password') {
      const passwordRule = tenantConfig?.uiConfig?.passwordRule;
      passwordRule[arrayValue[1]] = value;
      //console.log("password rule", passwordRule);
      configNewUpdated = {
        ...tenantConfig,
        uiConfig: {...tenantConfig?.uiConfig, passwordRule: {...passwordRule}},
      };
    } else {
      const allFields = tenantConfig?.uiConfig?.userFields;
      let item = allFields.find((x) => x.name === arrayValue[0]);
      // console.log('value', name);
      // console.log('typeof', typeof value);
      // console.log('items-----------> ', item);
      if (item) {
        item.validation[arrayValue[1]] = value;
      }
      //  console.log("all firels", allFields);
      configNewUpdated = {
        ...tenantConfig,
        uiConfig: {...tenantConfig?.uiConfig, userFields: [...allFields]},
      };
    }

    //console.log(configNewUpdated);
    // dispatch({type: "SAVE_TENANT_CONFIG", payload: configNewUpdated})
    onChange(configNewUpdated);
  };

  const tabClasses = 'navi-link py-4';
  const tabActiveClasses = 'navi-link py-4 active';
  const stepHeading =
    wizardStep && wizardStep === 1
      ? 'Password Configuration'
      : wizardStep === 2
      ? 'Registration Field Configuration'
      : 'Custom Registration Fields';
  //console.log("pointTier", pointTierConfig);
  //console.log("ui config", tenantConfig);
  return (
    <ErrorBoundary>
      <div className="d-flex flex-column flex-root">
        <div className="container">
          <div className="d-flex flex-row">
            <div
              className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
              id="kt_profile_aside">
              <div className="card card-custom card-stretch">
                <div className="card-body pt-4">
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <div className="navi-item mb-2">
                      <span
                        className={
                          wizardStep === 1 ? tabActiveClasses : tabClasses
                        }>
                        <span className="navi-icon me-2">
                          <span className="svg-icon">1</span>
                        </span>
                        <span className="navi-text font-size-lg">
                          Password Configuration
                        </span>
                      </span>
                    </div>
                    <div className="navi-item mb-2">
                      <span
                        className={
                          wizardStep === 2 ? tabActiveClasses : tabClasses
                        }>
                        <span className="navi-icon me-2">
                          <span className="svg-icon">2</span>
                        </span>
                        <span className="navi-text font-size-lg">
                          Registration Field Configuration
                        </span>
                      </span>
                    </div>
                    <div className="navi-item mb-2">
                      <span
                        className={
                          wizardStep === 3 ? tabActiveClasses : tabClasses
                        }>
                        <span className="navi-icon me-2">
                          <span className="svg-icon">3</span>
                        </span>
                        <span className="navi-text font-size-lg">
                          Custom Registraion Fields
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-row-fluid ml-lg-8">
              <div className="card card-custom">
                {/* {console.log(".............tenantData", tenantData, isNewTenant)} */}

                <Form className="form">
                  <div
                    className="card-header py-3"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingTop: '20px ​!important',
                    }}>
                    <div className="card-title align-items-start flex-column">
                      <h3 className="card-label font-weight-bolder text-dark">
                        {stepHeading}
                      </h3>
                      <span className="text-muted font-weight-bold font-size-sm mt-1">
                        Ui configuration
                      </span>
                    </div>
                    <div className="card-toolbar">
                      <button
                        type="button"
                        //  disabled={wizardStep <= 1 && 'disabled'}
                        className="btn btn-success me-2"
                        onClick={() => wizardPrevious()}>
                        MOVE PREVIOUS
                      </button>
                      <button
                        type="button"
                        // disabled={wizardStep >= 3 && 'disabled'}
                        className="btn btn-success me-2"
                        onClick={() => wizardNext()}>
                        MOVE NEXT
                      </button>
                    </div>
                  </div>

                  <div className="card-body">
                    {wizardStep === 1 && (
                      <>
                        <Row className="g-2">
                          <Col md>
                            <Form.Group className="mb-3">
                              <Form.Label>Min. Length</Form.Label>
                              <input
                                className="form-control form-control-lg form-control-solid"
                                name="password.minLength"
                                onChange={(e) =>
                                  handleChanges({
                                    target: {
                                      name: e.target.name,
                                      value: isNaN(parseInt(e.target.value))
                                        ? ''
                                        : parseInt(e.target.value),
                                    },
                                  })
                                }
                                type="number"
                                min="0"
                                value={
                                  (uiConfig &&
                                    uiConfig?.passwordRule?.minLength) ||
                                  ''
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md>
                            <Form.Group className="mb-3">
                              <Form.Label>Max. Length</Form.Label>
                              <input
                                className="form-control form-control-lg form-control-solid"
                                name="password.maxLength"
                                onChange={(e) =>
                                  handleChanges({
                                    target: {
                                      name: e.target.name,
                                      value: isNaN(parseInt(e.target.value))
                                        ? ''
                                        : parseInt(e.target.value),
                                    },
                                  })
                                }
                                type="number"
                                value={
                                  (uiConfig &&
                                    uiConfig?.passwordRule?.maxLength) ||
                                  ''
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="g-2">
                          <Col md>
                            <Form.Group className="mb-3">
                              <Form.Label>Lower Case Required</Form.Label>
                              <select
                                className="form-control form-control-lg form-control-solid"
                                onChange={handleChanges}
                                name="password.lowerCaseReq"
                                value={
                                  uiConfig &&
                                  uiConfig?.passwordRule?.lowerCaseReq
                                }>
                                <option value="">Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            </Form.Group>
                          </Col>
                          <Col md>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Special character Required
                              </Form.Label>
                              <select
                                className="form-control form-control-lg form-control-solid"
                                onChange={handleChanges}
                                name="password.specialCharReq"
                                value={
                                  uiConfig &&
                                  uiConfig?.passwordRule?.specialCharReq
                                }>
                                <option value="">Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="g-2">
                          <Col md>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Different from last n Passwords
                              </Form.Label>
                              <input
                                className="form-control form-control-lg form-control-solid"
                                name="password.pwdBucketSize"
                                onChange={(e) =>
                                  handleChanges({
                                    target: {
                                      name: e.target.name,
                                      value: isNaN(parseInt(e.target.value))
                                        ? ''
                                        : parseInt(e.target.value),
                                    },
                                  })
                                }
                                type="number"
                                value={
                                  (uiConfig &&
                                    uiConfig?.passwordRule?.pwdBucketSize) ||
                                  ''
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md>
                            <Form.Group className="mb-3">
                              <Form.Label>Upper Case Required</Form.Label>
                              <select
                                className="form-control form-control-lg form-control-solid"
                                onChange={handleChanges}
                                name="password.upperCaseReq"
                                value={
                                  uiConfig &&
                                  uiConfig?.passwordRule?.upperCaseReq
                                }>
                                <option value="">Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="g-2">
                          <Col md>
                            <Form.Group className="mb-3">
                              <Form.Label>Has Numeric</Form.Label>
                              <div>
                                <Switch
                                  name="password.hasNumeric"
                                  onChange={({target}) =>
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    })
                                  }
                                  color="primary"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                  checked={
                                    (uiConfig &&
                                      uiConfig?.passwordRule?.hasNumeric) ||
                                    false
                                  }
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md>
                            <Form.Group className="mb-3">
                              <Form.Label>Has Alpha</Form.Label>
                              <div>
                                <Switch
                                  name="password.hasAlpha"
                                  onChange={({target}) =>
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    })
                                  }
                                  color="primary"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                  checked={
                                    (uiConfig &&
                                      uiConfig?.passwordRule?.hasAlpha) ||
                                    false
                                  }
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Is Password Field Mandatory?{' '}
                              </Form.Label>
                              <div>
                                <Switch
                                  name="password.mandatory"
                                  onChange={({target}) =>
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    })
                                  }
                                  color="primary"
                                  checked={
                                    (uiConfig &&
                                      uiConfig?.passwordRule?.mandatory) ||
                                    false
                                  }
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-right">Special character RegEx</label>
                                <div className="col-lg-9 col-xl-6">
                                <input
                                    className="form-control form-control-lg form-control-solid"
                                    name="password.specialCharRegEx"
                                    onChange={handleChanges}
                                    type="text"
                                    value={uiConfig &&  uiConfig.passwordRule.specialCharRegEx}
                                  />
                                </div>
                              </div> */}
                      </>
                    )}

                    {wizardStep === 2 && (
                      <>
                        {/* first name */}
                        {uiConfig?.userFields?.find(
                          (item) => item.name === 'firstName',
                        ) && (
                          <>
                            <div className="col-form-label text-left">
                              <h4>First Name</h4>
                            </div>
                            <Row
                              className="g-2"
                              style={{border: '1px solid #ccc'}}>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Min. Length</Form.Label>
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    name="firstName.minLength"
                                    onChange={(e) =>
                                      handleChanges({
                                        target: {
                                          name: e.target.name,
                                          value: isNaN(parseInt(e.target.value))
                                            ? ''
                                            : parseInt(e.target.value),
                                        },
                                      })
                                    }
                                    type="number"
                                    value={
                                      uiConfig?.userFields.find(
                                        (item) => item.name === 'firstName',
                                      )?.validation?.minLength
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Max. Length</Form.Label>
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    name="firstName.maxLength"
                                    onChange={(e) =>
                                      handleChanges({
                                        target: {
                                          name: e.target.name,
                                          value: isNaN(parseInt(e.target.value))
                                            ? ''
                                            : parseInt(e.target.value),
                                        },
                                      })
                                    }
                                    type="number"
                                    value={
                                      uiConfig?.userFields.find(
                                        (item) => item.name === 'firstName',
                                      )?.validation?.maxLength
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Has Numeric</Form.Label>
                                  <div>
                                    <Switch
                                      name="firstName.hasNumeric"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'firstName',
                                        )?.validation?.hasNumeric
                                      }
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Has Alpha</Form.Label>
                                  <div>
                                    <Switch
                                      name="firstName.hasAlpha"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'firstName',
                                        )?.validation?.hasAlpha
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Mandatory</Form.Label>
                                  <div>
                                    <Switch
                                      name="firstName.mandatory"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'firstName',
                                        )?.validation?.mandatory
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        )}

                        {/* last name */}
                        {uiConfig?.userFields?.find(
                          (item) => item.name === 'lastName',
                        ) && (
                          <>
                            <div className="col-form-label text-left">
                              <h4>Last Name</h4>
                            </div>
                            <Row
                              className="g-2"
                              style={{border: '1px solid #ccc'}}>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Min. Length</Form.Label>
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    name="lastName.minLength"
                                    onChange={(e) =>
                                      handleChanges({
                                        target: {
                                          name: e.target.name,
                                          value: isNaN(parseInt(e.target.value))
                                            ? ''
                                            : parseInt(e.target.value),
                                        },
                                      })
                                    }
                                    type="number"
                                    value={
                                      uiConfig?.userFields.find(
                                        (item) => item.name === 'lastName',
                                      )?.validation?.minLength
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Max. Length</Form.Label>
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    name="lastName.maxLength"
                                    onChange={(e) =>
                                      handleChanges({
                                        target: {
                                          name: e.target.name,
                                          value: isNaN(parseInt(e.target.value))
                                            ? ''
                                            : parseInt(e.target.value),
                                        },
                                      })
                                    }
                                    type="number"
                                    value={
                                      uiConfig?.userFields.find(
                                        (item) => item.name === 'lastName',
                                      )?.validation?.maxLength
                                    }
                                  />
                                </Form.Group>
                              </Col>{' '}
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Has Numeric</Form.Label>
                                  <div>
                                    <Switch
                                      name="lastName.hasNumeric"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'lastName',
                                        )?.validation?.hasNumeric
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Has Alpha</Form.Label>
                                  <div>
                                    <Switch
                                      name="lastName.hasAlpha"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'lastName',
                                        )?.validation?.hasAlpha
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Mandatory</Form.Label>
                                  <div>
                                    <Switch
                                      name="lastName.mandatory"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'lastName',
                                        )?.validation?.mandatory
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        )}

                        {/* user name */}
                        {uiConfig?.userFields?.find(
                          (item) => item.name === 'username',
                        ) && (
                          <>
                            {' '}
                            <div className="col-form-label text-left">
                              <h4>User Name</h4>
                            </div>
                            <Row
                              className="g-2"
                              style={{border: '1px solid #ccc'}}>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Min. Length</Form.Label>
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    name="username.minLength"
                                    onChange={(e) =>
                                      handleChanges({
                                        target: {
                                          name: e.target.name,
                                          value: isNaN(parseInt(e.target.value))
                                            ? ''
                                            : parseInt(e.target.value),
                                        },
                                      })
                                    }
                                    type="number"
                                    value={
                                      uiConfig?.userFields.find(
                                        (item) => item.name === 'username',
                                      )?.validation?.minLength
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Max. Length</Form.Label>
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    name="username.maxLength"
                                    onChange={(e) =>
                                      handleChanges({
                                        target: {
                                          name: e.target.name,
                                          value: isNaN(parseInt(e.target.value))
                                            ? ''
                                            : parseInt(e.target.value),
                                        },
                                      })
                                    }
                                    type="number"
                                    value={
                                      uiConfig?.userFields.find(
                                        (item) => item.name === 'username',
                                      )?.validation?.maxLength
                                    }
                                  />
                                </Form.Group>
                              </Col>{' '}
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Has Numeric</Form.Label>
                                  <div>
                                    <Switch
                                      name="username.hasNumeric"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'username',
                                        )?.validation?.hasNumeric
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Has Alpha</Form.Label>
                                  <div>
                                    <Switch
                                      name="username.hasAlpha"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'username',
                                        )?.validation?.hasAlpha
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Mandatory</Form.Label>
                                  <div>
                                    <Switch
                                      name="username.mandatory"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'username',
                                        )?.validation?.mandatory
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        )}
                        {uiConfig?.userFields?.find(
                          (item) => item.name === 'email',
                        ) && (
                          <>
                            <div className="col-form-label text-left">
                              <h4>Email</h4>
                            </div>
                            <Row
                              className="g-2"
                              style={{border: '1px solid #ccc'}}>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Min. Length</Form.Label>
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    name="email.minLength"
                                    onChange={(e) =>
                                      handleChanges({
                                        target: {
                                          name: e.target.name,
                                          value: isNaN(parseInt(e.target.value))
                                            ? ''
                                            : parseInt(e.target.value),
                                        },
                                      })
                                    }
                                    type="number"
                                    value={
                                      uiConfig?.userFields.find(
                                        (item) => item.name === 'email',
                                      )?.validation?.minLength
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Max. Length</Form.Label>
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    name="email.maxLength"
                                    onChange={(e) =>
                                      handleChanges({
                                        target: {
                                          name: e.target.name,
                                          value: isNaN(parseInt(e.target.value))
                                            ? ''
                                            : parseInt(e.target.value),
                                        },
                                      })
                                    }
                                    type="number"
                                    value={
                                      uiConfig?.userFields.find(
                                        (item) => item.name === 'email',
                                      )?.validation?.maxLength
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Has Numeric</Form.Label>
                                  <div>
                                    <Switch
                                      name="email.hasNumeric"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'email',
                                        )?.validation?.hasNumeric
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Has Alpha</Form.Label>
                                  <div>
                                    <Switch
                                      name="email.hasAlpha"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'email',
                                        )?.validation?.hasAlpha
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Mandatory</Form.Label>
                                  <div>
                                    <Switch
                                      name="email.mandatory"
                                      onChange={({target}) =>
                                        handleChanges({
                                          target: {
                                            name: target.name,
                                            value: target.checked,
                                          },
                                        })
                                      }
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                      checked={
                                        uiConfig?.userFields.find(
                                          (item) => item.name === 'email',
                                        )?.validation?.mandatory
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                    {wizardStep === 3 && (
                      <>
                        <Row>
                          <Col md>
                            <Form.Group>
                              <label>Select Extra Fields</label>
                            </Form.Group>
                          </Col>
                          <Col md>
                            <Form.Group>
                              <Form.Control
                                onChange={handleCustomField}
                                name="extraFieldSelect"
                                as="select"
                                aria-label="Default select example">
                                <option value="">Select Fields</option>

                                {customFieldlist?.length > 0 &&
                                  customFieldlist.map((item) => {
                                    return (
                                      <option value={item.name} key={item.name}>
                                        {item.placeholder}
                                      </option>
                                    );
                                  })}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md>
                            <Form.Group>
                              <Button variant="primary" onClick={addExtraField}>
                                Add
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                        {extraFields.map((item) => {
                          return (
                            <div key={item.name}>
                              <div className="col-form-label text-left">
                                <h4>{item.placeholder}</h4>
                              </div>
                              <Row
                                className="g-2"
                                style={{border: '1px solid #ccc'}}>
                                <Col md>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Min. Length</Form.Label>
                                    <input
                                      className="form-control form-control-lg form-control-solid"
                                      name={`${item.name}.minLength`}
                                      onChange={(e) =>
                                        handleChanges({
                                          target: {
                                            name: e.target.name,
                                            value: isNaN(
                                              parseInt(e.target.value),
                                            )
                                              ? ''
                                              : parseInt(e.target.value),
                                          },
                                        })
                                      }
                                      type="number"
                                      value={item.validation['minLength']}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Max. Length</Form.Label>
                                    <input
                                      className="form-control form-control-lg form-control-solid"
                                      name={`${item.name}.maxLength`}
                                      onChange={(e) =>
                                        handleChanges({
                                          target: {
                                            name: e.target.name,
                                            value: isNaN(
                                              parseInt(e.target.value),
                                            )
                                              ? ''
                                              : parseInt(e.target.value),
                                          },
                                        })
                                      }
                                      type="number"
                                      value={item.validation['maxLength']}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Mandatory</Form.Label>
                                    <select
                                      className="form-control form-control-lg form-control-solid"
                                      name={`${item.name}.mandatory`}
                                      onChange={(e) => {
                                        const {name, value} = e.target;
                                        handleChanges({
                                          target: {
                                            name: name,
                                            value:
                                              value == 'true' ? true : false,
                                          },
                                        });
                                      }}
                                      value={item.validation['mandatory']}>
                                      <option value="">Select</option>
                                      <option value={true}>Yes</option>
                                      <option value={false}>No</option>
                                    </select>
                                  </Form.Group>
                                </Col>
                                <Col md>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Has Numeric</Form.Label>
                                    <div>
                                      <Switch
                                        name={`${item.name}.hasNumeric`}
                                        onChange={({target}) =>
                                          handleChanges({
                                            target: {
                                              name: `${item.name}.hasNumeric`,
                                              value: target.checked,
                                            },
                                          })
                                        }
                                        color="primary"
                                        inputProps={{
                                          'aria-label': 'primary checkbox',
                                        }}
                                        checked={
                                          uiConfig &&
                                          uiConfig?.userFields.filter(
                                            (itm) => itm.name == `${item.name}`,
                                          )[0].validation['hasNumeric']
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                                {uiConfig &&
                                  uiConfig?.userFields
                                    .filter(
                                      (itm) => itm.name == `${item.name}`,
                                    )[0]
                                    .validation.hasOwnProperty('isUnique') && (
                                    <Col md>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Is Unique</Form.Label>
                                        <div>
                                          <Switch
                                            name={`${item.name}.isUnique`}
                                            onChange={({target}) =>
                                              handleChanges({
                                                target: {
                                                  name: `${item.name}.isUnique`,
                                                  value: target.checked,
                                                },
                                              })
                                            }
                                            color="primary"
                                            inputProps={{
                                              'aria-label': 'primary checkbox',
                                            }}
                                            checked={
                                              uiConfig &&
                                              uiConfig?.userFields.filter(
                                                (itm) =>
                                                  itm.name == `${item.name}`,
                                              )[0].validation['isUnique']
                                            }
                                          />
                                        </div>
                                      </Form.Group>
                                    </Col>
                                  )}
                                <Col md>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Has Alpha</Form.Label>
                                    <div>
                                      <Switch
                                        name={`${item.name}.hasAlpha`}
                                        onChange={({target}) =>
                                          handleChanges({
                                            target: {
                                              name: target.name,
                                              value: target.checked,
                                            },
                                          })
                                        }
                                        color="primary"
                                        inputProps={{
                                          'aria-label': 'primary checkbox',
                                        }}
                                        checked={
                                          uiConfig &&
                                          uiConfig?.userFields.filter(
                                            (itm) => itm.name == `${item.name}`,
                                          )[0].validation['hasAlpha']
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>

                                <Col
                                  md
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <Button
                                    variant="danger"
                                    onClick={() => removeExtraField(item.name)}>
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </Form>
                <div className="card-header py-3 d-flex justify-content-end">
                  <div className="card-toolbar">
                    <button
                      type="button"
                      //  disabled={wizardStep <= 1 && 'disabled'}
                      className="btn btn-success me-2"
                      onClick={() => wizardPrevious()}>
                      MOVE PREVIOUS
                    </button>
                    <button
                      type="button"
                      // disabled={wizardStep >= 3 && 'disabled'}
                      className="btn btn-success me-2"
                      onClick={() => wizardNext()}>
                      MOVE NEXT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default React.memo(UiConfig);
