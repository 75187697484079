import React, {useEffect, useState, useCallback} from 'react';
import {Button, Form, Row, Col} from 'react-bootstrap';
import './styles.css';
import ServiceConfig from './ServiceConfige';
import {useSelector} from 'react-redux';
import clsx from 'clsx';
import UiConfig from './UiConfig';
import TemplateGenerate from './TemplateGenerate';
import {
  Switch,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {ExpandMore as ExpandMoreIcon} from '@mui/icons-material';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import {getData} from 'country-list';
import ct from 'countries-and-timezones';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import {
  addCompaign,
  getCampaignDetailByToken,
  updateCompaign,
  getCampaignTagsAction,
  getClientList,
} from '../../../app/modules/Auth/_redux/authCrud';
import IsLoadingHOC from '../../layout/components/HOC/IsLoadingHOC';
import ErrorBoundary from '../../_helpers/ErrorBoundary';
import CampaignSchema from './Campaign.Schema';
import DatePicker from 'react-datepicker';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function uniqByKeepLast(data, key) {
  return [...new Map(data.map((x) => [key(x), x])).values()];
}

const TenantManagement = ({
  addCompaign,
  getCampaignDetailByToken,
  updateCompaign,
  setLoading,
}) => {
  const history = useHistory();
  const isLoading = false;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');
  const [tenantConfig, setTenantConfig] = useState({});
  const [multi, setMulti] = useState(null);
  const [tokenData, setTokenData] = useState({});
  const [clientList, setClientList] = useState([]);
  // const [captcha, setCaptcha] = useState(false);

  let {id} = useParams();
  const [initialvalues, setInitialvalues] = useState({
    name: '',
    domain: '',
    selectCampaign: '',
    selectClient: '',
  });
  const handleChangeAccodion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const ValidateEmails = (mails) => {
    mails = Array.isArray(mails) ? mails : [mails];
    let invaildEmails = [];
    for (let i in mails) {
      if (
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          mails[i].trim(),
        )
      ) {
      } else {
        invaildEmails.push(mails[i].trim());
      }
    }
    return invaildEmails;
  };
  const {
    tenantConfig: configData,
    tenantConfigDefault,
    accessToken,
    campaign,
    customFields,
  } = useSelector((state) => state.auth);

  const [tagList, setTagList] = useState([]);

  const [wizardStep, setWizardStep] = useState(1);
  const timezones = ct.getAllTimezones();
  var timeZoneArray = Object.keys(timezones);
  useEffect(() => {
    (async function getCampaingDetails() {
      if (id) {
        //setIsLoading(true);
        setLoading(true);
        getCampaignDetailByToken()
          .then((res) => {
            // setIsLoading(false);
            setLoading(false);
            //console.log('response data', {...tenantConfigDefault, ...res});
            const countryData = [];
            if (Array.isArray(res?.countryCode)) {
              // console.log('fjjfds', res?.countryCode);
              res?.countryCode &&
                res.countryCode.length > 0 &&
                res.countryCode.forEach((item) => {
                  // console.log(item);
                  let label = getData().find((dddt) => dddt.code === item);
                  countryData.push({value: item, label: label?.name});
                });
              // console.log('country data', countryData);
              setMulti(countryData);
            } else {
              let label = getData().find(
                (item) => item.code === res?.countryCode,
              );
              setMulti([
                {
                  value: res?.countryCode,
                  label: label?.name,
                },
              ]);
            }

            //console.log('country data', configData, res);
            res.comingSoonDate = res.comingSoonDate
              ? new Date(res?.comingSoonDate).toLocaleString('en-US', {
                  timeZone: res?.timeZone || 'America/New_York',
                })
              : '';
            res.activationEnd = res.activationEnd
              ? new Date(res?.activationEnd).toLocaleString('en-US', {
                  timeZone: res?.timeZone || 'America/New_York',
                })
              : '';
            res.activationStart = res.activationStart
              ? new Date(res?.activationStart).toLocaleString('en-US', {
                  timeZone: res?.timeZone || 'America/New_York',
                })
              : '';
            res.completeEnd = res.completeEnd
              ? new Date(res?.completeEnd).toLocaleString('en-US', {
                  timeZone: res?.timeZone || 'America/New_York',
                })
              : '';
            res.purchaseEndDate = res.purchaseEndDate
              ? new Date(res?.purchaseEndDate).toLocaleString('en-US', {
                  timeZone: res?.timeZone || 'America/New_York',
                })
              : '';
            res.purchaseStartDate = res.purchaseStartDate
              ? new Date(res?.purchaseStartDate).toLocaleString('en-US', {
                  timeZone: res?.timeZone || 'America/New_York',
                })
              : '';
            res.shutdownDate = res.shutdownDate
              ? new Date(res?.shutdownDate).toLocaleString('en-US', {
                  timeZone: res?.timeZone || 'America/New_York',
                })
              : '';
            if (
              !res.enableSession &&
              res.sessionExpiryTime &&
              parseInt(res.sessionExpiryTime) > 0
            ) {
              res.enableSession = true;
            }
            let data = {
              ...configData,
              ...res,
              countryCode: countryData,
            };

            if (!data.bonusPointInfo) {
              data = {
                ...data,
                bonusPointInfo: {
                  onRegistration: 0,
                  onProfileComplete: {
                    enable: false,
                    validFrom: '',
                    validTo: '',
                    points: 0,
                    validateOnField: '',
                  },
                },
              };
            }
            if (!data.registrationPurchaseConfig) {
              data = {
                ...data,
                registrationPurchaseConfig: {
                  enable: false,
                  digitalPurchase: false,
                  discPurchase: false,
                  digitalConfig: {
                    moviesAnyWhere: {
                      token: '',
                    },
                  },
                },
              };
            }
            if (
              data?.receiptUploadViaEmail?.enable &&
              !data?.receiptUploadViaEmail?.channel
            ) {
              data['receiptUploadViaEmail']['channel'] = 'attachment';
            }
            setTenantConfig({
              ...data,
              neoCurrencyInsufficientAmountLimit:
                data?.neoCurrencyInsufficientAmountLimit || 0,
            });
          })
          .catch((err) => {
            //setIsLoading(false);
            setLoading(false);
            setTenantConfig(configData);
            console.log(err);
            err.forEach((item) => {
              toast.error(item.message);
            });
          });
      } else {
        setTenantConfig(tenantConfigDefault);
      }
      //const currentCampaign =  campaign.find(item => item.id === id);
      //console.log("currentCampaign", currentCampaign);
      // currentCampaign ? setTenantConfig(currentCampaign) :  setTenantConfig(configData);
    })();
  }, []);
  useEffect(() => {
    getClientList().then((res) => {
      if (res?.content?.length) {
        setClientList(res?.content || []);
      }
    });
  }, []);

  useEffect(() => {
    if (accessToken) {
      try {
        let jsData = JSON.parse(window.atob(accessToken.split('.')[1]));
        jsData.isSuperAdmin = jsData?.roles?.indexOf('ADMIN') >= 0 || false;
        if (!jsData.isSuperAdmin) {
          setInitialvalues({
            ...initialvalues,
            selectClient: jsData?.clId || '',
          });
        }
        setTokenData(jsData);
      } catch (e) {
        setTokenData({});
      }
    }
  }, [accessToken]);
  useEffect(() => {
    getCampaignTagsAction()
      .then((res) => {
        if (Array.isArray(res)) setTagList(res);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  const wizardPrevious = () => {
    if (wizardStep > 0) {
      setWizardStep(wizardStep - 1);
    }
  };

  const wizardNext = () => {
    if (wizardStep >= 5) {
      setExpanded('panel2');
    } else {
      setWizardStep(wizardStep + 1);
    }
  };
  const validateSchema = () => {
    CampaignSchema.validate(tenantConfig, {abortEarly: false})
      .then(() => {})
      .catch((err) => {
        err.inner.forEach((e) => {
          // console.log(e.message, e.path);
          toast.error(e.message);
        });
      });
  };
  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  const submitHandle = (param) => {
    validateSchema();
    // console.log(param)
    let tenantConfigNew = {
      ...tenantConfig,
      neoCurrencyInsufficientAmountLimit:
        tenantConfig?.neoCurrencyInsufficientAmountLimit || 0,
      shippingCharges: tenantConfig?.shippingCharges || 0,
      countryCode: multi?.map((item) => item.value),
      googleAnalyticsConfig:
        typeof tenantConfig?.googleAnalyticsConfig === 'object'
          ? tenantConfig?.googleAnalyticsConfig
          : isJsonString(tenantConfig?.googleAnalyticsConfig)
          ? JSON.parse(tenantConfig?.googleAnalyticsConfig)
          : tenantConfig?.googleAnalyticsConfig,
    };
    if (!tenantConfig?.captchaConfig?.enable) {
      tenantConfigNew = {
        ...tenantConfigNew,
        captchaConfig: {
          ...tenantConfigNew.captchaConfig,
          projectID: '',
          recaptchaSiteKey: '',
        },
      };
    }

    if (!tenantConfig?.receiptUploadViaEmail?.enable) {
      tenantConfigNew = {
        ...tenantConfigNew,
        receiptUploadViaEmail: {
          ...tenantConfigNew.receiptUploadViaEmail,
          emailUser: '',
          emailPassword: '',
          emailHost: '',
          emailPort: '',
          channel: '',
        },
      };
    } else if (
      tenantConfig?.receiptUploadViaEmail?.enable &&
      (tenantConfig?.receiptUploadViaEmail?.channel === '' ||
        !tenantConfig?.receiptUploadViaEmail?.channel)
    ) {
      toast.error(`Please select "Receipt Received Via (Channel)".`);
      return false;
    }

    if (tokenData?.isSuperAdmin && !tenantConfig?.clientId) {
      toast.error(`Please select client for new campaign`);
      return false;
    }

    if (!tenantConfig?.salesForce?.enable) {
      tenantConfigNew = {
        ...tenantConfigNew,
        salesForce: {
          ...tenantConfigNew.salesForce,
          accessTokenUrl: '',
          accountId: '',
          clientId: '',
          clientSecret: '',
          dataUrl: '',
          grantType: '',
        },
      };
    }
    if (!tenantConfig?.registrationPurchaseConfig?.enable) {
      tenantConfigNew = {
        ...tenantConfigNew,
        registrationPurchaseConfig: {
          ...tenantConfigNew.registrationPurchaseConfig,
          enable: false,
          digitalPurchase: false,
          discPurchase: false,
          digitalConfig: {
            moviesAnyWhere: {
              token: '',
            },
          },
        },
      };
    }

    if (!tenantConfig?.receiptOCRParse) {
      tenantConfigNew = {
        ...tenantConfigNew,
        ocrParsingModel: '',
        ocrParsingTool: '',
      };
    }
    if (!tenantConfig?.receiptUploadLimit?.enable) {
      tenantConfigNew = {
        ...tenantConfigNew,
        receiptUploadLimit: {
          enable: false,
          daily: 0,
          weekly: 0,
          monthly: 0,
          campaignDuration: 0,
        },
      };
    } else if (tenantConfig?.receiptUploadLimit?.enable) {
      tenantConfigNew = {
        ...tenantConfigNew,
        receiptUploadLimit: {
          enable: true,
          daily: parseInt(tenantConfig?.receiptUploadLimit?.daily || '0'),
          weekly: parseInt(tenantConfig?.receiptUploadLimit?.weekly || '0'),
          monthly: parseInt(tenantConfig?.receiptUploadLimit?.monthly || '0'),
          campaignDuration: parseInt(
            tenantConfig?.receiptUploadLimit?.campaignDuration || '0',
          ),
        },
      };
    }
    if (!tenantConfig?.redeemLimitApplicable?.limitApplicable) {
      tenantConfigNew = {
        ...tenantConfigNew,
        redeemLimitApplicable: {
          ...tenantConfig?.redeemLimitApplicable,
          limitField: '',
          calendar: '',
        },
      };
    }
    if (!tenantConfig?.salesValidation?.receipt) {
      tenantConfigNew = {
        ...tenantConfigNew,
        allowRcptBucket: false,
      };
    }

    if (!tenantConfig?.bonusPointInfo?.onRegistration) {
      tenantConfigNew = {
        ...tenantConfigNew,
        bonusPointInfo: {
          ...tenantConfigNew.bonusPointInfo,
          onRegistration: 0,
        },
      };
    }

    if (!tenantConfig?.bonusPointInfo?.onProfileComplete?.enable) {
      tenantConfigNew = {
        ...tenantConfigNew,
        bonusPointInfo: {
          ...tenantConfigNew.bonusPointInfo,
          onProfileComplete: {
            enable: false,
            validFrom: '',
            validTo: '',
            points: 0,
            validateOnField: '',
          },
        },
      };
    }
    const field =
      tenantConfig?.bonusPointInfo?.onProfileComplete?.validateOnField;
    const isSelectedField = tenantConfig.uiConfig.userFields.find(
      (it) => it.name === field,
    );
    if (field && !isSelectedField) {
      toast.error(
        `Use validate on field(${field}) as a registration field and make it mandatory.`,
      );
      return false;
    } else if (isSelectedField && !isSelectedField.validation.mandatory) {
      toast.error(
        `Use validate on field(${field}) as a registration field and make it mandatory.`,
      );
      return false;
    }
    if (tenantConfigNew?.rewardLimitSettings?.enabled) {
      tenantConfigNew.rewardLimitSettings.rewardLimit = isNaN(
        tenantConfigNew.rewardLimitSettings.rewardLimit,
      )
        ? 0
        : parseFloat(tenantConfigNew.rewardLimitSettings.rewardLimit);
      tenantConfigNew.rewardLimitSettings.limitPercentReached = isNaN(
        tenantConfigNew.rewardLimitSettings.limitPercentReached,
      )
        ? 0
        : parseFloat(tenantConfigNew.rewardLimitSettings.limitPercentReached);
      if (tenantConfigNew.rewardLimitSettings.rewardLimit < 1) {
        toast.error(
          `Please enter Rewards Limit more than 0 or Disable the Reward Limit Settings.`,
        );
        return false;
      }
      if (
        tenantConfigNew.rewardLimitSettings.limitPercentReached < 1 ||
        tenantConfigNew.rewardLimitSettings.limitPercentReached > 99
      ) {
        toast.error(
          `Please enter a valid "Reward Limit Percent Reached",beetween 1 to 99 or Disable the Reward Limit Settings.`,
        );
        return false;
      }
      let emailIds = tenantConfigNew.rewardLimitSettings.emailIds.split(',');
      let invaildEmailsList = ValidateEmails(emailIds);
      if (
        !tenantConfigNew.rewardLimitSettings.emailIds ||
        invaildEmailsList.length > 0
      ) {
        toast.error(
          `Please enter valid "Email for Send Alert(After limit reach of rewards) or Disable the Reward Limit Settings.`,
        );
        return false;
      }
    }
    if (tenantConfigNew?.registrationPurchaseConfig?.enable) {
      if (
        !tenantConfigNew?.registrationPurchaseConfig?.digitalPurchase &&
        !tenantConfigNew?.registrationPurchaseConfig?.discPurchase
      ) {
        toast.error(`Please enable at least one of Digital/Disc Purchase.`);
        return false;
      } else if (
        tenantConfigNew?.registrationPurchaseConfig?.digitalPurchase &&
        !tenantConfigNew?.registrationPurchaseConfig?.digitalConfig
          ?.moviesAnyWhere?.token
      ) {
        toast.error(`Please enter Movies AnyWhere Token.`);
        return false;
      }
      if (!tenantConfigNew?.registrationPurchaseConfig?.digitalPurchase) {
        tenantConfigNew.registrationPurchaseConfig.digitalConfig = {
          moviesAnyWhere: {token: ''},
        };
      }
    } else {
      tenantConfigNew.registrationPurchaseConfig = {
        enable: false,
        digitalPurchase: false,
        discPurchase: false,
        digitalConfig: {
          moviesAnyWhere: {
            token: '',
          },
        },
      };
    }
    if (tenantConfigNew?.gtmConfig?.enabled) {
      if (!tenantConfigNew?.gtmConfig?.gtmId) {
        toast.error(`Please enter valid GTM ID.`);
        return false;
      } else if (!tenantConfigNew?.gtmConfig?.gtmAnalyticsPropertyId) {
        toast.error(`Please enter valid GTM Analytics Property Id.`);
        return false;
      } else if (!tenantConfigNew?.gtmConfig?.gtmAnalyticsConfig) {
        toast.error(`Please enter valid GTM Analytics Config.`);
        return false;
      }
      tenantConfigNew.gtmConfig.gtmAnalyticsConfig =
        tenantConfigNew.gtmConfig.gtmAnalyticsConfig === 'object'
          ? tenantConfigNew.gtmConfig.gtmAnalyticsConfig
          : isJsonString(tenantConfigNew.gtmConfig.gtmAnalyticsConfig)
          ? JSON.parse(tenantConfigNew.gtmConfig.gtmAnalyticsConfig)
          : tenantConfigNew.gtmConfig.gtmAnalyticsConfig;
    } else {
      tenantConfigNew.gtmConfig = {enabled: false};
    }
    if (tenantConfigNew.enableSession) {
      if (
        !tenantConfigNew.sessionExpiryTime ||
        parseInt(tenantConfigNew.sessionExpiryTime) < 5
      ) {
        toast.error(
          `Please enter greater than 5 value in Session Expiry Time.`,
        );
        return false;
      }
    } else {
      tenantConfigNew.enableSession = false;
      tenantConfigNew.sessionExpiryTime = 0;
    }
    if (
      tenantConfig?.earningPointActivities?.refer &&
      !tenantConfig?.earningPointActivities?.referAdditionInfo?.pointsCreditOn
    ) {
      tenantConfig.earningPointActivities.referAdditionInfo = {
        pointsCreditOn: 'registration',
        noOfApprovedReceipt: 0,
      };
    } else if (!tenantConfig?.earningPointActivities?.refer) {
      tenantConfig.earningPointActivities.referAdditionInfo = {
        pointsCreditOn: '',
        noOfApprovedReceipt: 0,
      };
    } else if (
      (tenantConfig?.earningPointActivities?.referAdditionInfo
        ?.pointsCreditOn || '') !== 'upload'
    ) {
      tenantConfig.earningPointActivities.referAdditionInfo.noOfApprovedReceipt = 0;
    }

    if (
      (tenantConfig?.earningPointActivities?.referAdditionInfo
        ?.pointsCreditOn || '') === 'upload' &&
      parseInt(
        tenantConfig?.earningPointActivities?.referAdditionInfo
          ?.noOfApprovedReceipt || 0,
      ) <= 0
    ) {
      toast.error(
        `Please enter no of approved receipt value for referral points.`,
      );
      return false;
    }
    let fromEmailIds = tenantConfigNew.fromEmail.split('||');
    let invaildFromEmailsList = ValidateEmails(fromEmailIds);
    if (tenantConfigNew.fromEmail && invaildFromEmailsList.length > 0) {
      toast.error(
        `Please enter valid From Email, If multiple emails please use "||" seperator in beetween.`,
      );
      return false;
    }
    CampaignSchema.isValid(tenantConfigNew).then(function (valid) {
      if (valid) {
        tenantConfigNew.comingSoonDate = moment(
          tenantConfigNew.comingSoonDate,
        ).format('YYYY-MM-DD HH:mm:ss');
        tenantConfigNew.activationStart = moment(
          tenantConfigNew.activationStart,
        ).format('YYYY-MM-DD HH:mm:ss');
        tenantConfigNew.activationEnd = moment(
          tenantConfigNew.activationEnd,
        ).format('YYYY-MM-DD HH:mm:ss');
        tenantConfigNew.purchaseStartDate = moment(
          tenantConfigNew.purchaseStartDate,
        ).format('YYYY-MM-DD HH:mm:ss');
        tenantConfigNew.purchaseEndDate = moment(
          tenantConfigNew.purchaseEndDate,
        ).format('YYYY-MM-DD HH:mm:ss');
        tenantConfigNew.shutdownDate = moment(
          tenantConfigNew.shutdownDate,
        ).format('YYYY-MM-DD HH:mm:ss');
        tenantConfigNew.completeEnd = moment(
          tenantConfigNew.completeEnd,
        ).format('YYYY-MM-DD HH:mm:ss');
        setLoading(true);

        if (param === 'submit') {
          addCompaign(tenantConfigNew)
            .then((res) => {
              setLoading(false);
              toast.success(res);
              window.location.href = '/campaign';
            })
            .catch((err) => {
              setLoading(false);
              err.forEach((item) => {
                toast.error(item.message);
              });
            });
        }
        if (param === 'update') {
          updateCompaign(tenantConfigNew)
            .then((res) => {
              // console.log(res,tenantConfigNew)
              setLoading(false);
              toast.success(res);
              history.push('/campaign');
            })
            .catch((err) => {
              setLoading(false);
              err.forEach((item) => {
                toast.error(item.message);
              });
            });
        }
      }
    });
  };

  const handleChanges = (e) => {
    const {name, value} = e.target;
    const arrayValue = name.split('.');
    let configNewUpdated = {};
    if (arrayValue.length === 3) {
      configNewUpdated = {
        ...tenantConfig,
        [arrayValue[0]]: {
          ...tenantConfig[arrayValue[0]],
          [arrayValue[1]]: {
            ...tenantConfig[arrayValue[0]][arrayValue[1]],
            [arrayValue[2]]: value,
          },
        },
      };
    } else if (arrayValue.length === 4) {
      if (!tenantConfig[arrayValue[0]]) {
        tenantConfig[arrayValue[0]] = {};
      } else if (!tenantConfig[arrayValue[0]][arrayValue[1]]) {
        tenantConfig[arrayValue[0]][arrayValue[1]] = {};
      } else if (!tenantConfig[arrayValue[0]][arrayValue[1]][arrayValue[2]]) {
        tenantConfig[arrayValue[0]][arrayValue[1]][arrayValue[2]] = {};
      }
      configNewUpdated = {
        ...tenantConfig,
        [arrayValue[0]]: {
          ...tenantConfig[arrayValue[0]],
          [arrayValue[1]]: {
            ...tenantConfig[arrayValue[0]][arrayValue[1]],
            [arrayValue[2]]: {
              ...tenantConfig[arrayValue[0]][arrayValue[1]][arrayValue[2]],
              [arrayValue[3]]: value,
            },
          },
        },
      };
    } else if (arrayValue.length === 2) {
      if (name === 'gtmConfig.enabled' && value === false) {
        configNewUpdated = {
          ...tenantConfig,
          gtmConfig: {enabled: false},
        };
      } else {
        configNewUpdated = {
          ...tenantConfig,
          [arrayValue[0]]: {
            ...tenantConfig[arrayValue[0]],
            [arrayValue[1]]: value,
          },
        };
      }
    } else {
      if (name === 'rewardLimitSettings') {
        let rewardLimitSettings = {
          enabled: value,
          limitType: 'quantity',
          rewardLimit: 0,
          limitPercentReached: 0,
          emailIds: '',
        };
        configNewUpdated = {
          ...tenantConfig,
          rewardLimitSettings: rewardLimitSettings,
        };
      } else if (
        [
          'rewardLimitSettings_limitType',
          'rewardLimitSettings_rewardLimit',
          'rewardLimitSettings_limitPercentReached',
          'rewardLimitSettings_emailIds',
        ].indexOf(name) >= 0
      ) {
        let rewardKeyName = name.replace('rewardLimitSettings_', '');
        configNewUpdated = Object.assign({}, tenantConfig);
        configNewUpdated.rewardLimitSettings[rewardKeyName] = value;
      } else {
        configNewUpdated = {...tenantConfig, [name]: value};
        if (name === 'googleAnalyticsEnabled' && value === false) {
          configNewUpdated = {
            ...configNewUpdated,
            googleAnalyticsId: '',
            googleAnalyticsPropertyId: '',
            googleAnalyticsConfig: '',
          };
        }
        if (name === 'gtmConfig.enabled' && value === false) {
          configNewUpdated = {
            ...tenantConfig,
            gtmConfig: {enabled: false},
          };
        }
      }
    }
    setTenantConfig(configNewUpdated);
  };

  const handleChange = useCallback((newValue) => {
    setTenantConfig(newValue);
  }, []);

  const tabClasses = 'navi-link py-4';
  const tabActiveClasses = 'navi-link py-4 active';
  const stepHeading =
    wizardStep && wizardStep === 1
      ? 'Basic Information'
      : wizardStep === 2
      ? 'Identity Configuration'
      : wizardStep === 3
      ? 'Notification Options'
      : wizardStep === 4
      ? 'Earn Point Activities'
      : 'Value Added Services';

  //console.log('tanent config data', tenantConfig);

  const convertTime = (time) => {
    console.log('time', time);
    const arrayDate = time.slice(0, 10).split('-');
    // const newDateFormat = `${arrayDate[1]}-${arrayDate[2]}-${arrayDate[0]}`;
    //  console.log('time', newDateFormat);
    return arrayDate;
  };

  // const renderCountryList = () => {
  //   return getData().map((item) => {
  //     return (
  //       <option value={item.code} key={item.code}>
  //         {item.name}
  //       </option>
  //     );
  //   });
  // };
  //console.log('campaign teanct', tenantConfig);
  function handleChangeMulti(value) {
    //console.log('values', value);
    setMulti(value);
  }
  function validateDate(value) {
    let error;
    // console.log(value)
    // alert("clicked")
    if (!tenantConfig?.activationStart > tenantConfig?.comingSoonDate) {
      error = 'Program Start Date should be more than Coming Soon Date';
    } else if (!tenantConfig?.activationStart < tenantConfig?.activationEnd) {
      error = 'Program Start Date should be more than Program End Date';
    } else if (!tenantConfig?.activationStart < tenantConfig?.shutdownDate) {
      error = 'Program Start Date should be less than Program ShutDown Date';
    } else if (!tenantConfig?.activationStart < tenantConfig?.completeEnd) {
      error = 'Program Start Date should be less than Program Submission  Date';
    }
    return error;
  }
  return (
    <ErrorBoundary>
      <div className="d-flex justify-content-between mb-3">
        {id ? (
          <Button
            type="text"
            variant="success"
            onClick={() => submitHandle('update')}
            disabled={isLoading}>
            Update
            <span
              style={{left: '-40px'}}
              className={`ml-3 ${clsx({spinner: isLoading})}`}
            />
          </Button>
        ) : (
          <Button
            type="text"
            variant="success"
            onClick={() => submitHandle('submit')}
            disabled={isLoading}>
            Submit
            <span
              style={{left: '-40px'}}
              className={`ml-3 ${clsx({spinner: isLoading})}`}
            />
          </Button>
        )}
        <Button
          type="text"
          variant="primary"
          onClick={() => history.push('/campaign')}>
          back
        </Button>
      </div>

      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChangeAccodion('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header">
          <Typography className={classes.heading}>Definition</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="d-flex flex-column flex-root">
            <div className="container">
              <div className="d-flex flex-row">
                <div
                  className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
                  id="kt_profile_aside">
                  <div className="card card-custom card-stretch">
                    <div className="card-body pt-4">
                      <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                          <span
                            className={
                              wizardStep === 1 ? tabActiveClasses : tabClasses
                            }>
                            <span className="navi-icon mr-2">
                              <span className="svg-icon">1</span>
                            </span>
                            <span className="navi-text font-size-lg">
                              Basic Information
                            </span>
                          </span>
                        </div>
                        <div className="navi-item mb-2">
                          <span
                            className={
                              wizardStep === 2 ? tabActiveClasses : tabClasses
                            }>
                            <span className="navi-icon mr-2">
                              <span className="svg-icon">2</span>
                            </span>
                            <span className="navi-text font-size-lg">
                              Identity Configuration
                            </span>
                          </span>
                        </div>
                        <div className="navi-item mb-2">
                          <span
                            className={
                              wizardStep === 3 ? tabActiveClasses : tabClasses
                            }>
                            <span className="navi-icon mr-2">
                              <span className="svg-icon">3</span>
                            </span>
                            <span className="navi-text font-size-lg">
                              Notification Options
                            </span>
                          </span>
                        </div>
                        <div className="navi-item mb-2">
                          <span
                            className={
                              wizardStep === 4 ? tabActiveClasses : tabClasses
                            }>
                            <span className="navi-icon mr-2">
                              <span className="svg-icon">4</span>
                            </span>
                            <span className="navi-text font-size-lg">
                              Earn Point Activities
                            </span>
                          </span>
                        </div>
                        <div className="navi-item mb-2">
                          <span
                            className={
                              wizardStep === 5 ? tabActiveClasses : tabClasses
                            }>
                            <span className="navi-icon mr-2">
                              <span className="svg-icon">5</span>
                            </span>
                            <span className="navi-text font-size-lg">
                              Value Added Services
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-row-fluid ml-lg-8">
                  <div className="card card-custom">
                    <Form className="form">
                      <div
                        className="card-header py-3"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingTop: '20px ​!important',
                        }}>
                        <div className="card-title align-items-start flex-column">
                          <h3 className="card-label font-weight-bolder text-dark">
                            {stepHeading}
                          </h3>
                          <span className="text-muted font-weight-bold font-size-sm mt-1">
                            Change your account settings
                          </span>
                        </div>
                        <div className="card-toolbar">
                          <button
                            type="button"
                            disabled={wizardStep <= 1 && 'disabled'}
                            className="btn btn-success mr-2"
                            onClick={() => wizardPrevious()}>
                            MOVE PREVIOUS
                          </button>
                          <button
                            type="button"
                            // disabled={wizardStep >= 5 && 'disabled'}
                            className="btn btn-success mr-2"
                            onClick={() => wizardNext()}>
                            MOVE NEXT
                          </button>
                        </div>
                      </div>

                      <div className="card-body">
                        {wizardStep === 1 && (
                          <>
                            {tokenData?.isSuperAdmin && (
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Select client for new campaign
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <select
                                    className="form-control"
                                    name="clientId"
                                    value={tenantConfig?.clientId || ''}
                                    onChange={(val) =>
                                      handleChanges({
                                        target: {
                                          name: 'clientId',
                                          value: val?.target?.value || '',
                                        },
                                      })
                                    }>
                                    <option value="">Select</option>
                                    {clientList.map((item) => {
                                      return (
                                        <option value={item._id}>
                                          {`${item.firstName} ${item.lastName}`}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            )}
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Site Name
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  className="form-control form-control-lg form-control-solid"
                                  name="name"
                                  type="text"
                                  onChange={handleChanges}
                                  value={tenantConfig?.name || ''}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Status
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <select
                                  className="form-control form-control-lg form-control-solid"
                                  onChange={handleChanges}
                                  name="status"
                                  value={tenantConfig?.status}>
                                  <option value="active">Active</option>
                                  <option value="inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Tag
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <CreatableSelect
                                  key={''}
                                  className="react-select"
                                  // classes={classes2}
                                  // styles={selectStyles}
                                  TextFieldProps={{
                                    label: 'Tag',
                                    InputLabelProps: {
                                      htmlFor: 'react-select-multiple',
                                      shrink: true,
                                    },
                                    placeholder: 'Select ',
                                  }}
                                  options={tagList.map((item) => {
                                    return {value: item._id, label: item._id};
                                  })}
                                  // components={}
                                  value={
                                    tenantConfig.tag
                                      ? {
                                          value: tenantConfig.tag,
                                          label: tenantConfig.tag,
                                        }
                                      : null
                                  }
                                  onChange={(val) =>
                                    handleChanges({
                                      target: {
                                        name: 'tag',
                                        value: val?.value || null,
                                      },
                                    })
                                  }
                                />
                                {/* <input
                                  className="form-control form-control-lg form-control-solid"
                                  name="tag"
                                  type="text"
                                  value={tenantConfig?.tag}
                                  onChange={handleChanges}
                                /> */}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Shipping Charges
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  className="form-control form-control-lg form-control-solid"
                                  name="shippingCharges"
                                  type="number"
                                  min={0}
                                  onChange={(e) => {
                                    handleChanges({
                                      target: {
                                        name: e.target.name,
                                        value: parseFloat(e.target.value),
                                      },
                                    });
                                  }}
                                  value={tenantConfig?.shippingCharges || 0}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Currency
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <select
                                  className="form-control form-control-lg form-control-solid"
                                  onChange={handleChanges}
                                  name="currency"
                                  value={tenantConfig?.currency}>
                                  <option value="">Select Currency</option>
                                  <option value="USD">USD</option>
                                  <option value="CAD">CAD</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Hosting Region
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <select
                                  className="form-control form-control-lg form-control-solid"
                                  onChange={handleChanges}
                                  name="awsRegion"
                                  value={tenantConfig?.awsRegion}>
                                  <option value="us-west-1">US West 1</option>
                                  <option value="us-west-2">US West 2</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Country Code
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <Select
                                  key={''}
                                  className="react-select"
                                  // classes={classes2}
                                  // styles={selectStyles}
                                  inputId="react-select-multiple"
                                  TextFieldProps={{
                                    label: 'Countries',
                                    InputLabelProps: {
                                      htmlFor: 'react-select-multiple',
                                      shrink: true,
                                    },
                                    placeholder: 'Select multiple countries',
                                  }}
                                  options={getData().map((item) => {
                                    return {value: item.code, label: item.name};
                                  })}
                                  // components={}
                                  value={multi}
                                  onChange={handleChangeMulti}
                                  isMulti
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Long Distance Code
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  className="form-control form-control-lg form-control-solid"
                                  name="longDistanceCode"
                                  type="text"
                                  onChange={handleChanges}
                                  value={tenantConfig?.longDistanceCode || ''}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Time Zone
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <select
                                  className="form-control form-control-lg form-control-solid"
                                  onChange={handleChanges}
                                  name="timeZone"
                                  value={tenantConfig?.timeZone}>
                                  <option value="">Select Timezone</option>
                                  {timeZoneArray.map((item) => {
                                    return (
                                      <option key={item} value={item}>
                                        {item}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            {tenantConfig?.timeZone && (
                              <>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Coming Soon Date
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd HH:mm:ss"
                                      timeFormat="HH:mm"
                                      is24Hour
                                      showTimeSelect
                                      className={`form-control form-control-lg form-control-solid ${
                                        id && 'bg-secondary'
                                      }`}
                                      autocomplete="off"
                                      selected={
                                        tenantConfig.comingSoonDate
                                          ? new Date(
                                              tenantConfig.comingSoonDate,
                                            )
                                          : ''
                                      }
                                      name="comingSoonDate"
                                      onChange={(date) => {
                                        handleChanges({
                                          target: {
                                            name: 'comingSoonDate',
                                            value: date
                                              ? moment(date).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                                )
                                              : date,
                                          },
                                        });
                                      }} //only when value has changed
                                      showYearDropdown
                                      dropdownMode="select"
                                      enableTabLoop
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Program Start Date
                                  </label>
                                  {/* {console.log(
                                    ' tenantConfig?.activationStart',
                                    tenantConfig?.activationStart,
                                    convertTime(tenantConfig?.activationStart),
                                  )} */}
                                  <div className="col-lg-9 col-xl-6">
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd HH:mm:ss"
                                      timeFormat="HH:mm"
                                      is24Hour
                                      showTimeSelect
                                      className={`form-control form-control-lg form-control-solid ${
                                        id && 'bg-secondary'
                                      }`}
                                      //  minDate={moment().toDate()}
                                      autocomplete="off"
                                      selected={
                                        tenantConfig?.activationStart
                                          ? new Date(
                                              tenantConfig?.activationStart,
                                            )
                                          : ''
                                      }
                                      name="activationStart"
                                      onChange={(date) => {
                                        handleChanges({
                                          target: {
                                            name: 'activationStart',
                                            value: date
                                              ? moment(date).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                                )
                                              : date,
                                          },
                                        });
                                      }} //only when value has changed
                                      showYearDropdown
                                      dropdownMode="select"
                                      enableTabLoop
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Purchase Start Date
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd HH:mm:ss"
                                      timeFormat="HH:mm"
                                      is24Hour
                                      showTimeSelect
                                      className={`form-control form-control-lg form-control-solid ${
                                        id && 'bg-secondary'
                                      }`}
                                      autocomplete="off"
                                      selected={
                                        tenantConfig?.purchaseStartDate
                                          ? new Date(
                                              tenantConfig?.purchaseStartDate,
                                            )
                                          : ''
                                      }
                                      name="purchaseStartDate"
                                      onChange={(date) => {
                                        handleChanges({
                                          target: {
                                            name: 'purchaseStartDate',
                                            value: date
                                              ? moment(date).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                                )
                                              : date,
                                          },
                                        });
                                      }} //only when value has changed
                                      showYearDropdown
                                      dropdownMode="select"
                                      enableTabLoop
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Purchase End Date
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd HH:mm:ss"
                                      timeFormat="HH:mm"
                                      is24Hour
                                      showTimeSelect
                                      className={`form-control form-control-lg form-control-solid ${
                                        id && 'bg-secondary'
                                      }`}
                                      autocomplete="off"
                                      selected={
                                        tenantConfig?.purchaseEndDate
                                          ? new Date(
                                              tenantConfig?.purchaseEndDate,
                                            )
                                          : ''
                                      }
                                      name="purchaseEndDate"
                                      onChange={(date) => {
                                        handleChanges({
                                          target: {
                                            name: 'purchaseEndDate',
                                            value: date
                                              ? moment(date).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                                )
                                              : date,
                                          },
                                        });
                                      }} //only when value has changed
                                      showYearDropdown
                                      dropdownMode="select"
                                      enableTabLoop
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Submission Dead Line
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd HH:mm:ss"
                                      timeFormat="HH:mm"
                                      is24Hour
                                      showTimeSelect
                                      className="form-control form-control-lg form-control-solid"
                                      selected={
                                        tenantConfig?.completeEnd
                                          ? new Date(tenantConfig?.completeEnd)
                                          : ''
                                      }
                                      name="completeEnd"
                                      // minDate={moment().toDate()}
                                      onChange={(date) =>
                                        handleChanges({
                                          target: {
                                            name: 'completeEnd',
                                            value: date
                                              ? moment(date).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                                )
                                              : '',
                                          },
                                        })
                                      } //only when value has changed
                                      showYearDropdown
                                      dropdownMode="select"
                                      enableTabLoop
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Program End Date
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd HH:mm:ss"
                                      timeFormat="HH:mm"
                                      is24Hour
                                      showTimeSelect
                                      className="form-control form-control-lg form-control-solid"
                                      selected={
                                        tenantConfig?.activationEnd
                                          ? new Date(
                                              tenantConfig?.activationEnd,
                                            )
                                          : ''
                                      }
                                      name="activationEnd"
                                      // minDate={moment().toDate()}
                                      onChange={(date) =>
                                        handleChanges({
                                          target: {
                                            name: 'activationEnd',
                                            value: date
                                              ? moment(date).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                                )
                                              : '',
                                            // .toISOString()
                                            // .split('T')[0],
                                          },
                                        })
                                      } //only when value has changed
                                      showYearDropdown
                                      dropdownMode="select"
                                      enableTabLoop
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Shutdown Date
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd HH:mm:ss"
                                      timeFormat="HH:mm"
                                      is24Hour
                                      showTimeSelect
                                      className="form-control form-control-lg form-control-solid"
                                      selected={
                                        tenantConfig?.shutdownDate
                                          ? new Date(tenantConfig?.shutdownDate)
                                          : ''
                                      }
                                      //  minDate={moment().toDate()}
                                      name="shutdownDate"
                                      onChange={(date) => {
                                        handleChanges({
                                          target: {
                                            name: 'shutdownDate',
                                            value: date
                                              ? moment(date).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                                )
                                              : '',
                                          },
                                        });
                                      }} //only when value has changed
                                      showYearDropdown
                                      dropdownMode="select"
                                      enableTabLoop
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}

                        {wizardStep === 2 && (
                          <>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                User Login Enable
                              </Form.Label>
                              <Switch
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="userLoginEnabled"
                                checked={
                                  tenantConfig?.userLoginEnabled || false
                                }
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Redeem Limit Applicable
                              </Form.Label>
                              <Switch
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="redeemLimitApplicable.limitApplicable"
                                checked={
                                  tenantConfig?.redeemLimitApplicable
                                    ?.limitApplicable || false
                                }
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            {tenantConfig?.redeemLimitApplicable
                              ?.limitApplicable && (
                              <>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Limit Field
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      className="form-control form-control-lg form-control-solid"
                                      name="redeemLimitApplicable.limitField"
                                      type="number"
                                      onChange={(e) =>
                                        handleChanges({
                                          target: {
                                            name: e.target.name,
                                            value: isNaN(
                                              parseInt(e.target.value),
                                            )
                                              ? ''
                                              : parseInt(e.target.value),
                                          },
                                        })
                                      }
                                      value={
                                        tenantConfig?.redeemLimitApplicable
                                          ?.limitField || ''
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Year
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    {/* <DatePicker
                                      dateFormat="yyyy-MM-dd"
                                      className={`form-control form-control-lg form-control-solid ${
                                        id && 'bg-secondary'
                                      }`}
                                      autocomplete="off"
                                      selected={
                                        tenantConfig?.redeemLimitApplicable
                                          ?.calendar
                                          ? new Date(
                                              convertTime(
                                                tenantConfig
                                                  ?.redeemLimitApplicable
                                                  ?.calendar,
                                              ),
                                            )
                                          : ''
                                      }
                                      name="redeemLimitApplicable.calendar"
                                      onChange={(date) => {
                                        handleChanges({
                                          target: {
                                            name: 'redeemLimitApplicable.calendar',
                                            value: date
                                              ? moment(date).format(
                                                  'YYYY-MM-DD',
                                                )
                                              : date,
                                          },
                                        });
                                      }} //only when value has changed
                                    /> */}

                                    <select
                                      className="form-control form-control-lg form-control-solid"
                                      onChange={handleChanges}
                                      name="redeemLimitApplicable.calendar"
                                      value={
                                        tenantConfig?.redeemLimitApplicable
                                          ?.calendar || ''
                                      }>
                                      <option value="">Select Year</option>
                                      <option value="Financial">
                                        Financial
                                      </option>
                                      <option value="calendar">Calendar</option>
                                    </select>
                                  </div>
                                </div>
                              </>
                            )}

                            {tenantConfig?.userLoginEnabled && (
                              <>
                                <Form.Group className="row align-items-center">
                                  <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    {' '}
                                    In-Application User Authentication
                                  </Form.Label>
                                  <Switch
                                    checked={
                                      tenantConfig?.userAuth?.InApp || false
                                    }
                                    onChange={({target}) =>
                                      handleChanges({
                                        target: {
                                          name: target.name,
                                          value: target.checked,
                                        },
                                      })
                                    }
                                    color="primary"
                                    name="userAuth.InApp"
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                </Form.Group>
                                {tenantConfig?.userAuth?.InApp && (
                                  <>
                                    <Form.Group className="row align-items-center">
                                      <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                        Google
                                      </Form.Label>
                                      <Switch
                                        checked={
                                          tenantConfig?.userAuth?.oauthGoogle ||
                                          false
                                        }
                                        onChange={({target}) =>
                                          handleChanges({
                                            target: {
                                              name: target.name,
                                              value: target.checked,
                                            },
                                          })
                                        }
                                        color="primary"
                                        name="userAuth.oauthGoogle"
                                        inputProps={{
                                          'aria-label': 'primary checkbox',
                                        }}
                                      />
                                    </Form.Group>
                                    <Form.Group className="row align-items-center">
                                      <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                        Facebook
                                      </Form.Label>
                                      <Switch
                                        checked={
                                          tenantConfig?.userAuth
                                            ?.oauthFacebook || false
                                        }
                                        onChange={({target}) =>
                                          handleChanges({
                                            target: {
                                              name: target.name,
                                              value: target.checked,
                                            },
                                          })
                                        }
                                        color="primary"
                                        name="userAuth.oauthFacebook"
                                        inputProps={{
                                          'aria-label': 'primary checkbox',
                                        }}
                                      />
                                    </Form.Group>
                                    <Form.Group className="row align-items-center">
                                      <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                        Twitter
                                      </Form.Label>
                                      <Switch
                                        checked={
                                          tenantConfig?.userAuth
                                            ?.oauthTwitter || false
                                        }
                                        onChange={({target}) =>
                                          handleChanges({
                                            target: {
                                              name: target.name,
                                              value: target.checked,
                                            },
                                          })
                                        }
                                        color="primary"
                                        name="userAuth.oauthTwitter"
                                        inputProps={{
                                          'aria-label': 'primary checkbox',
                                        }}
                                      />
                                    </Form.Group>
                                  </>
                                )}
                              </>
                            )}

                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Email Login Only
                              </Form.Label>
                              <Switch
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="emailLoginOnly"
                                checked={tenantConfig?.emailLoginOnly || false}
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Validate Email
                              </Form.Label>
                              <Switch
                                checked={tenantConfig?.validateEmail || false}
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="validateEmail"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Send welcome Email
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.sendWelcomeEmail || false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="sendWelcomeEmail"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Allow Email Change
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.allowEmailChange || false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="allowEmailChange"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>

                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Second Factor EMAIL Authentication
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <Switch
                                  onChange={({target}) => {
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    });
                                    // if (!target.checked) {
                                    //   handleChanges({
                                    //     target: {
                                    //       name: 'auth2ndFactorSMS',
                                    //       value: false,
                                    //     },
                                    //   });
                                    // }
                                  }}
                                  color="primary"
                                  name="auth2ndFactorEMAIL"
                                  checked={
                                    tenantConfig?.auth2ndFactorEMAIL || false
                                  }
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              </div>
                            </div>
                            {tenantConfig?.auth2ndFactorEMAIL && (
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Second Factor SMS Authentication
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <Switch
                                    onChange={({target}) =>
                                      handleChanges({
                                        target: {
                                          name: target.name,
                                          value: target.checked,
                                        },
                                      })
                                    }
                                    color="primary"
                                    name="auth2ndFactorSMS"
                                    checked={
                                      tenantConfig?.auth2ndFactorSMS || false
                                    }
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Enable Captcha
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <Switch
                                  onChange={({target}) => {
                                    // setCaptcha(target.checked);
                                    handleChanges({
                                      target: {
                                        name: 'captchaConfig.enable',
                                        value: target.checked,
                                      },
                                    });
                                  }}
                                  color="primary"
                                  name="captchaConfig.enable"
                                  checked={
                                    tenantConfig?.captchaConfig?.enable || false
                                  }
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              </div>
                            </div>
                            {tenantConfig?.captchaConfig?.enable && (
                              <>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Recaptcha Version
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <select
                                      className="form-control form-control-lg form-control-solid"
                                      onChange={handleChanges}
                                      name="captchaConfig.recaptchaVersion"
                                      value={
                                        tenantConfig?.captchaConfig
                                          ?.recaptchaVersion || ''
                                      }>
                                      <option value="">
                                        Select Captcha Version
                                      </option>
                                      <option value="V2">V2</option>
                                      <option value="V3">V3</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Project Id
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      className="form-control form-control-lg form-control-solid"
                                      name="captchaConfig.projectID"
                                      type="text"
                                      onChange={handleChanges}
                                      value={
                                        tenantConfig?.captchaConfig
                                          ?.projectID || ''
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Recaptcha Site Key
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      className="form-control form-control-lg form-control-solid"
                                      name="captchaConfig.recaptchaSiteKey"
                                      type="text"
                                      onChange={handleChanges}
                                      value={
                                        tenantConfig?.captchaConfig
                                          ?.recaptchaSiteKey || ''
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Recaptcha Secret Key
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      className="form-control form-control-lg form-control-solid"
                                      name="captchaConfig.recaptchaSecretKey"
                                      type="text"
                                      onChange={handleChanges}
                                      value={
                                        tenantConfig?.captchaConfig
                                          ?.recaptchaSecretKey || ''
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Registration Captcha
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <Switch
                                      onChange={({target}) => {
                                        // setCaptcha(target.checked);
                                        handleChanges({
                                          target: {
                                            name: 'captchaConfig.registrationCaptcha',
                                            value: target.checked,
                                          },
                                        });
                                      }}
                                      color="primary"
                                      name="captchaConfig.registrationCaptcha"
                                      checked={
                                        tenantConfig?.captchaConfig
                                          ?.registrationCaptcha || false
                                      }
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Login Captcha
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <Switch
                                      onChange={({target}) => {
                                        // setCaptcha(target.checked);
                                        handleChanges({
                                          target: {
                                            name: 'captchaConfig.loginCaptcha',
                                            value: target.checked,
                                          },
                                        });
                                      }}
                                      color="primary"
                                      name="captchaConfig.loginCaptcha"
                                      checked={
                                        tenantConfig?.captchaConfig
                                          ?.loginCaptcha || false
                                      }
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Upload Receipt Captcha
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <Switch
                                      onChange={({target}) => {
                                        // setCaptcha(target.checked);
                                        handleChanges({
                                          target: {
                                            name: 'captchaConfig.uploadReceiptCaptcha',
                                            value: target.checked,
                                          },
                                        });
                                      }}
                                      color="primary"
                                      name="captchaConfig.uploadReceiptCaptcha"
                                      checked={
                                        tenantConfig?.captchaConfig
                                          ?.uploadReceiptCaptcha || false
                                      }
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Support Captcha
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <Switch
                                      onChange={({target}) => {
                                        // setCaptcha(target.checked);
                                        handleChanges({
                                          target: {
                                            name: 'captchaConfig.supportCaptcha',
                                            value: target.checked,
                                          },
                                        });
                                      }}
                                      color="primary"
                                      name="captchaConfig.supportCaptcha"
                                      checked={
                                        tenantConfig?.captchaConfig
                                          ?.supportCaptcha || false
                                      }
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Package Code Captcha
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <Switch
                                      onChange={({target}) => {
                                        // setCaptcha(target.checked);
                                        handleChanges({
                                          target: {
                                            name: 'captchaConfig.packageCodeCaptcha',
                                            value: target.checked,
                                          },
                                        });
                                      }}
                                      color="primary"
                                      name="captchaConfig.packageCodeCaptcha"
                                      checked={
                                        tenantConfig?.captchaConfig
                                          ?.packageCodeCaptcha || false
                                      }
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Receipt Upload Limit
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.receiptUploadLimit?.enable ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: 'receiptUploadLimit.enable',
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="receiptUploadLimit.enable"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>

                            {tenantConfig?.receiptUploadLimit?.enable && (
                              <>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Daily
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      className="form-control form-control-lg form-control-solid"
                                      name="receiptUploadLimit.daily"
                                      type="number"
                                      onChange={handleChanges}
                                      value={
                                        tenantConfig?.receiptUploadLimit
                                          ?.daily || 0
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Weekly
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      className="form-control form-control-lg form-control-solid"
                                      name="receiptUploadLimit.weekly"
                                      type="number"
                                      onChange={handleChanges}
                                      value={
                                        tenantConfig?.receiptUploadLimit
                                          ?.weekly || 0
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Monthly
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      className="form-control form-control-lg form-control-solid"
                                      name="receiptUploadLimit.monthly"
                                      type="number"
                                      onChange={handleChanges}
                                      value={
                                        tenantConfig?.receiptUploadLimit
                                          ?.monthly || 0
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Campaign Duration
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      className="form-control form-control-lg form-control-solid"
                                      name="receiptUploadLimit.campaignDuration"
                                      type="number"
                                      onChange={handleChanges}
                                      value={
                                        tenantConfig?.receiptUploadLimit
                                          ?.campaignDuration || 0
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Receipt OCR Parse
                              </Form.Label>
                              <Switch
                                checked={tenantConfig?.receiptOCRParse || false}
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: 'receiptOCRParse',
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="receiptOCRParse"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            {tenantConfig && tenantConfig.receiptOCRParse && (
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  OCR Parsing Tool
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <select
                                    className="form-control form-control-lg form-control-solid"
                                    onChange={handleChanges}
                                    name="ocrParsingTool"
                                    value={tenantConfig?.ocrParsingTool || ''}>
                                    <option value="">
                                      Select Parsing Model
                                    </option>
                                    <option value="MICROSOFT_AZURE">
                                      Microsoft Azure
                                    </option>
                                    <option value="AMAZON_TEXTRACT">
                                      Amazon Textract
                                    </option>
                                  </select>
                                </div>
                              </div>
                            )}
                            {tenantConfig &&
                              tenantConfig.receiptOCRParse &&
                              (tenantConfig?.ocrParsingTool || '') !=
                                'AMAZON_TEXTRACT' && (
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    OCR Parsing Model
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <select
                                      className="form-control form-control-lg form-control-solid"
                                      onChange={handleChanges}
                                      name="ocrParsingModel"
                                      value={
                                        tenantConfig?.ocrParsingModel || ''
                                      }>
                                      <option value="">
                                        Select Parsing Model
                                      </option>
                                      <option value="prebuilt-invoice">
                                        Invoice Model
                                      </option>
                                      <option value="prebuilt-receipt">
                                        Receipt Model
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              )}
                          </>
                        )}

                        {wizardStep === 3 && (
                          <>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Email
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.notificationOptions?.email ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="notificationOptions.email"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                SMS
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.notificationOptions?.sms ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="notificationOptions.sms"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Facebook
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.notificationOptions?.facebook ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="notificationOptions.facebook"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Twitter
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.notificationOptions?.twitter ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="notificationOptions.twitter"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Instagram
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.notificationOptions
                                    ?.instagram || false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="notificationOptions.instagram"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Apple Client Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.appleAuthentication
                                        ?.clientId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.appleAuthentication
                                        ?.clientId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="appleAuthentication.clientId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Apple Redirect URI
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.appleAuthentication
                                        ?.redirectURI
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.appleAuthentication
                                        ?.redirectURI
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="appleAuthentication.redirectURI"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Apple App Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.appleAuthentication?.appId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.appleAuthentication?.appId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="appleAuthentication.appId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Apple Key Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.appleAuthentication?.keyId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.appleAuthentication?.keyId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="appleAuthentication.keyId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Apple Private Key
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.appleAuthentication
                                        ?.privateKey
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.appleAuthentication
                                        ?.privateKey
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="appleAuthentication.privateKey"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Apple Resource Server
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.appleAuthentication
                                        ?.resourceServer
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.appleAuthentication
                                        ?.resourceServer
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="appleAuthentication.resourceServer"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Microsoft Client Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.microsoftAuthentication
                                        ?.clientId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.microsoftAuthentication
                                        ?.clientId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="microsoftAuthentication.clientId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Microsoft Redirect URI
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.microsoftAuthentication
                                        ?.redirectURI
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.microsoftAuthentication
                                        ?.redirectURI
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="microsoftAuthentication.redirectURI"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Microsoft App Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.microsoftAuthentication
                                        ?.appId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.microsoftAuthentication
                                        ?.appId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="microsoftAuthentication.appId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Microsoft Key Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.microsoftAuthentication
                                        ?.keyId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.microsoftAuthentication
                                        ?.keyId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="microsoftAuthentication.keyId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Microsoft Private Key
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.microsoftAuthentication
                                        ?.privateKey
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.microsoftAuthentication
                                        ?.privateKey
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="microsoftAuthentication.privateKey"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Microsoft Resource Server
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.microsoftAuthentication
                                        ?.resourceServer
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.microsoftAuthentication
                                        ?.resourceServer
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="microsoftAuthentication.resourceServer"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Google Client Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.googleAuthentication
                                        ?.clientId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.googleAuthentication
                                        ?.clientId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="googleAuthentication.clientId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Google Redirect URI
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.googleAuthentication
                                        ?.redirectURI
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.googleAuthentication
                                        ?.redirectURI
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="googleAuthentication.redirectURI"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Google App Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.googleAuthentication?.appId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.googleAuthentication?.appId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="googleAuthentication.appId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Google Key Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.googleAuthentication?.keyId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.googleAuthentication?.keyId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="googleAuthentication.keyId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Google Private Key
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.googleAuthentication
                                        ?.privateKey
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.googleAuthentication
                                        ?.privateKey
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="googleAuthentication.privateKey"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Google Resource Server
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.googleAuthentication
                                        ?.resourceServer
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.googleAuthentication
                                        ?.resourceServer
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="googleAuthentication.resourceServer"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Facebook Client Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.facebookAuthentication
                                        ?.clientId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.facebookAuthentication
                                        ?.clientId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="facebookAuthentication.clientId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Facebook Redirect URI
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.facebookAuthentication
                                        ?.redirectURI
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.facebookAuthentication
                                        ?.redirectURI
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="facebookAuthentication.redirectURI"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Facebook App Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.facebookAuthentication
                                        ?.appId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.facebookAuthentication
                                        ?.appId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="facebookAuthentication.appId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Facebook Key Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.facebookAuthentication
                                        ?.keyId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.facebookAuthentication
                                        ?.keyId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="facebookAuthentication.keyId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Facebook Private Key
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.facebookAuthentication
                                        ?.privateKey
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.facebookAuthentication
                                        ?.privateKey
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="facebookAuthentication.privateKey"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Facebook Resource Server
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.facebookAuthentication
                                        ?.resourceServer
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.facebookAuthentication
                                        ?.resourceServer
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="facebookAuthentication.resourceServer"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Amazon Client Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.amazonAuthentication
                                        ?.clientId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.amazonAuthentication
                                        ?.clientId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="amazonAuthentication.clientId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Amazon Redirect URI
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.amazonAuthentication
                                        ?.redirectURI
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.amazonAuthentication
                                        ?.redirectURI
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="amazonAuthentication.redirectURI"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Amazon App Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.amazonAuthentication?.appId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.amazonAuthentication?.appId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="amazonAuthentication.appId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Amazon Key Id
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.amazonAuthentication?.keyId
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.amazonAuthentication?.keyId
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="amazonAuthentication.keyId"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Amazon Private Key
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.amazonAuthentication
                                        ?.privateKey
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.amazonAuthentication
                                        ?.privateKey
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="amazonAuthentication.privateKey"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Amazon Resource Server
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    value={
                                      tenantConfig &&
                                      tenantConfig?.amazonAuthentication
                                        ?.resourceServer
                                    }
                                    className={`form-control form-control-lg form-control-solid ${
                                      tenantConfig?.amazonAuthentication
                                        ?.resourceServer
                                        ? 'bg-secondary'
                                        : ''
                                    }`}
                                    name="amazonAuthentication.resourceServer"
                                    type="text"
                                    onChange={handleChanges}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {wizardStep === 4 && (
                          <>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Watch and Earn
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.earningPointActivities?.watch ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="earningPointActivities.watch"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Read and Earn
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.earningPointActivities?.read ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="earningPointActivities.read"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Answer and Earn
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.earningPointActivities
                                    ?.answer || false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="earningPointActivities.answer"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>

                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Share and Earn
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.earningPointActivities?.share ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="earningPointActivities.share"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>

                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Refer and Earn
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.earningPointActivities?.refer ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="earningPointActivities.refer"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            {tenantConfig?.earningPointActivities?.refer && (
                              <Form.Group className="row align-items-center">
                                <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Refer Points Credit on
                                </Form.Label>
                                <div className="col-lg-9 col-xl-6">
                                  <select
                                    className="form-control form-control-lg"
                                    onChange={handleChanges}
                                    name="earningPointActivities.referAdditionInfo.pointsCreditOn"
                                    value={
                                      tenantConfig?.earningPointActivities
                                        ?.referAdditionInfo?.pointsCreditOn
                                    }>
                                    <option value="registration">
                                      On Registration
                                    </option>
                                    <option value="upload">
                                      On Upload receipt
                                    </option>
                                  </select>
                                </div>
                              </Form.Group>
                            )}
                            {(tenantConfig?.earningPointActivities
                              ?.referAdditionInfo?.pointsCreditOn || '') ===
                              'upload' && (
                              <Form.Group className="row align-items-center">
                                <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  No of Approved Receipt
                                </Form.Label>
                                <div className="col-lg-9 col-xl-6">
                                  <input
                                    className="form-control"
                                    name="earningPointActivities.referAdditionInfo.noOfApprovedReceipt"
                                    onChange={(e) =>
                                      handleChanges({
                                        target: {
                                          name: e.target.name,
                                          value: +e.target.value,
                                        },
                                      })
                                    }
                                    type="number"
                                    value={
                                      tenantConfig?.earningPointActivities
                                        ?.referAdditionInfo?.noOfApprovedReceipt
                                    }
                                  />
                                </div>
                              </Form.Group>
                            )}
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Click and Earn
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.earningPointActivities?.click ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="earningPointActivities.click"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>

                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Login and Earn
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.earningPointActivities?.login ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="earningPointActivities.login"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Additional Bonus Points(Activity)
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.earningPointActivities?.bonus ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="earningPointActivities.bonus"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Play Games
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.earningPointActivities?.games ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="earningPointActivities.games"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <div className="col-form-label text-left">
                              <h4>Bonus Point Info</h4>
                            </div>
                            <Row
                              className="g-2"
                              style={{border: '1px solid #ccc'}}>
                              <Col md={12}>
                                <Form.Group className="mb-1">
                                  <Form.Label>On Registration</Form.Label>
                                  <input
                                    className="form-control"
                                    name="bonusPointInfo.onRegistration"
                                    onChange={(e) =>
                                      handleChanges({
                                        target: {
                                          name: e.target.name,
                                          value: +e.target.value,
                                        },
                                      })
                                    }
                                    type="number"
                                    value={
                                      tenantConfig?.bonusPointInfo
                                        ?.onRegistration
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={12}>
                                <Form.Group className="mb-1">
                                  <Form.Label>Profile Complete</Form.Label>
                                  <Switch
                                    checked={
                                      tenantConfig?.bonusPointInfo
                                        ?.onProfileComplete?.enable || false
                                    }
                                    onChange={({target}) =>
                                      handleChanges({
                                        target: {
                                          name: target.name,
                                          value: target.checked,
                                        },
                                      })
                                    }
                                    color="primary"
                                    name="bonusPointInfo.onProfileComplete.enable"
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              {tenantConfig?.bonusPointInfo?.onProfileComplete
                                ?.enable && (
                                <>
                                  <Col md={3}>
                                    <Form.Group>
                                      <Form.Label>Valid From</Form.Label>
                                      <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        className={`form-control`}
                                        autocomplete="off"
                                        selected={
                                          tenantConfig?.bonusPointInfo
                                            ?.onProfileComplete?.validFrom
                                            ? new Date(
                                                convertTime(
                                                  tenantConfig?.bonusPointInfo
                                                    ?.onProfileComplete
                                                    ?.validFrom,
                                                ),
                                              )
                                            : ''
                                        }
                                        name="bonusPointInfo.onProfileComplete.validFrom"
                                        onChange={(date) => {
                                          handleChanges({
                                            target: {
                                              name: 'bonusPointInfo.onProfileComplete.validFrom',
                                              value: date
                                                ? moment(date).format(
                                                    'YYYY-MM-DD',
                                                  )
                                                : date,
                                            },
                                          });
                                        }} //only when value has changed
                                        showYearDropdown
                                        dropdownMode="select"
                                        enableTabLoop
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group>
                                      <Form.Label>Valid To</Form.Label>
                                      <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        className={`form-control`}
                                        autocomplete="off"
                                        selected={
                                          tenantConfig?.bonusPointInfo
                                            ?.onProfileComplete?.validTo
                                            ? new Date(
                                                convertTime(
                                                  tenantConfig?.bonusPointInfo
                                                    ?.onProfileComplete
                                                    ?.validTo,
                                                ),
                                              )
                                            : ''
                                        }
                                        name="bonusPointInfo.onProfileComplete.validTo"
                                        onChange={(date) => {
                                          handleChanges({
                                            target: {
                                              name: 'bonusPointInfo.onProfileComplete.validTo',
                                              value: date
                                                ? moment(date).format(
                                                    'YYYY-MM-DD',
                                                  )
                                                : date,
                                            },
                                          });
                                        }} //only when value has changed
                                        showYearDropdown
                                        dropdownMode="select"
                                        enableTabLoop
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group>
                                      <Form.Label>Points</Form.Label>
                                      <input
                                        className="form-control"
                                        name="bonusPointInfo.onProfileComplete.points"
                                        onChange={(e) =>
                                          handleChanges({
                                            target: {
                                              name: e.target.name,
                                              value: +e.target.value,
                                            },
                                          })
                                        }
                                        type="number"
                                        value={
                                          tenantConfig?.bonusPointInfo
                                            ?.onProfileComplete?.points || 0
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group>
                                      <Form.Label>Validate On Field</Form.Label>
                                      <select
                                        className="form-control"
                                        name="bonusPointInfo.onProfileComplete.validateOnField"
                                        onChange={(e) =>
                                          handleChanges({
                                            target: {
                                              name: e.target.name,
                                              value: e.target.value,
                                            },
                                          })
                                        }
                                        value={
                                          tenantConfig?.bonusPointInfo
                                            ?.onProfileComplete
                                            ?.validateOnField || ''
                                        }>
                                        <option value="">
                                          --Select Field--
                                        </option>
                                        {uniqByKeepLast(
                                          [
                                            ...tenantConfig.uiConfig.userFields,
                                            ...customFields,
                                          ],
                                          (it) => it.name,
                                        ).map((item) => (
                                          <option
                                            key={item.name}
                                            value={item.name}>
                                            {item.placeholder}
                                          </option>
                                        ))}
                                      </select>
                                    </Form.Group>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </>
                        )}

                        {wizardStep === 5 && (
                          <>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Receipt Validation
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.salesValidation?.receipt ||
                                  false
                                }
                                onChange={({target}) => {
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  });
                                  // if (!target.checked) {
                                  //   customHandleChange({
                                  //     target: {
                                  //       name: 'allowRcptBucket',
                                  //       value: false,
                                  //     },
                                  //   });
                                  // }
                                }}
                                color="primary"
                                name="salesValidation.receipt"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            {tenantConfig?.salesValidation?.receipt && (
                              <Form.Group className="row align-items-center">
                                <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Mark All Receipts As Pending
                                </Form.Label>
                                <Switch
                                  checked={
                                    tenantConfig?.allReceiptsPending || false
                                  }
                                  onChange={({target}) => {
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    });
                                  }}
                                  color="primary"
                                  name="allReceiptsPending"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              </Form.Group>
                            )}
                            {tenantConfig?.salesValidation?.receipt && (
                              <Form.Group className="row align-items-center">
                                <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Allow Receipt Bucket
                                </Form.Label>
                                <Switch
                                  onChange={({target}) =>
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    })
                                  }
                                  color="primary"
                                  name="allowRcptBucket"
                                  checked={
                                    tenantConfig?.allowRcptBucket || false
                                  }
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              </Form.Group>
                            )}
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Multiple Receipt Upload
                              </Form.Label>
                              <Switch
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="multiReceiptUpload"
                                checked={
                                  tenantConfig?.multiReceiptUpload || false
                                }
                                inputProps={{
                                  'aria-label': 'primary checkbox',
                                }}
                              />
                            </Form.Group>
                            {tenantConfig?.salesValidation?.receipt && (
                              <Form.Group className="row align-items-center">
                                <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Receipt status callback URL
                                </Form.Label>
                                <Switch
                                  checked={tenantConfig?.receiptCallbackUrl}
                                  onChange={({target}) =>
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    })
                                  }
                                  color="primary"
                                  name="receiptCallbackUrl"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              </Form.Group>
                            )}
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Receipt Upload PreValidation
                              </Form.Label>
                              <Switch
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="receiptUploadPreValidation"
                                checked={
                                  tenantConfig?.receiptUploadPreValidation ||
                                  false
                                }
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Receipt Upload via Email
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <Switch
                                  checked={
                                    tenantConfig?.receiptUploadViaEmail
                                      ?.enable || false
                                  }
                                  onChange={({target}) =>
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    })
                                  }
                                  color="primary"
                                  name="receiptUploadViaEmail.enable"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              </div>
                            </div>
                            {tenantConfig?.receiptUploadViaEmail?.enable && (
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                  Receipt Received Via (Channel)
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <select
                                    className="form-control form-control-lg form-control-solid"
                                    onChange={handleChanges}
                                    name="receiptUploadViaEmail.channel"
                                    value={
                                      tenantConfig?.receiptUploadViaEmail
                                        ?.channel || ''
                                    }>
                                    <option value="">Select Channel</option>
                                    <option value="attachment">
                                      As a attachment
                                    </option>
                                    <option value="forwarded">
                                      Email Forwarded
                                    </option>
                                  </select>
                                </div>
                              </div>
                            )}
                            {tenantConfig?.receiptUploadViaEmail?.enable && (
                              <>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Source Email User
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        (tenantConfig &&
                                          tenantConfig?.receiptUploadViaEmail
                                            ?.emailUser) ||
                                        ''
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="receiptUploadViaEmail.emailUser"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Source Email password
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        (tenantConfig &&
                                          tenantConfig?.receiptUploadViaEmail
                                            ?.emailPassword) ||
                                        ''
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="receiptUploadViaEmail.emailPassword"
                                      type="password"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Source Email Host
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        (tenantConfig &&
                                          tenantConfig?.receiptUploadViaEmail
                                            ?.emailHost) ||
                                        ''
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="receiptUploadViaEmail.emailHost"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Source Email Port
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        (tenantConfig &&
                                          tenantConfig?.receiptUploadViaEmail
                                            ?.emailPort) ||
                                        ''
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="receiptUploadViaEmail.emailPort"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Backup Email User
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        (tenantConfig &&
                                          tenantConfig?.receiptUploadViaEmail
                                            ?.backupEmailUser) ||
                                        ''
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="receiptUploadViaEmail.backupEmailUser"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Product Code Validation
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.salesValidation?.code || false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="salesValidation.code"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Dynamic Messaging
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.optionalServices?.dynamicMsg ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="optionalServices.dynamicMsg"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Help Desk Services
                              </Form.Label>
                              <Switch
                                checked={
                                  (tenantConfig &&
                                    tenantConfig?.helpDeskServices?.enable) ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="helpDeskServices.enable"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            {tenantConfig?.helpDeskServices?.enable && (
                              <>
                                {tenantConfig?.helpDeskServices?.inboxId && (
                                  <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                      InboxId :
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                      <h3 className="form-control">
                                        {
                                          tenantConfig?.helpDeskServices
                                            ?.inboxId
                                        }
                                      </h3>
                                    </div>
                                  </div>
                                )}

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Team Work BaseUrl V1
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig &&
                                        tenantConfig?.helpDeskServices
                                          ?.teamworkBaseUrlV1
                                      }
                                      className={`form-control form-control-lg form-control-solid ${
                                        tenantConfig?.helpDeskServices?.inboxId
                                          ? 'bg-secondary'
                                          : ''
                                      }`}
                                      name="helpDeskServices.teamworkBaseUrlV1"
                                      type="text"
                                      onChange={handleChanges}
                                      disabled={
                                        tenantConfig?.helpDeskServices?.inboxId
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Team Work BaseUrl V2
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig &&
                                        tenantConfig?.helpDeskServices
                                          ?.teamworkBaseUrlV2
                                      }
                                      className={`form-control form-control-lg form-control-solid ${
                                        tenantConfig?.helpDeskServices?.inboxId
                                          ? 'bg-secondary'
                                          : ''
                                      }`}
                                      name="helpDeskServices.teamworkBaseUrlV2"
                                      type="text"
                                      onChange={handleChanges}
                                      disabled={
                                        tenantConfig?.helpDeskServices?.inboxId
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Team Work Permanent Token
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig &&
                                        tenantConfig?.helpDeskServices
                                          ?.teamworkPermanentToken
                                      }
                                      className={`form-control form-control-lg form-control-solid ${
                                        tenantConfig?.helpDeskServices?.inboxId
                                          ? 'bg-secondary'
                                          : ''
                                      }`}
                                      name="helpDeskServices.teamworkPermanentToken"
                                      type="text"
                                      onChange={handleChanges}
                                      disabled={
                                        tenantConfig?.helpDeskServices?.inboxId
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Team Work Agent Id
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig &&
                                        tenantConfig?.helpDeskServices
                                          ?.teamworkAgentId
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="helpDeskServices.teamworkAgentId"
                                      type="number"
                                      onChange={(e) =>
                                        handleChanges({
                                          target: {
                                            name: e.target.name,
                                            value: isNaN(
                                              parseInt(e.target.value),
                                            )
                                              ? ''
                                              : parseInt(e.target.value),
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Use Reward Token
                              </Form.Label>
                              <Switch
                                checked={
                                  tenantConfig?.rewardConfig?.useRewardToken ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="rewardConfig.useRewardToken"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            {/* <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Receipt Upload Email
                              </Form.Label>
                              <Switch
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="receiptUploadEmail"
                                checked={
                                  tenantConfig?.receiptUploadEmail || false
                                }
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group> */}
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Receipt Upload Email
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  value={
                                    (tenantConfig &&
                                      tenantConfig?.receiptUploadEmail) ||
                                    ''
                                  }
                                  className="form-control form-control-lg form-control-solid"
                                  name="receiptUploadEmail"
                                  type="text"
                                  onChange={handleChanges}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Reward Claim Url
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  value={
                                    (tenantConfig &&
                                      tenantConfig?.rewardConfig
                                        ?.rewardClaimUrl) ||
                                    ''
                                  }
                                  className="form-control form-control-lg form-control-solid"
                                  name="rewardConfig.rewardClaimUrl"
                                  type="text"
                                  onChange={handleChanges}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Reward Limit Settings
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <Switch
                                  checked={
                                    tenantConfig?.rewardLimitSettings
                                      ?.enabled || false
                                  }
                                  onChange={({target}) =>
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    })
                                  }
                                  color="primary"
                                  name="rewardLimitSettings"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              </div>
                            </div>
                            {tenantConfig?.rewardLimitSettings?.enabled && (
                              <>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Reward Limit Type
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <select
                                      className="form-control form-control-lg form-control-solid"
                                      onChange={handleChanges}
                                      name="rewardLimitSettings_limitType"
                                      value={
                                        tenantConfig?.rewardLimitSettings
                                          ?.limitType
                                      }>
                                      <option value="quantity">Quantity</option>
                                      <option value="amount">Amount</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Rewards Limit
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig?.rewardLimitSettings
                                          ?.rewardLimit
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="rewardLimitSettings_rewardLimit"
                                      type="number"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Reward Limit Percent Reached
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig?.rewardLimitSettings
                                          ?.limitPercentReached
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="rewardLimitSettings_limitPercentReached"
                                      type="number"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Email for Send Alert(After limit reach of
                                    rewards)
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig?.rewardLimitSettings
                                          ?.emailIds
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="rewardLimitSettings_emailIds"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Google Analytics Enable
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <Switch
                                  checked={
                                    tenantConfig?.googleAnalyticsEnabled ||
                                    false
                                  }
                                  onChange={({target}) =>
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    })
                                  }
                                  color="primary"
                                  name="googleAnalyticsEnabled"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              </div>
                            </div>
                            {tenantConfig?.googleAnalyticsEnabled && (
                              <>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Google Analytics Id
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        (tenantConfig &&
                                          tenantConfig?.googleAnalyticsId) ||
                                        ''
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="googleAnalyticsId"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Google Analytics Property Id
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        (tenantConfig &&
                                          tenantConfig?.googleAnalyticsPropertyId) ||
                                        ''
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="googleAnalyticsPropertyId"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Google Analytics Config
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <textarea
                                      rows="7"
                                      className="md-textarea form-control"
                                      onChange={handleChanges}
                                      name="googleAnalyticsConfig"
                                      defaultValue={
                                        tenantConfig &&
                                        JSON.stringify(
                                          tenantConfig?.googleAnalyticsConfig,
                                        )
                                      }></textarea>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                GTM Enable
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <Switch
                                  checked={
                                    tenantConfig?.gtmConfig?.enabled || false
                                  }
                                  onChange={({target}) =>
                                    handleChanges({
                                      target: {
                                        name: target.name,
                                        value: target.checked,
                                      },
                                    })
                                  }
                                  color="primary"
                                  name="gtmConfig.enabled"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              </div>
                            </div>
                            {tenantConfig?.gtmConfig?.enabled && (
                              <>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    GTM ID
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        (tenantConfig &&
                                          tenantConfig?.gtmConfig?.gtmId) ||
                                        ''
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="gtmConfig.gtmId"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    GTM Analytics Property Id
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        (tenantConfig &&
                                          tenantConfig?.gtmConfig
                                            ?.gtmAnalyticsPropertyId) ||
                                        ''
                                      }
                                      className="form-control form-control-lg form-control-solid"
                                      name="gtmConfig.gtmAnalyticsPropertyId"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    GTM Analytics Config
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <textarea
                                      rows="7"
                                      className="md-textarea form-control"
                                      onChange={handleChanges}
                                      name="gtmConfig.gtmAnalyticsConfig"
                                      defaultValue={
                                        tenantConfig &&
                                        JSON.stringify(
                                          tenantConfig?.gtmConfig
                                            ?.gtmAnalyticsConfig,
                                        )
                                      }></textarea>
                                  </div>
                                </div>
                              </>
                            )}
                            {/******Test *****/}
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Salesforce Configuration
                              </Form.Label>
                              <Switch
                                checked={
                                  (tenantConfig &&
                                    tenantConfig?.salesForce?.enable) ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="salesForce.enable"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            {tenantConfig?.salesForce?.enable && (
                              <>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Access Token Url
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig &&
                                        tenantConfig?.salesForce?.accessTokenUrl
                                      }
                                      className={`form-control form-control-lg form-control-solid`}
                                      name="salesForce.accessTokenUrl"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Account Id
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig &&
                                        tenantConfig?.salesForce?.accountId
                                      }
                                      className={`form-control form-control-lg form-control-solid `}
                                      name="salesForce.accountId"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Client Id
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig &&
                                        tenantConfig?.salesForce?.clientId
                                      }
                                      className={`form-control form-control-lg form-control-solid `}
                                      name="salesForce.clientId"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Client Secret
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig &&
                                        tenantConfig?.salesForce?.clientSecret
                                      }
                                      className={`form-control form-control-lg form-control-solid `}
                                      name="salesForce.clientSecret"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Data URL
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig &&
                                        tenantConfig?.salesForce?.dataUrl
                                      }
                                      className={`form-control form-control-lg form-control-solid `}
                                      name="salesForce.dataUrl"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Grant Type
                                  </label>
                                  <div className="col-lg-9 col-xl-6">
                                    <input
                                      value={
                                        tenantConfig &&
                                        tenantConfig?.salesForce?.grantType
                                      }
                                      className={`form-control form-control-lg form-control-solid `}
                                      name="salesForce.grantType"
                                      type="text"
                                      onChange={handleChanges}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            <Form.Group className="row align-items-center">
                              <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                On Registration Additional Configuration
                              </Form.Label>
                              <Switch
                                checked={
                                  (tenantConfig &&
                                    tenantConfig?.registrationPurchaseConfig
                                      ?.enable) ||
                                  false
                                }
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="registrationPurchaseConfig.enable"
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </Form.Group>
                            {tenantConfig?.registrationPurchaseConfig
                              ?.enable && (
                              <>
                                <Form.Group className="row align-items-center">
                                  <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Registration via Digital Purchase
                                  </Form.Label>
                                  <Switch
                                    checked={
                                      (tenantConfig &&
                                        tenantConfig?.registrationPurchaseConfig
                                          ?.digitalPurchase) ||
                                      false
                                    }
                                    onChange={({target}) =>
                                      handleChanges({
                                        target: {
                                          name: target.name,
                                          value: target.checked,
                                        },
                                      })
                                    }
                                    color="primary"
                                    name="registrationPurchaseConfig.digitalPurchase"
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                </Form.Group>
                                {tenantConfig?.registrationPurchaseConfig
                                  ?.digitalPurchase && (
                                  <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                      Movies AnyWhere Token
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                      <input
                                        value={
                                          tenantConfig &&
                                          tenantConfig
                                            ?.registrationPurchaseConfig
                                            ?.digitalConfig?.moviesAnyWhere
                                            ?.token
                                        }
                                        className={`form-control form-control-lg form-control-solid `}
                                        name="registrationPurchaseConfig.digitalConfig.moviesAnyWhere.token"
                                        type="text"
                                        onChange={handleChanges}
                                      />
                                    </div>
                                  </div>
                                )}
                                <Form.Group className="row align-items-center">
                                  <Form.Label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                    Registration via Disc Purchase
                                  </Form.Label>
                                  <Switch
                                    checked={
                                      (tenantConfig &&
                                        tenantConfig?.registrationPurchaseConfig
                                          ?.discPurchase) ||
                                      false
                                    }
                                    onChange={({target}) =>
                                      handleChanges({
                                        target: {
                                          name: target.name,
                                          value: target.checked,
                                        },
                                      })
                                    }
                                    color="primary"
                                    name="registrationPurchaseConfig.discPurchase"
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                </Form.Group>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Form>
                    <div className="card-header py-3 d-flex justify-content-end">
                      <div className="card-toolbar">
                        <button
                          type="button"
                          disabled={wizardStep <= 1 && 'disabled'}
                          className="btn btn-success mr-2"
                          onClick={() => wizardPrevious()}>
                          MOVE PREVIOUS
                        </button>
                        <button
                          type="button"
                          // disabled={wizardStep >= 5 && 'disabled'}
                          className="btn btn-success mr-2"
                          onClick={() => wizardNext()}>
                          MOVE NEXT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChangeAccodion('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header">
          <Typography className={classes.heading}>
            Service Configuration
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ServiceConfig
            data={tenantConfig}
            onChange={handleChange}
            setExpanded={setExpanded}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChangeAccodion('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header">
          <Typography className={classes.heading}>Ui Configuration</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UiConfig
            data={tenantConfig}
            onChange={handleChange}
            setExpanded={setExpanded}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChangeAccodion('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="pane43bh-header">
          <Typography className={classes.heading}>
            Template Generation
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {id ? (
            <TemplateGenerate
              data={tenantConfig}
              onChange={handleChange}
              id={id}
              setExpanded={setExpanded}
            />
          ) : null}
        </AccordionDetails>
      </Accordion>
    </ErrorBoundary>
  );
};

export default connect(null, {
  addCompaign,
  getCampaignDetailByToken,
  updateCompaign,
})(IsLoadingHOC(TenantManagement));
