import React from 'react';

import 'chart.js/auto';
import {Chart} from 'react-chartjs-2';
const options = {
  maintainAspectRatio: false,
};

const CommanChart = ({type, data, optionsNew = {}}) => {
  //console.log('data data ------->', data, type);
  const dataNew = (data) => {
    // console.log('working');
    // if (type === 'bar') {
    //   console.log('working1');
    //   const optionsData = {
    //     ...data,
    //     options: {
    //       ...data.options,
    //       scales: {
    //         ...Chart.options.scales,
    //         x: {
    //           ticks: {
    //             fontFamily: 'Lato',
    //             fontColor: '#fff',
    //             fontSize: 14,
    //             minRotation: 30,
    //           },
    //         },
    //       },
    //     },
    //   };
    //   console.log('new Data', newData);
    //   return data;
    // } else {
    //   return data;
    // }
  };
  const customOptions = () => {
    return {
      ...optionsNew,
      responsive: true,
      datasets: [
        {
          label: 'My First Dataset',
          data: [300, 50, 100],
          borderWidth: '0',
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
          ],
        },
      ],
      scales: {
        ...optionsNew?.scales,
        x: {
          ticks: {
            fontFamily: 'Lato',
            fontColor: '#fff',
            fontSize: 14,
            minRotation: 30,
          },
        },
      },
    };
  };
  
  const newData = {
    ...data,
    datasets: data.datasets.map((item) => ({...item, borderWidth: 0})),
  };
  return (
    <Chart
      options={type !== 'bar' ? options : customOptions}
      type={type}
      data={newData}
    />
  );
};

export default CommanChart;
