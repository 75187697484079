import React, {useEffect, useState} from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import {useHistory, useParams} from 'react-router-dom';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {ErrorMessage, Form, Formik} from 'formik';
import AddEditRBACvalidationSchema from '../Schema/RBAC.Schema';
import {FormHelperText, Switch} from '@mui/material';
import {toast} from 'react-toastify';
import {addEditRoleAction, getRoleDetailsAction} from '../_redux/action';
const AddEdit = (props) => {
  const {id} = useParams();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({
    role: '',
    description: '',
    status: 'active',
  });
  useEffect(() => {
    async function fetchRoleData(id) {
      try {
        if (id) {
          props.setLoading(true);
          const response = await getRoleDetailsAction(id);
          if (response.httpCode == 200) {
            const {role, description, status} = response.data[0];
            setInitialValues({role, description, status});
            props.setLoading(false);
          }
        }
      } catch (error) {
        props.setLoading(false);
      }
    }
    fetchRoleData(id);
  }, [id]);

  const handleChange = (e, values, setValues) => {
    let {name, type, value, checked} = e.target;
    if (type == 'checkbox') {
      value = checked ? 'active' : 'inactive';
    }
    if (name == 'role') {
      value = value.toUpperCase();
    }
    setValues({...values, [name]: value});
  };
  const handleSubmit = async (values, {resetForm}) => {
    try {
      props.setLoading(true);
      console.log('values', values);
      const response = await addEditRoleAction(values, id);
      if (response.httpCode == 200) {
        toast.success(response.message);
        resetForm(initialValues);
        props.setLoading(false);
        history.push('/rbac/roles/list');
      } else {
        const error = response.errors;
        error.forEach((item) => {
          toast.error(item.message);
        });
        props.setLoading(false);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  };
  return (
    <>
      <Card>
        <CardHeader title={id ? 'Edit Role' : 'Add Role'}>
          <CardHeaderToolbar>
            <button
              className="btn btn-info"
              type="button"
              onClick={() => history.push('/rbac/roles/list')}>
              Back
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={AddEditRBACvalidationSchema}>
            {({values, errors, setValues}) => (
              <Form>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label text-lg-left">
                    Role:
                  </label>
                  <div className="col-lg-9 col-xl-4">
                    <input
                      className="form-control form-control-lg text-uppercases"
                      onChange={(e) => handleChange(e, values, setValues)}
                      name="role"
                      value={values.role}
                    />
                    <FormHelperText>
                      <ErrorMessage
                        name="role"
                        render={(msg) => (
                          <span className="text-danger">{msg}</span>
                        )}
                      />
                    </FormHelperText>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label text-lg-left">
                    Description:
                  </label>
                  <div className="col-lg-9 col-xl-4">
                    <textarea
                      cols="40"
                      rows="5"
                      className="form-control form-control-lg"
                      onChange={(e) => handleChange(e, values, setValues)}
                      name="description"
                      value={values.description}
                    />
                    <FormHelperText>
                      <ErrorMessage
                        name="description"
                        render={(msg) => (
                          <span className="text-danger">{msg}</span>
                        )}
                      />
                    </FormHelperText>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-form-label text-lg-left">
                    Status
                  </label>
                  <div className="col-lg-9 col-xl-4">
                    <Switch
                      onChange={(e) => handleChange(e, values, setValues)}
                      name="status"
                      checked={
                        values.status === 'active' || values.status === true
                          ? true
                          : false
                      }
                      // checked={!!get(values, 'status')}
                    />
                    {values.status === true || values.status === 'active'
                      ? 'active'
                      : 'inactive'}
                    <FormHelperText>
                      <ErrorMessage
                        name="status"
                        render={(msg) => (
                          <span className="text-danger">{msg}</span>
                        )}
                      />
                    </FormHelperText>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      className="btn btn-primary font-weight-bold me-2">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </>
  );
};

export default IsLoadingHOC(AddEdit, '..');
