import React, {useEffect, useState, Fragment} from 'react';
import {Button, Form, Row, Col, Field} from 'react-bootstrap';

import {Switch} from '@mui/material';
import ErrorBoundary from '../../_helpers/ErrorBoundary';

const ServiceConfig = ({data: tenantConfig, onChange, setExpanded}) => {
  const [wizardStep, setWizardStep] = useState(1);

  const {pointTierConfig} = tenantConfig;
  const [additionalDomain, setAdditionalDomain] = useState([]);
  useEffect(() => {
    if (tenantConfig?.additionalDomain) {
      setAdditionalDomain(tenantConfig?.additionalDomain || []);
    }
  }, [tenantConfig]);
  const wizardPrevious = () => {
    if (wizardStep === 1) {
      setExpanded('panel1');
    }
    if (wizardStep > 1) {
      setWizardStep(wizardStep - 1);
    }
  };

  const wizardNext = () => {
    if (wizardStep >= 6) {
      setExpanded('panel3');
    } else {
      setWizardStep(wizardStep + 1);
    }
  };

  const addNewTier = () => {
    const newData = [
      ...tenantConfig.pointTierConfig,
      {
        tierId: pointTierConfig.length + 1,
        tierName: '',
        desc: '',
        multiplier: 1,
        'level-up-points': 0,
      },
    ];
    const configNewUpdated = {...tenantConfig, pointTierConfig: [...newData]};
    onChange(configNewUpdated);
  };

  const removeTier = (tierId) => {
    //console.log('id', tierId);
    const newData = tenantConfig.pointTierConfig.filter(
      (item) => item.tierId !== tierId,
    );
    const configNewUpdated = {...tenantConfig, pointTierConfig: [...newData]};
    onChange(configNewUpdated);
  };

  const onChangePointTierConfig = (name, value, id) => {
    const copyValue = pointTierConfig;
    copyValue[id][name] = value;
    const configNewUpdated = {...tenantConfig, pointTierConfig: [...copyValue]};
    onChange(configNewUpdated);
  };
  const onChangeAdditionalDomains = (name, value, ind) => {
    const domains = [...additionalDomain];
    domains[ind][name] = value;
    const configNewUpdated = {...tenantConfig, additionalDomain: [...domains]};
    // setAdditionalDomain(domains);
    onChange(configNewUpdated);
  };
  const handleChanges = (e) => {
    const {name, value} = e.target;
    const arrayValue = name.split('.');
    let configNewUpdated = {};
    if (arrayValue.length > 1) {
      configNewUpdated = {
        ...tenantConfig,
        [arrayValue[0]]: {
          ...tenantConfig[arrayValue[0]],
          [arrayValue[1]]: value,
        },
      };
    } else {
      configNewUpdated = {...tenantConfig, [name]: value};
    }
    if (name === 'enableSession') {
      configNewUpdated = {
        ...configNewUpdated,
        sessionExpiryTime: value ? 1440 : 0,
      };
    }
    onChange(configNewUpdated);
  };

  const tabClasses = 'navi-link py-4';
  const tabActiveClasses = 'navi-link py-4 active';

  const stepHeading =
    wizardStep && wizardStep === 1
      ? 'Basic Server Informations'
      : wizardStep === 2
      ? 'Domain Configuration'
      : wizardStep === 3
      ? 'Mailing Details'
      : wizardStep === 4
      ? 'Point Tier Config'
      : wizardStep === 5
      ? ' Open APIs'
      : wizardStep === 6
      ? 'NeoCurrency Configuration'
      : 'Value Added Services';
  //console.log("service Config", tenantConfig);
  const addRemoveDomain = (ind, type) => {
    let domains = [...additionalDomain];
    if (type === 'add') {
      domains.push({domain: '', language: ''});
    } else if (type === 'remove') {
      domains.splice(ind, 1);
    }
    const configNewUpdated = {...tenantConfig, additionalDomain: [...domains]};
    onChange(configNewUpdated);
  };
  return (
    <ErrorBoundary>
      <div className="d-flex flex-column flex-root">
        <div className="container">
          <div className="d-flex flex-row">
            <div
              className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
              id="kt_profile_aside">
              <div className="card card-custom card-stretch">
                <div className="card-body pt-4">
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <div className="navi-item mb-2">
                      <span
                        className={
                          wizardStep === 1 ? tabActiveClasses : tabClasses
                        }>
                        <span className="navi-icon me-2">
                          <span className="svg-icon">1</span>
                        </span>
                        <span className="navi-text font-size-lg">
                          Basic Server Information
                        </span>
                      </span>
                    </div>
                    <div className="navi-item mb-2">
                      <span
                        className={
                          wizardStep === 2 ? tabActiveClasses : tabClasses
                        }>
                        <span className="navi-icon me-2">
                          <span className="svg-icon">2</span>
                        </span>
                        <span className="navi-text font-size-lg">
                          Domain Configuration
                        </span>
                      </span>
                    </div>
                    <div className="navi-item mb-2">
                      <span
                        className={
                          wizardStep === 3 ? tabActiveClasses : tabClasses
                        }>
                        <span className="navi-icon me-2">
                          <span className="svg-icon">3</span>
                        </span>
                        <span className="navi-text font-size-lg">
                          Mailing Details
                        </span>
                      </span>
                    </div>
                    <div className="navi-item mb-2">
                      <span
                        className={
                          wizardStep === 4 ? tabActiveClasses : tabClasses
                        }>
                        <span className="navi-icon me-2">
                          <span className="svg-icon">4</span>
                        </span>
                        <span className="navi-text font-size-lg">
                          Point Tier Config
                        </span>
                      </span>
                    </div>
                    <div className="navi-item mb-2">
                      <span
                        className={
                          wizardStep === 5 ? tabActiveClasses : tabClasses
                        }>
                        <span className="navi-icon me-2">
                          <span className="svg-icon">5</span>
                        </span>
                        <span className="navi-text font-size-lg">
                          Open APIs
                        </span>
                      </span>
                    </div>
                    <div className="navi-item mb-2">
                      <span
                        className={
                          wizardStep === 6 ? tabActiveClasses : tabClasses
                        }>
                        <span className="navi-icon me-2">
                          <span className="svg-icon">5</span>
                        </span>
                        <span className="navi-text font-size-lg">
                          NeoCurrency Configuration
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-row-fluid ml-lg-8">
              <div className="card card-custom">
                <Form className="form">
                  <div
                    className="card-header py-3"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingTop: '20px ​!important',
                    }}>
                    <div className="card-title align-items-start flex-column">
                      <h3 className="card-label font-weight-bolder text-dark">
                        {stepHeading}
                      </h3>
                      <span className="text-muted font-weight-bold font-size-sm mt-1">
                        Change your account settings
                      </span>
                    </div>
                    <div className="card-toolbar">
                      <button
                        type="button"
                        //disabled={wizardStep <= 1 && 'disabled'}
                        className="btn btn-success me-2"
                        onClick={() => wizardPrevious()}>
                        MOVE PREVIOUS
                      </button>
                      <button
                        type="button"
                        //  disabled={wizardStep >= 4 && 'disabled'}
                        className="btn btn-success me-2"
                        onClick={() => wizardNext()}>
                        MOVE NEXT
                      </button>
                    </div>
                  </div>

                  <div className="card-body">
                    {wizardStep === 1 && (
                      <>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Enable Session
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <Switch
                              onChange={({target}) =>
                                handleChanges({
                                  target: {
                                    name: target.name,
                                    value: target.checked,
                                  },
                                })
                              }
                              color="primary"
                              name="enableSession"
                              checked={tenantConfig?.enableSession || false}
                              inputProps={{'aria-label': 'primary checkbox'}}
                            />
                          </div>
                        </div>
                        {tenantConfig?.enableSession && (
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                              Session Expiry Time (In minutes)
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <input
                                className="form-control form-control-lg form-control-solid"
                                name="sessionExpiryTime"
                                type="number"
                                value={tenantConfig?.sessionExpiryTime}
                                onChange={(e) => {
                                  handleChanges({
                                    target: {
                                      name: e.target.name,
                                      value: parseInt(e.target.value),
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Domain
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="domain"
                              type="text"
                              value={tenantConfig?.domain || ''}
                              onChange={handleChanges}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Additional Domains
                          </label>
                          <div className="col-lg-9 col-xl-9">
                            {additionalDomain &&
                              additionalDomain.map((v, i) => {
                                return (
                                  <div
                                    className="row"
                                    key={`row-add-domain-${i}`}>
                                    <div className="col-lg-6 col-xl-6">
                                      <input
                                        className="form-control form-control-lg "
                                        type="text"
                                        value={v?.domain || ''}
                                        onChange={(e) =>
                                          onChangeAdditionalDomains(
                                            'domain',
                                            e.target.value,
                                            i,
                                          )
                                        }
                                        placeholder="Domain"
                                      />
                                    </div>
                                    <div className="col-lg-4 col-xl-4">
                                      <select
                                        className="form-select form-control-lg"
                                        value={v?.language || ''}
                                        onChange={(e) =>
                                          onChangeAdditionalDomains(
                                            'language',
                                            e.target.value,
                                            i,
                                          )
                                        }>
                                        <option value="">
                                          Select Language
                                        </option>
                                        <option value="EN">English</option>
                                        <option value="FR">French</option>
                                        <option value="ES">Spanish</option>
                                      </select>
                                    </div>
                                    <div className="col-lg-2 col-xl-2">
                                      <p align="right">
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          title="Click to remove domain"
                                          onClick={(e) =>
                                            addRemoveDomain(i, 'remove')
                                          }>
                                          <i
                                            className="fa fa-minus"
                                            aria-hidden="true"></i>
                                        </button>
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            <p align="right">
                              <button
                                type="button"
                                className="btn btn-success"
                                title="Click to add domain"
                                onClick={(e) => addRemoveDomain(0, 'add')}>
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"></i>
                              </button>
                            </p>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Pre Registration
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <Switch
                              onChange={({target}) =>
                                handleChanges({
                                  target: {
                                    name: target.name,
                                    value: target.checked,
                                  },
                                })
                              }
                              color="primary"
                              name="usePreRegistration"
                              checked={
                                tenantConfig?.usePreRegistration || false
                              }
                              inputProps={{'aria-label': 'primary checkbox'}}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Fingerprinting Enable
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <Switch
                              onChange={({target}) =>
                                handleChanges({
                                  target: {
                                    name: target.name,
                                    value: target.checked,
                                  },
                                })
                              }
                              color="primary"
                              name="fingerprinting.enable"
                              checked={
                                tenantConfig?.fingerprinting?.enable || false
                              }
                              inputProps={{'aria-label': 'primary checkbox'}}
                            />
                          </div>
                        </div>
                        {tenantConfig?.fingerprinting?.enable && (
                          <>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Fingerprinting Client Key
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  className="form-control form-control-lg form-control-solid"
                                  name="fingerprinting.clientKey"
                                  type="text"
                                  value={
                                    tenantConfig?.fingerprinting?.clientKey ||
                                    ''
                                  }
                                  onChange={handleChanges}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                                Fingerprinting Server Key
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  className="form-control form-control-lg form-control-solid"
                                  name="fingerprinting.serverKey"
                                  type="text"
                                  value={
                                    tenantConfig?.fingerprinting?.serverKey ||
                                    ''
                                  }
                                  onChange={handleChanges}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {wizardStep === 2 && (
                      <>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            From Email
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="fromEmail"
                              type="text"
                              value={tenantConfig?.fromEmail}
                              onChange={handleChanges}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Reply-to Email
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="replyToEmail"
                              type="email"
                              value={tenantConfig?.replyToEmail}
                              onChange={handleChanges}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Support Email
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              value={tenantConfig && tenantConfig?.supportEmail}
                              className="form-control form-control-lg form-control-solid"
                              name="supportEmail"
                              type="text"
                              onChange={handleChanges}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Contact Support Email
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              value={
                                tenantConfig &&
                                tenantConfig?.contactSupportEmail
                              }
                              className="form-control form-control-lg form-control-solid"
                              name="contactSupportEmail"
                              type="text"
                              onChange={handleChanges}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Send Grid API Key
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="sendGridCreds.apikey"
                              type="email"
                              value={tenantConfig?.sendGridCreds?.apikey}
                              onChange={handleChanges}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Support Email Name
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="sendGridCreds.supportEmailName"
                              type="email"
                              value={
                                tenantConfig?.sendGridCreds?.supportEmailName
                              }
                              onChange={handleChanges}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {wizardStep === 3 && (
                      <>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Twitter Handle
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="socialHandles.twitterHandle"
                              type="text"
                              value={tenantConfig?.socialHandles?.twitterHandle}
                              onChange={handleChanges}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Instagram Handle
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="socialHandles.instagramHandle"
                              type="text"
                              value={
                                tenantConfig?.socialHandles?.instagramHandle
                              }
                              onChange={handleChanges}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Facebook Handles
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="socialHandles.facebookHanle"
                              type="text"
                              value={tenantConfig?.socialHandles?.facebookHanle}
                              onChange={handleChanges}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Tiktok Handle
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="socialHandles.tiktokHandle"
                              type="text"
                              value={tenantConfig?.socialHandles?.tiktokHandle}
                              onChange={handleChanges}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {wizardStep === 4 && (
                      <>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Is Tier Enable
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <Switch
                              onChange={({target}) =>
                                handleChanges({
                                  target: {
                                    name: target.name,
                                    value: target.checked,
                                  },
                                })
                              }
                              color="primary"
                              name="isTierEnabled"
                              checked={tenantConfig?.isTierEnabled}
                              inputProps={{'aria-label': 'primary checkbox'}}
                            />
                          </div>
                        </div>

                        {tenantConfig?.isTierEnabled &&
                          pointTierConfig?.map((item, index) => {
                            return (
                              <div key={index}>
                                <Row className="g-2" key={item.tierid}>
                                  <Col md>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Tier Name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="tierName"
                                        id="tierName"
                                        onChange={(e) =>
                                          onChangePointTierConfig(
                                            e.target.name,
                                            e.target.value,
                                            item.tierId - 1,
                                          )
                                        }
                                        value={item.tierName}
                                        placeholder="Tier Name"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Description</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="desc"
                                        id="desc"
                                        onChange={(e) =>
                                          onChangePointTierConfig(
                                            e.target.name,
                                            e.target.value,
                                            item.tierId - 1,
                                          )
                                        }
                                        value={item.desc}
                                        placeholder="Description"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row className="g-3">
                                  <Col md>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Multiplier</Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="multiplier"
                                        id="multiplier"
                                        onChange={(e) =>
                                          onChangePointTierConfig(
                                            e.target.name,
                                            parseFloat(e.target.value),
                                            item.tierId - 1,
                                          )
                                        }
                                        value={item.multiplier}
                                        placeholder="Multiplier"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Level Up Points</Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="level-up-points"
                                        id="level-up-points"
                                        onChange={(e) =>
                                          onChangePointTierConfig(
                                            e.target.name,
                                            parseInt(e.target.value),
                                            item.tierId - 1,
                                          )
                                        }
                                        value={item['level-up-points']}
                                        placeholder="Enter Level Up points"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md>
                                    <Form.Group className="mb-3">
                                      <button
                                        type="button"
                                        className="btn btn-success me-2"
                                        onClick={() => addNewTier()}>
                                        Add New
                                      </button>
                                      {index > 0 ? (
                                        <button
                                          type="button"
                                          className="btn btn-danger me-2"
                                          onClick={() =>
                                            removeTier(item.tierId)
                                          }>
                                          Remove
                                        </button>
                                      ) : null}
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                      </>
                    )}
                    {wizardStep === 5 && (
                      <>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Short Code
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="shortCode"
                              type="text"
                              value={tenantConfig?.shortCode}
                              onChange={handleChanges}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Public Apis
                          </label>
                          <div className="col-xl-3">
                            <label className="col-form-label text-left text-lg-left">
                              Activity List
                            </label>
                            <div>
                              <Switch
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="publicApis.activityList"
                                checked={tenantConfig?.publicApis?.activityList}
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <label className="col-form-label text-left text-lg-left">
                              Reward List
                            </label>
                            <div>
                              <Switch
                                onChange={({target}) =>
                                  handleChanges({
                                    target: {
                                      name: target.name,
                                      value: target.checked,
                                    },
                                  })
                                }
                                color="primary"
                                name="publicApis.rewardList"
                                checked={tenantConfig?.publicApis?.rewardList}
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {wizardStep === 6 && (
                      <>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            NeoCurrency Program ID
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="neoCurrencyProjectId"
                              type="number"
                              value={tenantConfig?.neoCurrencyProjectId}
                              onChange={(e) => {
                                handleChanges({
                                  target: {
                                    name: e.target.name,
                                    value: parseFloat(e.target.value),
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            NeoCurrency Campaign ID
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="neoCurrencyCampaignId"
                              type="number"
                              value={tenantConfig?.neoCurrencyCampaignId}
                              onChange={(e) => {
                                handleChanges({
                                  target: {
                                    name: e.target.name,
                                    value: parseFloat(e.target.value),
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-xl-3 col-lg-3 col-form-label text-left text-lg-left">
                            Insufficient Amount Limit
                          </label>
                          <div className="col-lg-9 col-xl-6">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              name="neoCurrencyInsufficientAmountLimit"
                              type="number"
                              value={
                                tenantConfig?.neoCurrencyInsufficientAmountLimit
                              }
                              onChange={(e) => {
                                handleChanges({
                                  target: {
                                    name: e.target.name,
                                    value: parseFloat(e.target.value),
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Form>
                <div className="card-header py-3 d-flex justify-content-end">
                  <div className="card-toolbar">
                    <button
                      type="button"
                      //disabled={wizardStep <= 1 && 'disabled'}
                      className="btn btn-success me-2"
                      onClick={() => wizardPrevious()}>
                      MOVE PREVIOUS
                    </button>
                    <button
                      type="button"
                      //  disabled={wizardStep >= 4 && 'disabled'}
                      className="btn btn-success me-2"
                      onClick={() => wizardNext()}>
                      MOVE NEXT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default React.memo(ServiceConfig);
