// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
//import * as actions from '../../../_redux/customers/customersActions';
import {getProcessAutomationList} from '../_redux/action';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../_metronic/_helpers';
import * as uiHelpers from '../ProcessAutomationUIHelpers';
import * as columnFormatters from './ActionsColumnFormatter';
import {Pagination} from '../../../../_metronic/_partials/controls';
// import {entities} from '../_mock';
import {useProcessAutomationUIContext} from '../ProcessAutomationUIContext';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import filterFactory from 'react-bootstrap-table2-filter';
import filterFun from '../../../../_metronic/_helpers/FilterHelper';

const ProcessAutomationTable = ({getProcessAutomationList, setLoading}) => {
  const history = useHistory();
  // ProcessAutomation UI Context
  const activitiessUIContext = useProcessAutomationUIContext();
  const processAutomationUIProps = useMemo(() => {
    return {
      ids: activitiessUIContext.ids,
      setIds: activitiessUIContext.setIds,
      queryParams: activitiessUIContext.queryParams,
      setQueryParams: activitiessUIContext.setQueryParams,
      openEditProcessAutomationDialog:
        activitiessUIContext.openEditProcessAutomationDialog,
      openDeleteProcessAutomationDialog:
        activitiessUIContext.openDeleteProcessAutomationDialog,
    };
  }, [activitiessUIContext]);

  // Getting curret state of ProcessAutomation list from store (Redux)
  const {currentState} = useSelector(
    (state) => ({currentState: state.processAutomation}),
    shallowEqual,
  );
  const {totalCount, entities, listLoading} = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    processAutomationUIProps.setIds([]);
    getProcessAutomationList(processAutomationUIProps.queryParams);
  }, [dispatch, processAutomationUIProps.queryParams]);
  useEffect(() => {
    setLoading(listLoading);
  }, [listLoading]);
  // Table columns
  const columns = [
    {
      dataField: 'process_model_name',
      text: 'Process Model Name',
      headerSortingClasses,
      filter: filterFun({placeholder: 'Search'}),
    },
    {
      dataField: 'description',
      text: 'Description',
      headerSortingClasses,
      filter: filterFun({placeholder: 'Search'}),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerSortingClasses,
      filter: filterFun({placeholder: 'Search'}),
    },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditProcessAutomationDialog:
          processAutomationUIProps.openEditProcessAutomationDialog,
        openDeleteProcessAutomationDialog:
          processAutomationUIProps.openDeleteProcessAutomationDialog,
        openExecuteProcessModele: (id) => {
          excuteProcessModel(id);
        },
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pe-3',
      style: {
        minWidth: '100px',
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: processAutomationUIProps.queryParams.limit,
    page: processAutomationUIProps.queryParams.page,
  };

  const excuteProcessModel = (id) => {
    // console.log('working', id);
    const currentProcess = entities.find((item) => item.id === id);
    history.push({
      pathname: `/process-automation/${id}/execute`,
      state: currentProcess,
    });
  };
  // console.log('processAutomationUIProps ui props', processAutomationUIProps);
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                filter={filterFactory()}
                onTableChange={getHandlerTableChange(
                  processAutomationUIProps.setQueryParams,
                )}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default connect(null, {getProcessAutomationList})(
  IsLoadingHOC(ProcessAutomationTable),
);
