import api from '../../../../api';
import {SAVE_REPORTING_LIST} from './type';
import {toast} from 'react-toastify';
export const getReportingList =
  (page, limit, url = 'report') =>
  async (dispatch) => {
    const newQuery = {
      limit: limit,
      page: page - 1,
      sortBy: 'createdAt',
      sortOrder: 'asc',
    };
    let queryString = Object.keys(newQuery)
      .map((key) => key + '=' + newQuery[key])
      .join('&');
    let data = await api.get(`/s1/${url}?${queryString}`);
    //console.log('reporting List Data', data);
    if (data.httpCode === 200) {
      dispatch({type: SAVE_REPORTING_LIST, payload: data.data});
    } else {
      dispatch({type: SAVE_REPORTING_LIST, payload: []});
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  };

export const getReportData = (id, body) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post(`/s1/report/generate-data/${id}`, body);
    if (data) {
      resolve(data);
      //dispatch({type: SAVE_REPORTING_LIST, payload: data.data});
    } else {
      // dispatch({type: SAVE_REPORTING_LIST, payload: []});
      reject('Error');
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};

export const getReportDetails = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/report/${id}`);
    if (data.httpCode === 200) {
      resolve(data.data);
      //dispatch({type: SAVE_REPORTING_LIST, payload: data.data});
    } else {
      // dispatch({type: SAVE_REPORTING_LIST, payload: []});
      reject('Error');
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};

export const getFirstChart = (id, startDate, endDate) => async (dispatch) => {
  // console.log('fsdjffds', startDate, endDate);
  return new Promise(async (resolve, reject) => {
    let data = await api.post(`/s1/report/generate-data/${id}`, {
      startDate: startDate,
      endDate: endDate,
    });
    if (data.httpCode === 200) {
      resolve(data.data);
      //dispatch({type: SAVE_REPORTING_LIST, payload: data.data});
    } else {
      // dispatch({type: SAVE_REPORTING_LIST, payload: []});
      reject('Error');
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};
export const getSummeryData = (startDate, endDate) => async (dispatch) => {
  // console.log('fsdjffds', startDate, endDate);
  return new Promise(async (resolve, reject) => {
    let data = await api.post(`/s1/report/generate-summary-data`, {
      startDate: startDate,
      endDate: endDate,
    });
    if (data.httpCode === 200) {
      resolve(data.data);
      //dispatch({type: SAVE_REPORTING_LIST, payload: data.data});
    } else {
      // dispatch({type: SAVE_REPORTING_LIST, payload: []});
      reject('Error');
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};

/* action creator for traffic API call for campaign configured with googleAnalytics
 */
export const getTrafficGoogleAnalyticsData =
  (startDate, endDate) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let data = await api.post(`/s1/report/get-ga-data`, {
        startDate: startDate,
        endDate: endDate,
      });
      if (data.httpCode >= 200 && data.httpCode <= 299) {
        resolve(data.data);
      } else {
        reject('Error');
        if (data?.errors) {
          if (Array.isArray(data.errors)) {
            data.errors.map((item) => {
              toast.error(item?.message);
            });
          }
        }
      }
    });
  };
export const getReportEarnBurnAction = (body) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post(`/s1/report/custom-data`, body);
    if (data.httpCode >= 200 && data.httpCode <= 299) {
      resolve(data.data);
    } else {
      reject('Error');
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};

//https://dev.userportal-terra.rsi-rnd.com/api/s1/report/generate-summary-data/623183156bba8dd29a81bbcf
