import * as Yup from 'yup';
const CampaignSchema = Yup.object().shape({
  domain: Yup.string().url().required('Domain Should not be empty'),
  name: Yup.string().required('Site Name Should not be empty'),
  supportEmail: Yup.string().required('Support Email should not be empty'),
  status: Yup.string().required('Status Should not be empty'),
  countryCode: Yup.array(),
  tag: Yup.string(),
  longDistanceCode: Yup.string()
    .max(6)
    .matches(/^[0-9]*$/, 'Please enter valid  Long Distance Code')
    .typeError('Long Distance Code Should be in current format'),
  comingSoonDate: Yup.date().typeError(
    'Coming Soon Date Should be in date format',
  ),
  activationStart: Yup.date()

    .typeError('Program Start Date Should be in date format')
    .required('Program Start Date should not be empty'),
  purchaseStartDate: Yup.date()
    .typeError('Purchase Start Date Should be in date format')
    .required('Purchase Start Date should not be empty'),
  purchaseEndDate: Yup.date()
    .typeError('Purchase End Date Should be in date format')
    .required('Purchase End Date should not be empty'),
  completeEnd: Yup.date()
    .typeError('Submission Dead Line date Should be in date format')
    .required('Submission Dead Line date should not be empty'),
  activationEnd: Yup.date()
    .typeError('Program End Date Should be in date format')
    .required('Program End Date Should not be empty'),
  shutdownDate: Yup.date()
    .typeError('Shut Down  Date Should be in date format')
    .required('Shut Down Date should not be empty'),
  uiConfig: Yup.object().shape({
    passwordRule: Yup.object().required('Password Rules Required'),
    userFields: Yup.array().required('User Fields Required'),
  }),
  isTierEnabled: Yup.boolean().required('Is Tier should not be empty'),
  userLoginEnabled: Yup.boolean().required(
    'User Login Enable should not be empty',
  ),
  currency: Yup.string().required('Please select the currency'),
  // shippingCharges: Yup.number()
  //   .min(0, 'Shipping Charges should be greater then or equal to zero')
  //   .required('Shipping Charges should not be empty'),
  publicApis: Yup.object().shape({
    activityList: Yup.boolean().required('Activity List should be true/false'),
    rewardList: Yup.boolean().required('Reward List should be true/false'),
  }),
  //s3Bucket: Yup.string().required('S3 Bucket Name Should not be empty'),
  pointTierConfig: Yup.array()
    .of(
      Yup.object().shape({
        tierName: Yup.string(),
        multiplier: Yup.number(),
        'level-up-points': Yup.number().typeError(
          'Lever up points should be number',
        ),
      }),
    )
    .required('Point tier Required'),
  menuStructure: Yup.array(),
  userMenu: Yup.array(),
  awsRegion: Yup.string().required('Please select Hosting Region'),
  validateEmail: Yup.boolean().required('Validate Email Required'),
  replyToEmail: Yup.string().email().required('Reply To Email Field required'),
  userAuth: Yup.object().shape({
    enabled: Yup.boolean().required('User Auth enabled should be true/false'),
    InApp: Yup.boolean().required('User Auth InApp should be true/false'),
    oauthGoogle: Yup.boolean().required(
      'User Auth oauth Google should be true/false',
    ),
    oauthFacebook: Yup.boolean().required(
      'User Auth oauth Facebook should be true/false',
    ),
    oauthTwitter: Yup.boolean().required(
      'User Auth oauth Twitter should be true/false',
    ),
  }),
  earningPointActivities: Yup.object().required(
    'Earn Point Activities Required',
  ),
  helpDeskServices: Yup.object().required('Help Desk Services required'),
  notificationOptions: Yup.object().required('Notification Options required'),
  auth2ndFactorEMAIL: Yup.boolean().required(
    'Second Factor Authentication Email Required',
  ),
  auth2ndFactorSMS: Yup.boolean().required(
    'Second Factor Authentication SMS Required',
  ),
  socialHandles: Yup.object(),
  timeZone: Yup.string().required(),
  sendGridCreds: Yup.object(),
  smsGwCreds: Yup.object(),
  sessionExpiryTime: Yup.number(),
  userRepoType: Yup.string(),
  fromEmail: Yup.string(),
  captchaConfig: Yup.object().shape({
    enable: Yup.boolean(),
    projectID: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Captch Project Id is required'),
      otherwise: Yup.string(),
    }),
    recaptchaSiteKey: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Recaptcha Site Key is required'),
      otherwise: Yup.string(),
    }),
  }),
  neoCurrencyProjectId: Yup.string().required(
    'NeoCurrency ProjectId Should not be empty',
  ),
  neoCurrencyCampaignId: Yup.string().required(
    'NeoCurrency ProjectId Should not be empty',
  ),
  receiptUploadViaEmail: Yup.object().shape({
    enable: Yup.boolean(),
    emailUser: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Source email user is a required field.'),
      otherwise: Yup.string(),
    }),
    emailPassword: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Source email password is a required field.'),
      otherwise: Yup.string(),
    }),
    emailHost: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Source email host is a required field.'),
      otherwise: Yup.string(),
    }),
    emailPort: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Source email port is a required field.'),
      otherwise: Yup.string(),
    }),
  }),
  bonusPointInfo: Yup.object().shape({
    onRegistration: Yup.number()
      .min(0, 'Enter number between 0 to 100000')
      .max(100000, 'Enter number between 0 to 100000'),
    onProfileComplete: Yup.object().shape({
      enable: Yup.boolean(),
      validFrom: Yup.string().when('enable', {
        is: true,
        then: Yup.string().required('Valid from is a required field.'),
        otherwise: Yup.string(),
      }),
      validTo: Yup.string().when('enable', {
        is: true,
        then: Yup.string().required('Valid to is a required field.'),
        otherwise: Yup.string(),
      }),
      points: Yup.string().when('enable', {
        is: true,
        then: Yup.string().required('Points is a required field.'),
        otherwise: Yup.string(),
      }),
      validateOnField: Yup.string().when('enable', {
        is: true,
        then: Yup.string().required('Validate on field is a required field.'),
        otherwise: Yup.string(),
      }),
    }),
  }),
  ocrParsingModel: Yup.string()
    .ensure()
    .when(['receiptOCRParse', 'ocrParsingTool'], {
      is: (receiptOCRParse, ocrParsingTool) =>
        receiptOCRParse && ocrParsingTool !== 'AMAZON_TEXTRACT',
      then: Yup.string().required('OCR Parsing model is a required field.'),
    }),
  ocrParsingTool: Yup.string()
    .ensure()
    .when('receiptOCRParse', {
      is: true,
      then: Yup.string().required('OCR Parsing Tool is a required field.'),
    }),
  salesForce: Yup.object().shape({
    enable: Yup.boolean(),
    accessTokenUrl: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Access Token URL is a required field.'),
      otherwise: Yup.string(),
    }),
    accountId: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Account Id is a required field.'),
      otherwise: Yup.string(),
    }),
    clientId: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Client Id is a required field.'),
      otherwise: Yup.string(),
    }),
    clientSecret: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Client Secret is a required field.'),
      otherwise: Yup.string(),
    }),
    dataUrl: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Data URL is a required field.'),
      otherwise: Yup.string(),
    }),
    grantType: Yup.string().when('enable', {
      is: true,
      then: Yup.string().required('Grant Type is a required field.'),
      otherwise: Yup.string(),
    }),
  }),
});

export default CampaignSchema;
