import React, {useEffect, useState} from 'react';
import {IsLoadingHOC} from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {Row, Col} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {getLockupAction, processCustomReportAction} from '../_redux/action';
import {toast} from 'react-toastify';
import SQSReportPopup from '../../../common/SQSReportPopup';

const GTMReport = ({setLoading}) => {
  const [exportAllResp, setExportAllResp] = React.useState(null);
  const dialogClose = () => {
    setExportAllResp(null);
  };
  const [reportList, setReportList] = useState([]);
  const [additionalKeys, setAdditionalCols] = useState([]);
  const [additionalKeysChildren, setAdditionalKeysChildren] = useState([]);

  const initVal = {
    fromDate: null,
    toDate: null,
    reportId: null,
    additionalKeys: null,
    additionalKeysChild: null,
  };
  const [filter, setFilter] = useState(initVal);
  const [errors, setErrors] = useState({
    fromDate: null,
    toDate: null,
    reportId: null,
    additionalKeys: null,
    additionalKeysChild: null,
  });
  const handleChange = (e) => {
    let fltData = {...filter};
    fltData[e.target.name] = e.target.value;
    if (e.target.value) {
      setErrors({...errors, [e.target.name]: null});
    }
    if (e.target.name === 'reportId') {
      fltData['additionalKeys'] = null;
      fltData['additionalKeysChild'] = null;
    }
    setFilter(fltData);
  };
  useEffect(() => {
    loadLookupDataList();
    // eslint-disable-next-line
  }, []);
  const loadLookupDataList = () => {
    let requestData = {lookups: ['CUSTOM_REPORTS']};
    setLoading(true);
    getLockupAction(requestData)
      .then((response) => {
        if (response.httpCode === 200 && response.data) {
          if (response.data.content) {
            let stListInd = response.data.content.findIndex(
              (v) => v.name === 'CUSTOM_REPORTS',
            );
            if (stListInd >= 0) {
              let reptDataList = response.data.content[stListInd].data.map(
                (v) => {
                  return {...v, label: v.reportName, value: v.reportId};
                },
              );
              setReportList(reptDataList);
            }
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (filter?.reportId?.additionalKeys) {
      let cols = filter.reportId.additionalKeys.map((v) => {
        return {...v, label: v.label, value: v.value};
      });
      setAdditionalCols(cols);
      setAdditionalKeysChildren([]);
    } else {
      setAdditionalCols(null);
      setAdditionalKeysChildren([]);
    }
    setFilter({...filter, additionalKeys: null, additionalKeysChild: null});
  }, [filter.reportId]);
  const handleSubmit = (e) => {
    let err = false;
    let allErr = {
      fromDate: null,
      toDate: null,
      reportId: null,
      additionalKeys: null,
    };
    if (!filter.fromDate) {
      err = true;
      allErr.fromDate = 'Please select valid From Date.';
    }
    if (!filter.toDate) {
      err = true;
      allErr.toDate = 'Please select valid To Date.';
    }
    if (!filter.reportId) {
      err = true;
      allErr.reportId = 'Please select Report Type.';
    }
    if (filter?.reportId?.additionalKeysRequired && !filter.additionalKeys) {
      err = true;
      allErr.additionalKeys = 'Please select at least one of this.';
    }
    if (err) {
      setErrors(allErr);
    } else {
      setLoading(true);
      setExportAllResp(null);
      let payload = {
        startDate: moment(filter.fromDate).format('YYYY-MM-DD'),
        endDate: moment(filter.toDate).format('YYYY-MM-DD'),
        reportId: filter?.reportId?.value,
        additionalKeys: filter?.additionalKeys?.map((v) => v.value) || [],
        additionalKeysChild:
          filter?.additionalKeysChild?.map((v) => v.value) || [],
      };
      processCustomReportAction(payload)
        .then((res) => {
          if (res?.httpCode >= 200 && res?.httpCode <= 299) {
            setFilter(initVal);
            setExportAllResp(res?.data?.fileName || 'NA');
            toast.success(`Process started successfully.`);
          } else {
            if (res?.errors) {
              if (Array.isArray(res.errors)) {
                res.errors.map((item) => {
                  toast.error(item?.message);
                });
              } else {
                toast.error(`Something went wrong.`);
              }
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoading(false);
          toast.error(`Something went wrong.`);
        });
    }
  };

  const onAdditionalChangeHandler = (value) => {
    setFilter({...filter, additionalKeys: value, additionalKeysChild: null});
    if (value) {
      setErrors({...errors, additionalKeys: ''});

      let childList = [];
      for (let i in value) {
        if (value[i].child) {
          value[i].child = value[i].child.map((v) => {
            return {...v, label: v.label, value: v.value};
          });
          childList = childList.concat(value[i].child);
        }
      }
      childList.sort((a, b) => b.label - a.label);
      setAdditionalKeysChildren(childList);
    } else {
      setAdditionalKeysChildren([]);
    }
  };

  return (
    <>
      <Row>
        <Col md={2} xs={3} lg={2} className="pt-5">
          <label className=" col-form-label text-lg-left">
            From Date <span className="text-danger">*</span>
          </label>
        </Col>
        <Col md={4} xs={9} lg={4} className="pt-5">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className={`form-control form-control-lg ${
              errors.fromDate ? 'is-invalid' : ''
            }`}
            autocomplete="off"
            selected={
              filter.fromDate ? moment(filter?.fromDate).toDate() : null
            }
            name="fromDate"
            onChange={(date) => {
              handleChange({
                target: {
                  name: 'fromDate',
                  value: date,
                },
              });
            }} //only when value has changed
            showYearDropdown
            dropdownMode="select"
            enableTabLoop
          />
          <p className="text-danger">{errors.fromDate}</p>
        </Col>
        <Col md={2} xs={3} lg={2} className="pt-5">
          <label className=" col-form-label text-lg-left">
            To Date <span className="text-danger">*</span>
          </label>
        </Col>
        <Col md={4} xs={9} lg={4} className="pt-5">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className={`form-control form-control-lg ${
              errors.toDate ? 'is-invalid' : ''
            }`}
            autocomplete="off"
            selected={filter.toDate ? moment(filter?.toDate).toDate() : null}
            name="toDate"
            onChange={(date) => {
              handleChange({
                target: {
                  name: 'toDate',
                  value: date,
                },
              });
            }} //only when value has changed
            showYearDropdown
            dropdownMode="select"
            enableTabLoop
          />
          <p className="text-danger">{errors.toDate}</p>
        </Col>
        <Col md={2} xs={3} lg={2} className="pt-5">
          <label className=" col-form-label text-lg-left">
            Report Type <span className="text-danger">*</span>
          </label>
        </Col>
        <Col md={4} xs={9} lg={4} className="pt-5">
          <Select
            onChange={(value) => {
              setFilter({
                ...filter,
                reportId: value,
              });
              if (value) {
                setErrors({...errors, reportId: null});
              }
            }}
            value={filter.reportId}
            options={reportList}
            className={`basic-multi-select ${
              errors.reportId ? 'is-invalid' : ''
            }`}
            classNamePrefix="select"
            placeholder="Search..."
          />
          <p className="text-danger">{errors.reportId}</p>
        </Col>
        {additionalKeys && (
          <>
            <Col md={2} xs={3} lg={2} className="pt-5">
              <label className=" col-form-label text-lg-left">
                Including/RefType{' '}
                <span className="text-danger">
                  {filter.reportId?.additionalKeysRequired && '*'}
                </span>
              </label>
            </Col>
            <Col md={4} xs={9} lg={4} className="pt-5">
              <Select
                onChange={onAdditionalChangeHandler}
                value={filter.additionalKeys}
                isMulti
                options={additionalKeys}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Search..."
              />
              <p className="text-danger">{errors.additionalKeys}</p>
            </Col>
          </>
        )}
        {additionalKeysChildren?.length > 0 && (
          <>
            <Col md={2} xs={3} lg={2} className="pt-5">
              <label className=" col-form-label text-lg-left">
                Element Tags{' '}
              </label>
            </Col>
            <Col md={4} xs={9} lg={4} className="pt-5">
              <Select
                onChange={(value) => {
                  setFilter({...filter, additionalKeysChild: value});
                }}
                value={filter.additionalKeysChild}
                isMulti
                options={additionalKeysChildren}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Search..."
              />
              <p className="text-danger">{errors.additionalKeysChild}</p>
            </Col>
          </>
        )}
      </Row>
      <p align="center" className="pt-5">
        <button
          type="submit"
          className={`btn btn-primary font-weight-bold me-2`}
          onClick={handleSubmit}>
          <i className="fa fa-tasks" /> Start Process
        </button>
      </p>
      <SQSReportPopup
        dialogClose={dialogClose}
        modelVisible={exportAllResp ? true : false}
        fileName={exportAllResp}
      />
    </>
  );
};

export default IsLoadingHOC(GTMReport);
