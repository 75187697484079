import {
  SAVE_CAMPAIGN_LIST,
  LOADING,
  CAMPAIGN_FOR_EDIT,
  UPDATE_CAMPAIGN,
} from './type';

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  metaData: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
  campaignForEdit: undefined,
  lastError: null,
};
const CampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CAMPAIGN_LIST: {
      const {content, metaData} = action.payload;
      return {
        ...state,
        entities: content ? content : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case LOADING: {
      return {...state, listLoading: action.payload};
    }
    case CAMPAIGN_FOR_EDIT: {
      return {...state, campaignForEdit: action.payload};
    }

    case UPDATE_CAMPAIGN: {
      let entities = state.entities.map((entity) => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }
        return entity;
      });
      return {...state, entities: entities};
    }
    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default CampaignReducer;
