import React, {useEffect, useState, Fragment} from 'react';
// import { Formik, Field, Form } from 'formik';
import {Button, Form} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import {
  fileContentExtract,
  checkStatus,
} from '../../../app/modules/Auth/_redux/authCrud';
import FileManagerModel from '../../../app/modules/FileManager/index';
// import fmService from '../../../app/modules/FileManager/FileManager.Service';
import {Refresh} from '@mui/icons-material';

import IsLoadingHOC from '../../layout/components/HOC/IsLoadingHOC';
import ErrorBoundary from '../../_helpers/ErrorBoundary';
import StackCreate from './StackCreate';

const TemplateGenerate = ({
  data: tenantConfig,
  id,
  setLoading,
  fileContentExtract,
  setExpanded,
  checkStatus,
}) => {
  const [show, setShow] = useState(false);
  const [showFM, setShowFM] = useState(false);
  const [stack, setStack] = useState(false);
  const [createStackStatusData, setCreateStackStatusData] = useState();
  useEffect(() => {
    checStutusData();
  }, []);
  // console.log('createStackStatusData', createStackStatusData, deployStatusData);
  //console.log('createStackDAta', deployStatusData);
  const dispatch = useDispatch();
  const generateTemplate = () => {
    setLoading(true);
    fileContentExtract(tenantConfig)
      .then((res) => {
        setLoading(false);
        toast.success(res);
      })
      .catch((err) => {
        setLoading(false);
        err.forEach((item) => {
          toast.error(item.message);
        });
      });
  };

  const createStackShow = () => {
    if (!tenantConfig?.s3TempletZipPath) {
      Swal.fire(
        'Template Zip file is not available !',
        'Please generate the template first',
        'warning',
      );
    } else {
      if (createStackStatusData?.StackStatus === 'CREATE_COMPLETE') {
        Swal.fire({
          title: 'Are you sure?',
          text: 'Once you create new stack,  old repo will be deleted and you will not be able to recover this imaginary file!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes Sure',
        }).then((result) => {
          if (result.isConfirmed) {
            setShow(true);
          }
        });
      } else {
        setShow(true);
      }
    }
  };

  //console.log('tenantConfig', tenantConfig);

  const showPopup = () => {
    dispatch({
      type: 'SECECTED_FOLDER_SET',
      payload: `campaign_id_${id}/User_template/`,
    });
    setTimeout(() => {
      setShowFM(!showFM);
    }, 100);
  };

  const getStateFromChild = () => {
    setShowFM(false);
  };
  const handleClose = () => {
    setShow(false);
  };

  const checStutusData = () => {
    setLoading(true);
    checkStatus()
      .then((res) => {
        setCreateStackStatusData(res);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.status === 'new') {
          setCreateStackStatusData({
            StackStatus: err.status,
            message: err?.message,
          });
        }
        setLoading(false);
      });
  };
  const handleRefresh = () => {
    checStutusData();
  };

  return (
    <ErrorBoundary>
      <div className="d-flex flex-column flex-root">
        <div className="card-toolbar align-self-end">
          <button
            type="button"
            //  disabled={wizardStep <= 1 && 'disabled'}
            className="btn btn-success me-2"
            onClick={() => setExpanded('panel3')}>
            MOVE PREVIOUS
          </button>
        </div>
        <div className="container">
          <div className="d-flex flex-row">
            <div className="flex-row-fluid ml-lg-8">
              <div className="card card-custom">
                <Form className="form">
                  <div className="card-toolbar">
                    <button
                      type="button"
                      //disabled={wizardStep <= 1 && "disabled"}
                      className="btn btn-success me-2"
                      onClick={generateTemplate}>
                      Generate Template
                    </button>
                  </div>
                  <br />
                  <div className="card-toolbar mb-5">
                    <div className="slack-details">
                      {!tenantConfig?.s3TempletZipPath && (
                        <h4>
                          Template is not generated. Please generate template
                          before creating Stack.
                        </h4>
                      )}
                      {createStackStatusData?.StackStatus ===
                        'CREATE_COMPLETE' && (
                        <>
                          <h4>Stack is already Created</h4>
                          <p>Stack Name : {createStackStatusData?.StackName}</p>
                          <p>
                            Repository Name :{' '}
                            {
                              createStackStatusData?.Parameters.find(
                                (item) => item.ParameterKey === 'RepoName',
                              )?.ParameterValue
                            }
                          </p>
                          <p>
                            S3 Bucket Name :{' '}
                            {
                              createStackStatusData?.Parameters.find(
                                (item) => item.ParameterKey === 'S3BucketName',
                              )?.ParameterValue
                            }
                          </p>
                          <p>
                            App Name :{' '}
                            {
                              createStackStatusData?.Parameters.find(
                                (item) => item.ParameterKey === 'AppName',
                              )?.ParameterValue
                            }
                          </p>
                          <br />
                        </>
                      )}
                      {createStackStatusData?.StackStatus === 'new' && (
                        <h4>{createStackStatusData?.message}</h4>
                      )}
                      <h4>
                        Deployment Status : {createStackStatusData?.StackStatus}
                      </h4>

                      {createStackStatusData?.StackStatus ===
                        'CREATE_FAILED' && (
                        <>Reason: {createStackStatusData?.StackStatusReason}</>
                      )}
                    </div>

                    {/* {!createStackStatusData && ( */}
                    <button
                      // disabled={
                      //   createStackStatusData?.StackStatus === 'CREATE_COMPLETE'
                      //     ? true
                      //     : false
                      // }
                      type="button"
                      className="btn btn-success me-2"
                      onClick={() => createStackShow()}>
                      Create Stack
                    </button>

                    <Button
                      variant="link"
                      title="Refresh"
                      onClick={() => handleRefresh()}>
                      <Refresh />
                    </Button>

                    {/* )} */}
                    {/* {!(deployStatusData?.status === 'COMPLETED') && (
                      <button
                        type="button"
                        onClick={handleBuild}
                        className="btn btn-success me-2">
                        Run Build
                      </button>
                    )} */}
                  </div>

                  <button
                    type="button"
                    onClick={showPopup}
                    className="btn btn-dark">
                    Click to Download Files
                  </button>
                  {showFM ? (
                    <FileManagerModel parentCallback={getStateFromChild} />
                  ) : (
                    ''
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
        <StackCreate
          handleClose={handleClose}
          show={show}
          status={createStackStatusData?.StackStatus}
          s3FolderPath={tenantConfig?.s3TempletZipPath}
        />
      </div>
    </ErrorBoundary>
  );
};

export default connect(null, {
  fileContentExtract,
  checkStatus,
})(React.memo(IsLoadingHOC(TemplateGenerate, 'Waiting')));
