import axios from 'axios';
import store from '../../redux/store';

const instance = axios.create();
const apiUrl = process.env.REACT_APP_API_URL;

let errorParser = (err) => {
  let msg = 'Error! 404 Service API not found';
  if (typeof err === 'object') {
    if (err['response']) {
      if (err.response.data['errors']) {
        try {
          let errarArr = err.response.data.errors.map((v) => v.message);
          msg = `Error! ${err.response.status} : ${errarArr.toString()}`;
        } catch (e) {
          msg = `Error! ${err.response.status} : something went wrong`;
        }
      } else {
        msg = `Error! ${err.response.status} : something went wrong `;
      }
    }
  }
  return msg;
};
export const getRequest = (url, callback) => {
  let {auth} = store.getState();
  let token = auth.accessToken;
  let headers = {
    Authorization: token,
  };
  instance
    .get(apiUrl + url, {
      headers: headers,
    })
    .then((response) => {
      callback(response, null);
    })
    .catch((err) => {
      let msg = errorParser(err);
      callback(null, msg);
    });
};

export const postRequest = (url, body, callback) => {
  let {auth} = store.getState();
  let token = auth.accessToken;
  let headers = {
    Authorization: token,
  };

  instance
    .post(apiUrl + url, body, {
      headers: headers,
    })
    .then((response) => {
      callback(response, null);
    })
    .catch((err) => {
      let msg = errorParser(err);
      callback(null, msg);
    });
};

export const putRequest = (url, body, callback) => {
  let {auth} = store.getState();
  let token = auth.accessToken;
  let headers = {
    Authorization: token,
  };

  instance
    .put(apiUrl + url, body, {
      headers: headers,
    })
    .then((response) => {
      callback(response, null);
    })
    .catch((err) => {
      let msg = errorParser(err);
      callback(null, msg);
    });
};
export const fetchRequest = (url, method, body = {}) => {
  let {auth} = store.getState();
  let token = auth.accessToken;
  let opt = {
    method: method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  if (Object.keys(body).length > 0) {
    opt['body'] = JSON.stringify(body); // body data type must match "Content-Type" header
  }
  return fetch(apiUrl + url, opt)
    .then((res) => res.json())
    .then((data) => data.data)
    .catch((e) => {
      console.log('Error', e);
      throw errorParser(e);
    });
};

export const downloadFileRequest = (url) => {
  let {auth} = store.getState();
  let token = auth.accessToken;
  let opt = {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return fetch(apiUrl + url, opt)
    .then((res) => res)
    .catch((e) => {
      console.log('Error', e);
      throw errorParser(e);
    });
};
