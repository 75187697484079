const tenantConfig = {
  name: '',
  status: 'active',
  shortCode: '',
  auth2ndFactorEMAIL: false,
  auth2ndFactorSMS: false,
  timeZone: 'America/Los_Angeles',
  isTierEnabled: false,
  awsRegion: 'us-west-2',
  clientId: '',
  currency: 'USD',
  countryCode: [],
  longDistanceCode: '',
  receiptUploadPreValidation: false,
  receiptUploadEmail: '',
  multiReceiptUpload: false,
  emailLoginOnly: false,
  socialSecurityNumber: '',
  address2: '',
  redeemLimitApplicable: {
    limitApplicable: true,
    limitField: '',
    calendar: '',
  },
  appleAuthentication: {
    clientId: '',
    redirectURI: '',
    appId: '',
    keyId: '',
    privateKey: '',
    resourceServer: '',
  },
  microsoftAuthentication: {
    clientId: '',
    redirectURI: '',
    appId: '',
    keyId: '',
    privateKey: '',
    resourceServer: '',
  },
  googleAuthentication: {
    clientId: '',
    redirectURI: '',
    appId: '',
    keyId: '',
    privateKey: '',
    resourceServer: '',
  },
  facebookAuthentication: {
    clientId: '',
    redirectURI: '',
    appId: '',
    keyId: '',
    privateKey: '',
    resourceServer: '',
  },
  amazonAuthentication: {
    clientId: '',
    redirectURI: '',
    appId: '',
    keyId: '',
    privateKey: '',
    resourceServer: '',
  },
  rewardConfig: {
    useRewardToken: false,
    rewardClaimUrl: '',
  },
  googleAnalyticsId: '',
  googleAnalyticsPropertyId: '',
  googleAnalyticsConfig: '',
  googleAnalyticsEnabled: false,
  receiptUploadViaEmail: {
    enable: false,
    emailUser: '',
    emailPassword: '',
    emailHost: '',
    emailPort: '',
    backupEmailUser: '',
  },
  salesForce: {
    enable: false,
    accessTokenUrl: '',
    accountId: '',
    clientId: '',
    clientSecret: '',
    dataUrl: '',
    grantType: '',
  },
  publicApis: {
    activityList: false,
    rewardList: false,
  },
  captchaConfig: {
    recaptchaVersion: '',
    recaptchaSecretKey: '',
    enable: false,
    projectID: '',
    recaptchaSiteKey: '',
    registrationCaptcha: false,
    loginCaptcha: false,
    uploadReceiptCaptcha: false,
    supportCaptcha: false,
    packageCodeCaptcha: false,
  },
  rcptUploadSMS: [
    {
      id: 'campaign',
      inMsg: '<campaign short code>',
      outMsg: '<welcome_tnc>',
    },
    {
      id: 'tnc_accept',
      inMsg: 'yes|no',
      outMsg: '<tns_yes_no>',
    },
    {
      id: 'receipt',
      inMsg: '<attached image>',
      outMsg: '<recept_response>',
    },
  ],
  receiptOCRParse: false,
  shippingCharges: 0,
  // activationStart: new Date().toISOString().split('T')[0],
  // activationEnd: new Date().toISOString().split('T')[0],
  // completeEnd: new Date().toISOString().split('T')[0],
  activationStart: '',
  activationEnd: '',
  completeEnd: '',
  shutDown: '',
  allowEmailChange: false,
  userLoginEnabled: false,
  validateEmail: false,
  sendWelcomeEmail: false,
  userRepoType: 'client',
  allowRcptBucket: false,
  userAuth: {
    enabled: false,
    InApp: false,
    oauthGoogle: false,
    oauthFacebook: false,
    oauthTwitter: false,
  },
  notificationOptions: {
    email: false,
    sms: false,
    twitter: false,
    facebook: false,
    instagram: false,
  },
  earningPointActivities: {
    watch: false,
    read: false,
    share: false,
    games: false,
    answer: false,
    refer: false,
    click: false,
    referAdditionInfo: {},
  },
  bonusPointInfo: {
    onRegistration: 0,
    onProfileComplete: {
      enable: false,
      validFrom: '',
      validTo: '',
      points: 0,
      validateOnField: '',
    },
  },
  salesValidation: {
    receipt: false,
    code: false,
  },
  receiptCallbackUrl: false,
  optionalServices: {
    dynamicMsg: false,
  },
  supportEmail: '',
  helpDeskServices: {
    enable: false,
    teamworkBaseUrlV1: '',
    teamworkBaseUrlV2: '',
    teamworkPermanentToken: '',
    teamworkAgentId: '',
    inboxId: '',
    inboxName: '',
    inboxDomain: '',
    adminId: '',
    adminPass: '',
  },
  enableSession: false,
  sessionExpiryTime: 1440,
  s3Bucket: '',
  usePreRegistration: false,
  fingerprinting: {
    enable: false,
    clientKey: 'testuserFPKey11',
    serverKey: 'testadminFPKey12',
  },
  domain: '',
  fromEmail: '',
  replyToEmail: '',
  sendGridCreds: {
    apikey: '',
    supportEmailName: '',
  },
  smsGwCreds: {
    key: '',
    id: '',
  },
  socialHandles: {
    twitterHandle: '',
    instagramHandle: '',
    facebookHanle: '',
    tiktokHandle: '',
  },

  pointTierConfig: [
    {
      tierId: 1,
      tierName: 'Extra',
      desc: '',
      multiplier: 1,
    },
  ],
  templateFile: '<s3folder/www/fileName.zip>',
  menuStructure: [
    {
      id: 'validatePurchase',
      lable: 'Validate Purchase',
      submenu: [
        {
          id: 'submitCode',
          label: 'Submit Code',
          routerId: '',
        },
        {
          id: 'digitalMovie',
          label: 'Digital Movie',
          routerId: '',
        },
        {
          id: 'receiptUpload',
          label: 'Receipt Upload',
          routerId: '',
        },
        {
          id: 'eligibleMovies',
          label: 'Eligible Movies',
          routerId: '',
        },
      ],
    },
    {
      id: 'rewards',
      label: 'Rewards',
      routerId: '',
    },
    {
      id: 'context',
      label: 'Contest',
      routerId: '',
    },
    {
      id: 'earnPoints',
      label: 'Earn Points',
      routerId: '',
    },
    {
      id: 'allAccess',
      label: 'All Access',
      submenu: [
        {
          id: 'allMyAccess',
          label: 'All My-Access',
          routerId: '',
        },
        {
          id: 'myMovieFeatures',
          label: 'My Movie Features',
          routerId: '',
        },
      ],
    },
    {
      id: 'fanZone',
      label: 'Fan Zone',
      imgUrl: 'https://....../menuImagex.png',
      routerId: '',
    },
  ],
  userMenu: [
    {
      id: 'myAllAccess',
      label: 'My All-Access',
      icon: '',
      routerId: '',
    },
    {
      id: 'pointsHistory',
      label: 'Points History',
      icon: '',
      routerId: '',
    },
    {
      id: 'myRewards',
      label: 'My Rewards',
      icon: '',
      routerId: '',
    },
    {
      id: 'profile',
      label: 'Profile',
      icon: '',
      routerId: '',
    },
    {
      id: 'connectMoviesAnywhere',
      label: 'My Rewards',
      icon: '',
      routerId: '',
    },
    {
      id: 'logout',
      label: 'Logout',
      icon: '',
      routerId: '',
    },
  ],
  uiConfig: {
    passwordRule: {
      minLength: 8,
      maxLength: 15,
      alphaReq: true,
      upperCaseReq: true,
      lowerCaseReq: true,
      specialCharReq: true,
      specialCharRegEx: '',
      pwdBucketSize: 2,
      mandatory: true,
      hasNumeric: false,
      hasAlpha: false,
    },
    userFields: [
      {
        name: 'firstName',
        type: 'string',
        fieldType: 'input',
        label: 'lang_key_fname',
        placeholder: 'First Name',
        helperService: null,
        validation: {
          email: false,
          mandatory: true,
          minLength: 1,
          maxLength: 20,
          hasNumeric: false,
          hasAlpha: false,
        },
      },
      {
        name: 'lastName',
        type: 'string',
        label: 'lang_key_lname',
        fieldType: 'input',
        placeholder: 'Last Name',
        helperService: null,
        validation: {
          email: false,
          mandatory: true,
          minLength: 1,
          maxLength: 20,
          hasNumeric: false,
          hasAlpha: false,
        },
      },
      {
        name: 'email',
        type: 'string',
        label: 'lang_key_email',
        fieldType: 'email',
        placeholder: 'Email',
        helperService: null,
        validation: {
          email: true,
          mandatory: true,
          minLength: 1,
          maxLength: 20,
          hasNumeric: false,
          hasAlpha: false,
        },
      },
      {
        name: 'username',
        type: 'string',
        fieldType: 'input',
        label: 'lang_key_uname',
        placeholder: 'user Name',
        helperService: null,
        validation: {
          email: false,
          mandatory: true,
          minLength: 6,
          maxLength: 20,
          hasNumeric: false,
          hasAlpha: false,
        },
      },
    ],
  },
};

const customFields = [
  {
    name: 'address',
    type: 'string',
    label: 'lang_key_add_address',
    fieldType: 'input',
    placeholder: 'Address',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 1,
      maxLength: 300,
      hasNumeric: false,
      isUnique: false,
      hasAlpha: false,
    },
  },
  {
    name: 'companyName',
    type: 'string',
    label: 'lang_key_add_companyName',
    fieldType: 'input',
    placeholder: 'Company Name',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 1,
      maxLength: 30,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'socialSecurityNumber',
    type: 'string',
    label: 'lang_key_add_socialSecurityNumber',
    fieldType: 'input',
    placeholder: 'Social Security Number',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 1,
      maxLength: 30,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'address2',
    type: 'string',
    label: 'lang_key_add_address2',
    fieldType: 'input',
    placeholder: 'Address 2',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 1,
      maxLength: 30,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'state',
    type: 'string',
    label: 'lang_key_add_state',
    fieldType: 'select',
    placeholder: 'State',
    helperService: 'https://hostname.domain.aws.com/api/us_states',
    validation: {
      email: false,
      mandatory: true,
      minLength: 1,
      maxLength: 20,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'suite',
    type: 'string',
    label: 'lang_key_add_suite',
    fieldType: 'input',
    placeholder: 'Suite',
    helperService: null,
    validation: {
      email: false,
      mandatory: true,
      minLength: 1,
      maxLength: 12,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'country',
    type: 'string',
    label: 'lang_key_add_country',
    fieldType: 'select',
    placeholder: 'Country',
    helperService: 'https://hostname.domain.aws.com/api/country',
    validation: {
      email: false,
      mandatory: true,
      minLength: 1,
      maxLength: 20,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'zip',
    type: 'string',
    label: 'lang_key_add_zip',
    fieldType: 'input',
    placeholder: 'Zip Code',
    validation: {
      email: false,
      mandatory: true,
      minLength: 1,
      maxLength: 20,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'homePhone',
    type: 'string',
    label: 'lang_key_phone_homePhone',
    fieldType: 'input',
    placeholder: 'HomePhone',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 10,
      maxLength: 10,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'cellPhone',
    type: 'string',
    label: 'lang_key_phone_cellPhone',
    fieldType: 'input',
    placeholder: 'CellPhone',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 10,
      maxLength: 10,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'twitterHandle',
    type: 'string',
    label: 'lang_key_twitterHandle',
    fieldType: 'input',
    placeholder: 'Twitter Handle',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 10,
      maxLength: 10,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'instagramHandle',
    type: 'string',
    label: 'lang_key_instagramHandle',
    fieldType: 'input',
    placeholder: 'Instagram Handle',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 10,
      maxLength: 10,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'facebookHandle',
    type: 'string',
    label: 'lang_key_facebookHandle',
    fieldType: 'input',
    placeholder: 'Facebook Handle',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 10,
      maxLength: 10,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'tiktokHandle',
    type: 'string',
    label: 'lang_key_tiktokHandle',
    fieldType: 'input',
    placeholder: 'Tiktok Handle',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 10,
      maxLength: 10,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'city',
    type: 'string',
    label: 'lang_key_city',
    fieldType: 'input',
    placeholder: 'City',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 10,
      maxLength: 10,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'age',
    type: 'string',
    label: 'lang_key_age',
    fieldType: 'input',
    placeholder: 'Age',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 10,
      maxLength: 10,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'birth',
    type: 'string',
    label: 'lang_key_birth',
    fieldType: 'input',
    placeholder: 'DOB',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 10,
      maxLength: 10,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'gender',
    type: 'string',
    label: 'lang_key_gender',
    fieldType: 'select',
    placeholder: 'Gender',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 10,
      maxLength: 10,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'secondaryEmail',
    type: 'string',
    label: 'lang_key_secondary_email',
    fieldType: 'email',
    placeholder: 'Secondary Email',
    helperService: null,
    validation: {
      email: true,
      mandatory: false,
      minLength: 1,
      maxLength: 100,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'shippingInstructions',
    type: 'string',
    label: 'lang_key_shipping_instructions',
    fieldType: 'input',
    placeholder: 'Shipping Instructions',
    helperService: null,
    validation: {
      email: false,
      mandatory: false,
      minLength: 1,
      maxLength: 30,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
  {
    name: 'memberId',
    type: 'string',
    label: 'lang_key_memberId',
    fieldType: 'input',
    placeholder: 'Member ID',
    helperService: null,
    validation: {
      email: false,
      mandatory: true,
      minLength: 12,
      maxLength: 12,
      hasNumeric: false,
      hasAlpha: false,
    },
  },
];

export { tenantConfig, customFields };
