import axios from 'axios';
import store from '../redux/store';
import {Logout, SetUser} from '../app/modules/Auth/_redux/types';
import {checkErrorResponse} from './util';

const baseUrl = process.env.REACT_APP_API_URL;
//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    //console.log("accessToken");
    const {accessToken} = store.getState('state').auth;
    //  console.log("accessToken", accessToken);
    if (accessToken) {
      config.headers['Authorization'] = accessToken;
    }
    return config;
  },
  (error) => {
    //console.log('error  --->....', error);
    Promise.reject(error);
  },
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    let {refreshToken} = store.getState('state').auth;
    if (
      refreshToken &&
      error.config.url === '/api/s2/auth/refreshtoken' &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      store.dispatch({type: Logout});
    }
    if (
      refreshToken &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      //	console.log("check working. ...............or not ............................");
      originalRequest._retry = true;
      return axios
        .post(`${baseUrl}/s2/auth/refreshtoken`, {refreshToken: refreshToken})
        .then((res) => {
          //console.log("result ...............................>>>>>>>>>>>>>>>>>", res);
          if (res['data']) {
            if (res.data.httpCode === 200) {
              store.dispatch({type: SetUser, payload: res.data.data});
              //store.dispatch(saveAccessToken(res.data.accessToken));
              //store.dispatch(saveRefreshToken(res.data.refreshToken));
              return axios(originalRequest);
            }
          } else {
            //console.log("Refresh token Response", res);
            store.dispatch({type: Logout});
          }
        })
        .catch((error) => {
          console.log('Refresh Token expire', error);
          store.dispatch({type: Logout});
        });
    }
    return Promise.reject(error);
  },
);

class Api {
  // constructor() {
  // 	//this.userLang = navigator.language || navigator.userLanguage;
  // }

  post(path, data = null) {
    return this.send(path, 'post', data);
  }

  put(path, data = null) {
    return this.send(path, 'put', data);
  }

  get(path, data = null) {
    return this.send(path, 'get', data);
  }

  patch(path, data = null) {
    return this.send(path, 'patch', data);
  }

  delete(path, data = null) {
    return this.send(path, 'delete', data);
  }

  send(url, method, data) {
    //console.log("lang", lang);
    let uri = `${baseUrl}${url}`;
    // console.log(uri);
    return new Promise((resolve, reject) => {
      let option = {method, url: uri, data: data};
      //  console.log('option', option);

      axios(option)
        .then((response) => {
          if (response.headers['content-disposition']) {
            let params = JSON.parse(response.config.data);
            var fileName = response.headers['content-disposition']
              .split('filename=')[1]
              .split(';')[0];
            if (params?.outputType === 'json') {
              const url = window.URL.createObjectURL(
                new Blob([JSON.stringify(response.data)]),
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }
            if (params?.outputType === 'csv') {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }

            //or any other extension
          }
          return response.data;
        })
        .then((responseData) => {
          // debugger;
          //console.log('data ', url, responseData);
          resolve(responseData);
        })
        .catch((error) => {
          // console.log('error.....', error, error['response']);
          //console.log('response for error', error['response']);
          const varifyError = checkErrorResponse(error);
          resolve(varifyError);
          // if (varifyError['response']) {
          //   resolve(varifyError.response.data);
          // } else {
          //   resolve(varifyError);
          // }
        });
    });
  }
}

const api = new Api();
export default api;
//export default axios;
