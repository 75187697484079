import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import {
  getAllModulesAction,
  getAllRolesAction,
  getRolePermissionAction,
  updateRolePermissionAction,
} from '../_redux/action';

const Permission = (props) => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [modules, setModules] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);

  useEffect(() => {
    async function fetchRoleData() {
      try {
        props.setLoading(true);
        const response = await getAllRolesAction();
        const resModules = await getAllModulesAction();
        if (response.httpCode == 200) {
          setRoles(response.data?.content || []);
        }
        if (resModules.httpCode == 200) {
          setModules(resModules.data.filter(({status}) => status === 'active'));
        }
        props.setLoading(false);
      } catch (error) {
        props.setLoading(false);
      }
    }
    fetchRoleData();
  }, []);

  useEffect(() => {
    async function fetchRoleData(id) {
      try {
        if (id) {
          props.setLoading(true);
          const response = await getRolePermissionAction(id);
          if (response.httpCode === 200) {
            const permissions = response.data || [];
            const updatedModules = modules.map((module) => {
              let moduleObj = {};
              const foundModule = permissions.find(
                (permission) => permission.moduleId === module._id,
              );
              moduleObj = {
                moduleId: module._id,
                name: module.module,
                permissions: foundModule
                  ? foundModule.permissions
                  : {create: 0, update: 0, read: 0},
              };
              return moduleObj;
            });
            setRolePermission(updatedModules);
            props.setLoading(false);
          } else {
            props.setLoading(false);
            toast.error(response.message);
          }
        }
      } catch (error) {
        props.setLoading(false);
      }
    }
    fetchRoleData(selectedRole);
  }, [selectedRole]);

  const handleChange = (e) => {
    setSelectedRole(e.target.value);
  };
  const handleChangePermission = (e, moduleId) => {
    const {name, checked} = e.target;
    const updatedModules = rolePermission.map((module) => {
      if (module.moduleId === moduleId) {
        return {
          ...module,
          permissions: {
            ...module.permissions,
            [name]: +checked,
          },
        };
      }
      return module;
    });
    console.log(updatedModules);
    setRolePermission(updatedModules);
  };

  const savePermission = async () => {
    try {
      props.setLoading(true);
      const body = rolePermission.filter((module) => {
        if (
          module.permissions.create == 0 &&
          module.permissions.read == 0 &&
          module.permissions.update == 0
        ) {
          return false;
        } else {
          return {
            roleId: selectedRole,
            moduleId: module.moduleId,
            permissions: module.permissions,
          };
        }
      });
      const response = await updateRolePermissionAction(body);
      if (response.httpCode === 200) {
        toast.success(response.message);
        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <Card>
        <CardHeader title={'Permission'}>
          <CardHeaderToolbar>
            <button className="btn btn-info" type="button">
              Back
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <select
                className="form-control form-control-lg"
                name="role"
                onChange={(e) => handleChange(e)}
                value={selectedRole}>
                <option value="">Select Role</option>
                {roles
                  .filter(({status}) => status === 'active')
                  .map((val, i) => (
                    <option value={val._id} key={i}>
                      {val.role}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {selectedRole && (
            <div className="row mt-3">
              <div className="col-lg-9">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Module</th>
                      <th scope="col">Create</th>
                      <th scope="col">Read</th>
                      <th scope="col">Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolePermission.map((module, i) => (
                      <tr key={module.moduleId}>
                        <th scope="row">{module.name}</th>
                        <td>
                          <input
                            name="create"
                            type={'checkbox'}
                            checked={Boolean(module.permissions.create)}
                            onClick={(e) => {
                              handleChangePermission(e, module.moduleId);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="read"
                            type={'checkbox'}
                            checked={Boolean(module.permissions.read)}
                            onClick={(e) => {
                              handleChangePermission(e, module.moduleId);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="update"
                            type={'checkbox'}
                            checked={Boolean(module.permissions.update)}
                            onClick={(e) => {
                              handleChangePermission(e, module.moduleId);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {rolePermission.length > 0 && (
            <div className="row justify-content-center mt-3">
              <div className="col-lg-3">
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={() => {
                    savePermission();
                  }}>
                  Save
                </button>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};
export default IsLoadingHOC(Permission, '..');
