import * as Yup from 'yup';
const AddEditRBACvalidationSchema = Yup.object({
  role: Yup.string()
    .min(2, 'Role should be greater than 2 letters')
    .max(100, 'Role should be less than 100 letters')
    .required('Required'),
  description: Yup.string()
    .min(5, 'Description should be greater than 5 letters')
    .max(500, 'Description should be less than 500 letters'),
  status: Yup.string().required('Required'),
});

export default AddEditRBACvalidationSchema;
